import { router } from "App";
import ExistingMember from "components/shared/existingMember";
import { useEffect } from "react";
export default function NewRx() {
  const data = JSON.parse(localStorage?.getItem("userData")) || {};
useEffect(()=>{
if(data && data.type=="Assistant"){
  router.navigate('/home')
}
},[])
  return (
<ExistingMember componentHeading="New Rx" />
  );
}
