import ClockIcon from "assets/svg/ClockIcon";
import { router } from "App";

import LogoIcon from "assets/svg/LogoIcon";
import Loader from "components/loader/Loader";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  getprescriptionIdParam,
  getsearchIdParam,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import user from "services/user";
import { convertTimestampToReadableDate } from "utils/helper";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import BakcIcon from "assets/svg/BackIcon";

export default function Prescription() {
  const { particularUserPrescription } = useSelector((state) => state.user);
  const { GET_PARTICULAR_USER_PRESCRIPTION: loading } = useSelector(
    (state) => state.loading
  );
  const location = useLocation();
  const { rx_user, prescription } = particularUserPrescription || {};
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const prescriptionId = searchParams.get("prescription_id");
  useEffect(() => {
    user.getParticularUserPrescription(user_id, prescriptionId);
  }, []);
  useEffect(() => {
    const data = {
      pathName: location,
      user_id: user_id,
      prescription_id: prescriptionId,
    };
    store.dispatch(getprescriptionIdParam(data));
    store.dispatch(getsearchIdParam({}));
  }, []);
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center "
        style={{
          backgroundColor: "#105CAA",
          fontFamily: "EB Garamond",
          padding: "16px 16px 50px 16px",
        }}
      >
        <div className=" d-flex flex-column ">
          <div>
            <LogoIcon />
            <p className="fw-700 fs-20 white">ClearRx</p>
          </div>
        </div>

        <div>
          <div className="mr-16">
            <p className=" white fs-16 fw-700 m-0">
              {" "}
              {prescription?.[0]?.user?.full_name}
            </p>
            <p className="white fs-14 fw-500 m-0">
              {" "}
              {prescription?.[0]?.user?.degree_speciality}
            </p>
            <p className="white fs-12 fw-400 m-0">
              License - {prescription?.[0]?.user?.licence_number}
            </p>
          </div>
        </div>
      </div>

      <div
        className="p-3 Regular shadow"
        style={{
          borderTopLeftRadius: "40px",
          borderTopRightRadius: "40px",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          background: "white",
          position: "relative",
          bottom: "40px",
          marginBottom: "22px",
        }}
      >
        <div
          className="back-icon-parent "
          style={{ marginLeft: "5px" }}
          onClick={() => router.navigate(`/rx-history?memberId=${user_id}`)}
        >
          <BakcIcon />
        </div>
        <h2 className="fw-500 fs-24 text-center m-3">Prescription</h2>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              className="py-3 px-2 Small shadow
"
              style={{ border: "1px solid #f7f7f7", borderRadius: "10px" }}
            >
              <div
                className="d-flex justify-content-between 
  "
              >
                <div>
                  <p className="fs-16 fw-700 mb-2">{`${rx_user?.patient_name[0].toUpperCase()}${rx_user?.patient_name.slice(
                    1
                  )}`}</p>
                  <p className="fs-16 fw-400 lightgray">{`${rx_user?.gender?.[0].toUpperCase()}${rx_user?.gender?.slice(
                    1
                  )} / ${rx_user?.age} years`}</p>
                </div>
                <div>
                  <p className="mb-2">
                    <span className="fs-16 fw-700">Rx ID </span>{" "}
                    <span className="lightgray">
                      {prescription?.[0]?.rx_id}
                    </span>
                  </p>
                  <p className="lightgray m-0">
                    {convertTimestampToReadableDate(
                      prescription?.[0].createdAt
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <textarea
                style={{ height: "106px" }}
                type="text"
                maxLength={700}
                disabled
                class="form-control mt-10"
                id="exampleInputPassword1"
                value={prescription?.[0]?.follow_up_advice}
              />
            </div>
            <p className="fs-18 fw-500 mt-4">Prescription</p>
            <div
              className="small shadow"
              style={{ border: "1px solid #f7f7f7", borderRadius: "10px" }}
            >
              <div
                className=" "
                style={{
                  maxHeight: "300px",
                  overflow: "scroll",
                  width: "100%",
                  margin: "10px auto",
                  paddingLeft: "8px",
                }}
              >
                {prescription?.[0]?.prescription_medicines?.map(
                  (items, index) => {
                    return (
                      <div
                        // key={index}
                        className="d-flex justify-content-between align-items-center mb-10  fs-14 fw-500"
                        style={{
                          padding: "11px 17px",
                          borderRadius: "8px",
                          overflow: "hidden",
                          overflowWrap: "anywhere",
                          backgroundColor:
                            index % 2 === 0 ? "#f3fbfe" : "#fff5f4",

                          border: "1px solid #f0ece4",
                        }}
                      >
                        <div className="d-flex flex-column  w-100">
                          <div>
                            {items?.medicine?.brand_name_short}(
                            {items?.medicine?.generic_name})
                          </div>

                          <div className=" cursor-pointer">
                            {/* <EditIcon /> */}
                            <div>
                              <ClockIcon /> &nbsp;
                              <span
                                className="fs-16fw-400"
                                style={{ color: "#5e5992" }}
                              >
                                {items?.dosage_duration &&
                                  `  (${items?.dosage_duration})`}
                              </span>
                              {(items?.intake_morning === 1 ||
                                items?.intake_afternoon === 1 ||
                                items?.intake_evening === 1 ||
                                items?.intake_night === 1) && (
                                <>
                                  <span style={{ color: "#5e5992" }}>
                                    (
                                    <span
                                      className=" fs-16"
                                      style={{ color: "#5e5992" }}
                                    >
                                      {[
                                        items?.intake_morning && "Morning",
                                        items?.intake_afternoon && "Afternoon",
                                        items?.intake_evening && "Evening",
                                        items?.intake_night && "Night",
                                      ]
                                        .filter(Boolean)
                                        .join(", ")}
                                    </span>
                                    )
                                  </span>
                                </>
                              )}
                              {/* )} */}
                              <span
                                className="fs-16 fw-400"
                                style={{ color: "#5e5992" }}
                              >
                                {items?.before_food === 1 && ` (Before Food)`}

                                {items?.after_food === 1 && ` (After Food)`}
                              </span>
                              <span
                                className="fs-16 fw-400"
                                style={{ color: "#5e5992" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
