import "./App.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";

import useIsmobile from "./hooks/useResize";
import { webRoutes } from "utils/webRoutes";
import { mobileRoutes } from "utils/mobileRoutes";
import PrivateRoute from "routes/PrivateRoute";
import Verify from "components/verify-account/Verify";
import PublicRoute from "routes/PublicRoute";

export function WebOrMobile({ component }) {
  const isMobile = useIsmobile();
  return isMobile ? mobileRoutes[component] : webRoutes[component];
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/home" element={<WebOrMobile component="home" />}></Route>
        <Route path="/" element={<WebOrMobile component="home" />}></Route>

        <Route
          path="/edit-profile"
          element={<WebOrMobile component="editProfile" />}
        ></Route>
        <Route
          path="/waiting-list"
          element={<WebOrMobile component="waitingList" />}
        ></Route>
        <Route
          path="/clinical-detail"
          element={<WebOrMobile component="existingUserList" />}
        ></Route>
        <Route
          path="/new-rx"
          element={<WebOrMobile component="newRx" />}
        ></Route>
        <Route
          path="/assistant"
          element={<WebOrMobile component="assistant" />}
        ></Route>
        <Route
          path="/new-rx/:id"
          element={<WebOrMobile component="rxDetails" />}
        />
        <Route
          path="/clinical-detail/:id"
          element={<WebOrMobile component="clinicalDetail" />}
        />
        <Route
          path="/search-rx"
          element={<WebOrMobile component="searchRx" />}
        />
        <Route
          path="/rx-history"
          element={<WebOrMobile component="rxHistory" />}
        />
        <Route
          path="/prescription"
          element={<WebOrMobile component="prescription" />}
        />
      </Route>

      <Route path="/" element={<PublicRoute />}>
        <Route
          path="/confirm-otp"
          element={<WebOrMobile component="confirmOtp" />}
        ></Route>
        <Route path="/" element={<WebOrMobile component="otp" />}></Route>
        <Route
          path="/register"
          element={<WebOrMobile component="register" />}
        ></Route>
        <Route
          path="/welcome"
          element={<WebOrMobile component="welcome" />}
        ></Route>
        <Route path="/login" element={<WebOrMobile component="otp" />}></Route>

        <Route path="/verify" element={<Verify />} />
      </Route>
      <Route path="/" element={<Outlet />}>
        <Route path="/terms" element={<WebOrMobile component="terms" />} />
        <Route path="/about-us" element={<WebOrMobile component="aboutus" />} />

        <Route
          path="/thanks"
          element={<WebOrMobile component="thanks" />}
        ></Route>
        <Route path="*" element={<Navigate to="/home" />} />
      </Route>
    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
