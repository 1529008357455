import React from 'react'
import { Col,Form } from 'react-bootstrap'

const FavouriteDiagnose = ({item,handleDiagnoseData}) => {
   
  return (
    <Col
    xs={12}
    className="d-flex align-items-center justify-content-between"
    style={{marginBottom:'10px'}}
  >
    <div className="d-flex align-items-center">
      <Form.Check
        type="checkbox"
        id={item.id}
        name="formHorizontalRadios"
        className="formRadios"
        value={ item.brand_name_short}
        onChange={(e)=>{handleDiagnoseData(e,item)}}
      />
      <label
        htmlFor={item.id}
        className="ms-2 grey custom-label"
      >
       
          {item.brand_name_short}
      </label>
    </div>
  </Col>
  )
}

export default FavouriteDiagnose