const SearchIcon = ({ active }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#b2b2b2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.60074 0.250977C8.27324 0.250977 10.4482 2.42598 10.4482 5.10098C10.4482 7.77598 8.27324 9.94848 5.60074 9.94848C2.92824 9.94848 0.75074 7.77348 0.75074 5.10098C0.75074 2.42848 2.92574 0.250977 5.60074 0.250977ZM5.60074 8.97098C7.73574 8.97098 9.47074 7.23348 9.47074 5.10098C9.47074 2.96848 7.73574 1.22848 5.60074 1.22848C3.46574 1.22848 1.72824 2.96598 1.72824 5.10098C1.72824 7.23598 3.46574 8.97098 5.60074 8.97098Z"
        {...(active ? { fill: "#145dab" } : {})}
      />
      <path
        d="M5.6007 1.72656C7.4582 1.72656 8.9707 3.23906 8.9707 5.09906C8.9707 6.95906 7.4582 8.46906 5.6007 8.46906C3.7432 8.46906 2.2282 6.95656 2.2282 5.09906C2.2282 3.24156 3.7407 1.72656 5.6007 1.72656ZM3.1957 5.34906C3.3332 5.34906 3.4457 5.23656 3.4457 5.09906C3.4457 3.91156 4.4132 2.94406 5.6007 2.94406C5.7382 2.94406 5.8507 2.83156 5.8507 2.69406C5.8507 2.55656 5.7382 2.44406 5.6007 2.44406C4.1357 2.44406 2.9457 3.63406 2.9457 5.09906C2.9457 5.23656 3.0582 5.34906 3.1957 5.34906Z"
        {...(active ? { fill: "#145dab" } : {})}
      />
      <path
        d="M11.2828 9.5918L13.4328 11.7468C13.6203 11.9343 13.7303 12.1718 13.7428 12.4193C13.7553 12.6543 13.6778 12.8718 13.5228 13.0268C13.3728 13.1743 13.1728 13.2493 12.9628 13.2493C12.7103 13.2493 12.4453 13.1418 12.2403 12.9368L10.1503 10.8443C10.4878 10.8268 10.7553 10.7143 10.9528 10.5118C11.2003 10.2543 11.2753 9.8993 11.2828 9.5918Z"
        {...(active ? { fill: "#145dab" } : {})}
      />
      <path
        d="M9.94016 8.24805L10.7227 9.03055C10.7202 9.04055 10.7227 9.04805 10.7252 9.05805C10.7527 9.15555 10.7852 9.33805 10.7852 9.53555C10.7852 9.76555 10.7402 10.013 10.5927 10.1655C10.4102 10.353 10.0752 10.393 9.59516 10.2855L8.74766 9.43805C8.91016 9.32555 9.13266 9.14805 9.39016 8.89055C9.65016 8.63055 9.82516 8.41055 9.94016 8.24805Z"
        {...(active ? { fill: "#145dab" } : {})}
      />
    </svg>
  );
};
export default SearchIcon;
