import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./uploadcertificate.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import UplaodIcon from "assets/svg/UploadIcon";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import PdfIcon from "assets/svg/PdfIcon";
import UploadIconCross from "assets/svg/UploadIconCross";
import { toBase64 } from "utils/helper";
import useIsmobile from "hooks/useResize";
import ImgIcon from "assets/svg/ImgIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DeleteIcon from "assets/svg/DeleteIcon";
import { toast } from "react-toastify";

export default function UploadCertificate({ setFieldValue, labelstar = true }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUpload, setIsUpload] = useState(true);
  const isMobile = useIsmobile();
  useEffect(() => {
    if (uploadedFiles?.length === 0) {
      setFieldValue("uploadCertificate", "");
    }
  }, [uploadedFiles]);

  const onDrop = async (acceptedFiles) => {
    const filesUploaded = [];
    for (let acceptedFile of acceptedFiles) {
      const preview = URL.createObjectURL(acceptedFile);
      const base64 = await toBase64(acceptedFile);

      filesUploaded.push({
        name: acceptedFile.name,
        base64,
        preview,
        file: acceptedFile,
      });
    }
    setFieldValue("uploadCertificate", [...uploadedFiles, ...filesUploaded]);
    setUploadedFiles((prev) => {
      return [...prev, ...filesUploaded];
    });
    setIsUpload(false);
  };
  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setIsUpload(true);
    }
  }, [uploadedFiles]);

  const handleDelete = (fileIndex) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(fileIndex, 1);

    setUploadedFiles(updatedFiles);
    setFieldValue("uploadCertificate", updatedFiles);
    if (uploadedFiles.length === 1) {
      setIsUpload(true);
    }
  };
  const handleDeleteAll = (e) => {
    e.preventDefault();
    setUploadedFiles([]);
    setFieldValue("uploadCertificate", []);
  };

  return (
    <>
      <Row
        className={`upload-certificate-parent ${
          isMobile ? "flex-column " : "flex-row"
        }  mt-20 `}
      >
        {/* <div className="d-flex justify-content-between"></div> */}
        <div
          className={`${
            uploadedFiles.length !== 0 && !isMobile && "upload-section"
          }`}
        >
          <span className={`lable darkblue ${isMobile ? "fs-12" : "fs-16"}`}>
            Upload License Certificate{" "}
            {labelstar && (
              <span className="label-star" style={{ fontSize: "16px" }}>
                &nbsp;*
              </span>
            )}
          </span>
          <Dropzone
            accept={{
              "application/pdf": [".pdf"],
              "image/png": [".png"],
              "image/jpeg": [".jpeg"],
              "image/jpg": [".jpg"],
            }}
            maxSize={10 * 1024 * 1024}
            multiple={true}
            // disabled={uploadedFiles.length === 3}
            maxFiles={3 - uploadedFiles.length}
            onDropRejected={(a, b) =>
              toast.error("Maximum of 3 files are allowed")
            }
            onDrop={(acceptedFile) => onDrop(acceptedFile)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Form.Label className="label mb-1">
                    {" "}
                    {/* {labelstar && <span className="label-star">&nbsp;*</span>} */}
                  </Form.Label>
                  <Row className="upload-certificate mx-1 cursor-pointer">
                    <>
                      <UplaodIcon />
                      <p
                        className="text-center fs-9 cursor-pointer"
                        style={{
                          color: "#3DB8ED",
                          marginTop: "0.75rem",
                          marginBottom: "0.375rem",
                        }}
                      >
                        Select a file or take a photo
                      </p>
                      <p className="text-center fs-11 cursor-pointer">
                        JPG, PNG or PDF, file size no more than 10MB
                      </p>
                    </>
                  </Row>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {uploadedFiles.length > 0 && (
          <>
            <div
              className="uploaded-certificate"
              style={{ width: !isMobile && "50%" }}
            >
              <span
                className={`lable darkblue ${isMobile ? "fs-12" : "fs-16"}`}
              >
                Uploaded Certificate
              </span>
              {/* {uploadedFiles?.length > 0 && !isUpload && ( */}

              <>
                <div
                  className="upload upload-section "
                  style={{
                    width: !isMobile && uploadedFiles.length !== 0 && "50%",
                  }}
                >
                  <span className="text-end cursor-pointer">
                    {/* Delete All <DeleteIcon onClick={handleDeleteAll} /> */}
                    <OverlayTrigger overlay={<Tooltip>Delete All</Tooltip>}>
                      <span className="tooltip-button">
                        <DeleteIcon onClick={(e) => handleDeleteAll(e)} />
                      </span>
                    </OverlayTrigger>
                    {/* : <button>Click me!</button> */}
                  </span>

                  <div className="upload-child d-flex flex-wrap align-items-center justify-content-center ">
                    {uploadedFiles.length > 0
                      ? uploadedFiles?.map((file, index) => (
                          <div className="col-md-4 mx-6" key={index}>
                            <div className="pdf-icon d-flex justify-content- Regular shadow">
                              {file?.name?.includes("pdf") ? (
                                <PdfIcon />
                              ) : (
                                <ImgIcon />
                              )}
                              <UploadIconCross
                                onClick={() => handleDelete(index)}
                              />
                            </div>
                            <div className="blue fs-9">{file?.name}</div>
                          </div>
                        ))
                      : "asdfasdasdfasd"}
                  </div>
                </div>
                <Form.Label
                  className="label mb-1"
                  onClick={() => setIsUpload(!isUpload)}
                >
                  {/* <span className="upload-more fs-14">Upload More </span> */}
                </Form.Label>
              </>
              {/* )} */}
            </div>
          </>
        )}
      </Row>
    </>
  );
}
UploadCertificate.propTypes = {
  label: PropTypes.string,
};
