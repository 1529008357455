import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import { toast } from "react-toastify";
import { addAssistant, updateAssistant } from "redux-store/slices/assistant";
class AssistantService {
  config = {
    headers: {
      Authorization: `${localStorage.getItem("token")}`,
    },
  };
  async addAssistant(userData) {
    userData.type = "Assistant";
      const response = await AXIOS.post("/assistant", userData, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,  
          "Content-Type": "application/json",
        }
      });
    console.log(response, "RESPONSEEEEE");
    if (response?.data?.status) {
      store.dispatch(
        addAssistant({
          name: userData?.name,
          phoneNumber: userData?.mobile_number,
          id: response?.data?.id,
          assistant_id:response?.data?.id
        })
      );
    }
    return response;
  }
  async updateAssistant(userData) {
 
    const response = await AXIOS.put("/assistant", userData, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,  
        "Content-Type": "application/json",
      }
    });
    store.dispatch(
      updateAssistant({
        name: userData?.assistantDetails?.name,
        phoneNumber: userData?.assistantDetails?.mobile_number,
        id: userData.assistantId,
      })
    );
    return response;
  }
  async getAssistant() {
  
    const response = await AXIOS.get("/assistant", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,  
        "Content-Type": "application/json",
      }
    });
    return response;
  }
  async deleteAssistant(id) {

    const response = await AXIOS.delete("/assistant", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: {
        assistantId: id,
      },
    });
    return response;
  }
}

export default new AssistantService();
