import UtilityButton from "components/buttons/UtilityButton";
import Radios from "components/form-radios/Radios";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user from "services/user";
import InputGroup from "react-bootstrap/InputGroup";

import Loader from "components/loader/Loader";
import { router } from "App";
import Container from "react-bootstrap/Container";

import { toast } from "react-toastify";
import {
  addDiagnosis,
  addDiagnosticPrescriptionDetails,
  addPrescriptionDetails,
  clearAddPrescriptionDetails,
  clearDiagnosticsPrescriptionsDetails,
  getsearchIdParam,
  searchRxMobileNumber,
} from "redux-store/slices/user";
import { useLocation } from "react-router-dom";
import { store } from "redux-store/store";
import { convertTimestampToReadableDate } from "utils/helper";
import { Spinner } from "react-bootstrap";

export default function SearchRx() {
  // const [mobileNumber, setMobileNumber] = useState("");
  const [existingMemberId, setExistingMemberId] = useState("");
  const [validMobileNumber, setValidMobileNumber] = useState(false);
  const location = useLocation;
  const [lastRx, setLastRx] = useState();
  const [addMembers, setAddMembers] = useState(false);
  const dispatch = useDispatch();
  const { SEARCH_RX: getSearchRxLoading, Rx_USER: getRxLoading } = useSelector(
    (state) => state.loading
  );
  const { searchRxMobileNumber: mobileNumber } = useSelector(
    (state) => state.user
  );

  const data = useSelector((state) => state.user);
  const { searchRxuser } = data || {};
  const handleMobileChange = (e) => {
    dispatch(searchRxMobileNumber(e.target.value));

    if (e.target.value.length < 10) {
      setValidMobileNumber(true);
    } else {
      setValidMobileNumber(false);
    }
    if (e.target.value.length > 9) {
      user.searchRx(e.target.value);
    }
  };
  const handleSearchRx = () => {
    if (existingMemberId) {
      router.navigate(`/rx-history?memberId=${existingMemberId}`);
    } else {
      toast.error("Please Select a Member");
    }
  };
  useEffect(() => {
    store.dispatch(getsearchIdParam({ pathName: location.pathname }));
  }, []);

  const copyRxData = (event,userId, prescriptionMedicine, follow_up_advice) => {
    event.stopPropagation()
    store.dispatch(clearDiagnosticsPrescriptionsDetails({}))
    store.dispatch(clearAddPrescriptionDetails({}))
    prescriptionMedicine.forEach(item => {
      if(item.medicine?.is_diagnostic==1){

        let data={
          "brand_name_short": item.medicine?.brand_name_short,
          "additional_instruction": item.additional_instruction,
          "is_diagnostic": item.medicine?.is_diagnostic,
        } 
        store.dispatch(
          addDiagnosticPrescriptionDetails({
            id: userId,
            diagnosis: "",
            medicalDetail: data,
          })
        );
      }else{
        let data = {
          brand_name_short: item.medicine.brand_name_short,
          dosage_duration: item.dosage_duration,
          additional_instruction: item?.additional_instruction,
          intake_morning: item?.intake_morning,
          intake_afternoon: item?.intake_afternoon,
          intake_evening: item?.intake_evening,
          intake_night: item?.intake_night,
          before_food: item?.before_food,
          after_food: item?.after_food,
          is_diagnostic: item.medicine.is_diagnostic,
        };
        store.dispatch(
          addPrescriptionDetails({
            id: userId,
            diagnosis: "",
            medicalDetail: data,
          })
        );
      }
    });
   
    store.dispatch(
      addDiagnosis({ existingMemberId: userId, value: follow_up_advice })
    );

    router.navigate(`/new-rx/${userId}`);
  };

  useEffect(() => {
    const data = async () => {
      const data = await user.lastTenRx();
      setLastRx(data?.data?.prescription);
    };
    data();
  }, []);
  return (
    <>
      <Container className="p-16 mt-20">
        <Mobileheader
          pageName="Search Rx"
          onClick={() => router.navigate("/home")}
        />

        <div className="mt-30 mb-20">
          <label className="fs-16 fw-400 mb-8">Patient Phone Number</label>

          <InputGroup style={{ height: "46px", flexWrap: "nowrap" }} size="lg">
            <InputGroup.Text
              id="inputGroup-sizing-lg"
              style={{ background: "#f5f6f6" }}
            >
              +91
            </InputGroup.Text>
            <Form.Control
              aria-label="Large"
              name="phoneNumber"
              inputMode="numeric"
              maxLength={10}
              type="text"
              autoComplete="off"
              aria-describedby="inputGroup-sizing-sm"
              value={Number(mobileNumber) || ""}
              style={{ borderLeft: "none", background: "#f5f6f6" }}
              onChange={handleMobileChange}
            />
          </InputGroup>
          {validMobileNumber && (
            <span className="red mt-1">
              {" "}
              Please Enter 10 digits Mobile Number{" "}
            </span>
          )}
        </div>
        {/* {getSearchRxLoading && <Loader />}
        {searchRxuser?.length > 0 && mobileNumber.length == 10 ? (
          <>
            <label
              className="label lightgray fs-16 mb-14 fw-500 black"
              htmlFor="floatingPasswordCustom"
            >
              Existing Member(s)
            </label>
            <div
              className="d-flex justify-content-between flex-wrap "
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              {searchRxuser?.length > 0 ? (
                searchRxuser?.map((users) => {
                  return (
                    <>
                      <div
                        onClick={() => setExistingMemberId(users.id)}
                        style={{ flex: "40%" }}
                      >
                        <Radios label={users?.patient_name} i={users?.id} />
                      </div>
                    </>
                  );
                })
              ) : (
                <p>Members Not gptnn</p>
              )}
            </div>
          </>
        ) : (
          <p
            className="d-flex justify-content-center"
            style={{ color: "#53545A" }}
          >
            {mobileNumber.length == 10 &&
              searchRxuser?.length === 0 &&
              !getSearchRxLoading &&
              "Patient Not Found"}
          </p>
        )} */}

        {!addMembers &&  (
          <Row
            className="d-flex justify-content-center mt-30"
            style={{
              position: "fixed",
              bottom: "3rem",
              width: "98%",
              marginBottom: "18px",
            }}
          >
            <UtilityButton
              btnName="Next"
              height="56px"
              fontSize="19px"
              disabled={searchRxuser?.length > 0 && mobileNumber.length =="10" ? false : true}
              onClick={handleSearchRx}
              // onClick={() =>
              //   existingMemberId
              //     ? router.navigate(`/new-rx/${existingMemberId}`)
              //     : toast.error("Please Select the Member")
              // }
            />
          </Row>
        )}
      </Container>
      <div
      style={{padding:'0px 10px'}}
          >
            {!getSearchRxLoading && mobileNumber?.length !== 10  ? (
              <label
                className="label lightgray fs-16 mb-14 fw-500 black"
                htmlFor="floatingPasswordCustom"
              >
                Last 10  Rx
              </label>
            ) : null}
            <div className="d-flex flex-wrap justify-content-between" style={{ gap: '15px',  }}>
            { !getSearchRxLoading && mobileNumber?.length !== 10 
              ? lastRx?.map((item, index) => {
                  return (
                    <div
                    className="cursor-pointer"
                    onClick={() =>
                      router.navigate(
                        `/prescription?user_id=${item.user_id}?&prescription_id=${item.id}`
                      )
                    }
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      background: '#fbfbfb',
                      padding: "18px 11px",
                      border: "1px solid #e3eaee",
                    }}
                  >
                 
                      <h6 className="">
                        {item.rx_user.patient_name} <span>({item.rx_user?.age}/
                          {item.rx_user?.gender == "male" ? "M" : "F"})</span>
                      </h6>
                      <div className="d-flex justify-content-between"> 
                      <span
                          className="text-center fw-700"
                          style={{
                            display: "inline-block",
                            width: "32%",
                            backgroundColor: "pink",
                            fontSize: "12px",
                            padding: "8px",
                            borderRadius: "6px",
                            marginRight: "4px",
                          }}
                        >
                         
                          {convertTimestampToReadableDate(
                            item?.rx_user?.createdAt
                          )}
                        </span>
                      <div
                        style={{
                          // marginTop: "2px",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-end", // Align items to the right
                          gap: "10px", // Add space between buttons
                        }}
                      >
                        <button
                          className="fs-11 gray   fw-500 border-0 p-2"
                          variant="light"
                          style={{
                            color: "#19104e",
                            backgroundColor: "#d8f1fb",
                            borderRadius:"5px"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(item.pdf_url, "_blank");
                          }}
                        >
                          Open Rx
                        </button>
                        <button
                          className="fs-11 gray  p-2 fw-500 border-0"
                          variant="light"
                          style={{
                            color: "#19104e",
                            backgroundColor: "#d8f1fb",
                            borderRadius:"5px"
                          }}
                          onClick={(e) =>
                            copyRxData(
                              e,
                              item?.user_id,
                              item?.prescription_medicines,
                              item.follow_up_advice
                            )
                          }
                        >
                          Copy Rx
                        </button>
                      </div>
                      </div>
                    
                  </div>
                    
                  );
                })
              : null}
              </div>
            {searchRxuser?.length === 0 && getSearchRxLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : mobileNumber?.length == 10 && searchRxuser.length > 0 ? (
              <>
                <label
                  className="label lightgray fs-16 mb-14 fw-500 black"
                  htmlFor="floatingPasswordCustom"
                >
                  Existing Member(s)
                </label>
                <div className="d-flex justify-content-between flex-wrap ">
                  {searchRxuser?.length > 0 &&
                    mobileNumber?.length == 10 &&
                    searchRxuser?.map((users, index) => (
                      <div
                        key={index}
                        style={{ flex: "40%" }}
                        onClick={() => setExistingMemberId(users.id)}
                      >
                        <Radios label={users?.patient_name} i={users?.id} />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <span
                className="d-flex justify-content-center"
                style={{ color: "#53545A" }}
              >
                {mobileNumber.length == 10 &&
                  searchRxuser?.length === 0 &&
                  !getRxLoading &&
                  "Patient Not Found"}
              </span>
            )}
          </div>
    </>
  );
}
