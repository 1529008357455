import HomeIcon from "assets/svg/HomeIcon";
import ProfileIcon from "assets/svg/ProfileIcon";
import SavedIcon from "assets/svg/SavedIcon";
import SearchIcon from "assets/svg/SearchIcon";
import React from "react";

import "./mobilebottomtab.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { router } from "App";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MobileBottomTab() {
  const { pathname } = useLocation();
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const { idParam, searchIdParam, prescriptionIdParam } = useSelector(
    (state) => state.user
  );
  const handleNavigate = () => {
    if (idParam) {
      router.navigate(`/new-rx/${idParam}`);
    } else {
      router.navigate("/new-rx");
    }
  };

  const handleSearchNavigate = () => {
    if (searchIdParam.pathName === "/rx-history") {
      router.navigate(`/rx-history?memberId=${searchIdParam.memberId}`);
    } else if (prescriptionIdParam?.pathName?.pathname === "/prescription") {
      router.navigate(
        `/prescription?user_id=${prescriptionIdParam.user_id}&prescription_id=${prescriptionIdParam.prescription_id}`
      );
    } else {
      router.navigate(`/search-rx`);
    }
  };
console.log(userData,"userData")
  return (
    <Navbar
      className="Small shadow"
      bg="white"
      data-bs-theme="light"
      style={{
        position: "fixed",
        bottom: "0px",
        width: "100%",
        marginTop: "40px",
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
      }}
    >
      <Container>
        <Nav
          className="me-auto d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <Nav.Link
            className={`${
              (pathname.includes("/home") || pathname.length === 1) &&
              "navlinkColor"
            } `}
            active={pathname.includes("/home") || pathname.length === 1}
            onClick={() => router.navigate("/home")}
          >
            <span className="mobiletabhover d-flex justify-content-center">
              <HomeIcon active={pathname.includes("/home")} />
              <span
                className={`ml-6 mobile-text ${
                  pathname.includes("/home") ||
                  (pathname.length === 1 && "navlinkColor")
                    ? ""
                    : "section"
                }`}
              >
                Home
              </span>
            </span>
          </Nav.Link>
          {userData && userData.type == "Assistant" ? null : (
            <>
              {" "}
              <Nav.Link
                className={`${pathname.includes("/new-rx") && "navlinkColor"} `}
                active={pathname.includes("/new-rx")}
                onClick={handleNavigate}
                href="#features"
              >
                <span className="mobiletabhover d-flex justify-content-center">
                  <SavedIcon active={pathname.includes("/new-rx")} />
                  <span
                    className={`ml-6 mobile-text ${
                      pathname.includes("/new-rx") ? "" : "section"
                    }`}
                  >
                    New Rx
                  </span>
                </span>
              </Nav.Link>
              <Nav.Link
                className={`${
                  pathname.includes("/search-rx") ||
                  pathname.includes("/rx-history") ||
                  pathname.includes("/prescription")
                    ? "navlinkColor"
                    : ""
                }`}
                active={
                  pathname.includes("/search-rx") ||
                  pathname.includes("/rx-history") ||
                  pathname.includes("/prescription")
                }
                onClick={handleSearchNavigate}
                href="#features"
              >
                <span className="mobiletabhover d-flex justify-content-center align-items-center">
                  <SearchIcon
                    active={
                      pathname.includes("/search-rx") ||
                      pathname.includes("/rx-history") ||
                      pathname.includes("/prescription")
                    }
                  />
                  <span
                    className={`ml-6  mobile-text ${
                      pathname.includes("/search-rx") ||
                      pathname.includes("/rx-history") ||
                      pathname.includes("/prescription")
                        ? ""
                        : "section"
                    }`}
                  >
                    Search Rx
                  </span>
                </span>
              </Nav.Link>
            </>
          )}

        {userData && userData.type=="Assistant"?  <Nav.Link
            className={`${
              (pathname.includes("/clinical-detail") || pathname.length === 1) &&
              "navlinkColor"
            } `}
            active={pathname.includes("/clinical-detail") || pathname.length === 1}
            onClick={() => router.navigate("/clinical-detail")}
          >
            <span className="mobiletabhover d-flex justify-content-center">
            <ProfileIcon active={pathname.includes("/clinical-detail")} />
              <span
                className={`ml-6 mobile-text ${
                  pathname.includes("/clinical-detail") ||
                  (pathname.length === 1 && "navlinkColor")
                    ? ""
                    : "section"
                }`}
              >
                Clinical  Detail
              </span>
            </span>
          </Nav.Link>:  <Nav.Link
            className={`${
              pathname.includes("/edit-profile") && "navlinkColor"
            } `}
            active={pathname.includes("/edit-profile")}
            onClick={() => router.navigate("/edit-profile")}
          >
            <span className="mobiletabhover d-flex justify-content-center">
              <ProfileIcon active={pathname.includes("/edit-profile")} />
              <span
                className={`ml-6 mobile-text     ${
                  pathname.includes("/edit-profile") ? "" : "section"
                }`}
              >
                Profile
              </span>
            </span>
          </Nav.Link>}
        </Nav>
      </Container>
    </Navbar>
  );
}
