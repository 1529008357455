const 
EditIcon = () => {
  return (
    <>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="13" cy="13" r="13" fill="#3DB8ED" fill-opacity="0.2" />
        <g clip-path="url(#clip0_597_2948)">
          <path
            d="M15.1189 8.28809C15.2891 8.11782 15.4912 7.98275 15.7137 7.89059C15.9361 7.79844 16.1745 7.75099 16.4153 7.75098C16.6561 7.75096 16.8945 7.79837 17.117 7.89049C17.3394 7.98262 17.5416 8.11766 17.7118 8.2879C17.8821 8.45814 18.0172 8.66025 18.1093 8.88269C18.2015 9.10514 18.2489 9.34355 18.2489 9.58433C18.249 9.8251 18.2015 10.0635 18.1094 10.286C18.0173 10.5084 17.8823 10.7106 17.712 10.8808L17.3738 11.2187L14.781 8.62596L15.1189 8.28809ZM14.2508 9.15659L8.63402 14.7741C8.4449 14.9631 8.31442 15.2027 8.25827 15.4641L7.75839 17.7966C7.74521 17.8578 7.74759 17.9214 7.76531 17.9815C7.78303 18.0416 7.81553 18.0963 7.85983 18.1406C7.90414 18.185 7.95885 18.2174 8.01895 18.2352C8.07905 18.2529 8.14263 18.2553 8.20389 18.2421L10.5364 17.7422C10.7978 17.6861 11.0374 17.5556 11.2264 17.3665L16.8439 11.749L14.2508 9.15659Z"
            fill="#3DB8ED"
          />
        </g>
        <defs>
          <clipPath id="clip0_597_2948">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(7 7)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export default EditIcon;
