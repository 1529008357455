const UploadIconCross = ({ onClick }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="upload-croos-icon"
      onClick={onClick}
    >
      <circle cx="5.17808" cy="5.17808" r="5.17808" fill="#F71111" />
      <g clip-path="url(#clip0_381_1204)">
        <path
          d="M6.76821 4.2454L5.54794 5.4658L6.76821 6.68615C6.94972 6.86773 6.94972 7.16186 6.76821 7.34344C6.67752 7.43413 6.55862 7.47951 6.43977 7.47951C6.32073 7.47951 6.20182 7.4342 6.1112 7.34344L4.89065 6.12296L3.67021 7.34343C3.57953 7.43412 3.46061 7.4795 3.34166 7.4795C3.22275 7.4795 3.10392 7.43419 3.01315 7.34343C2.83164 7.16193 2.83164 6.86779 3.01315 6.68614L4.2334 5.46579L3.01309 4.2454C2.83158 4.06389 2.83158 3.76969 3.01309 3.58818C3.19456 3.40681 3.48859 3.40681 3.67014 3.58818L4.89064 4.80858L6.11106 3.58818C6.29264 3.40681 6.5867 3.40681 6.76814 3.58818C6.94972 3.76969 6.94972 4.06389 6.76821 4.2454Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_381_1204">
          <rect
            width="4.02739"
            height="4.0274"
            fill="white"
            transform="translate(2.87695 3.45215)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UploadIconCross;
