import HelpLineIcon from "assets/svg/HelpLineIcon";
import LogoIcon from "assets/svg/LogoIcon";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./webnavbar.css";
import { router } from "App";
import { useLocation } from "react-router-dom";
import user from "services/user";
import { useSelector } from "react-redux";
import WhatsApp from "assets/svg/WhatsApp";
import {
  Col,
  Dropdown,
  DropdownButton,
  Image,
  NavDropdown,
} from "react-bootstrap";
import LogoutButton from "components/buttons/LogoutButton";
import ConfirmationModal from "components/modals/ConfirmationModal";
import { store } from "redux-store/store";
import { clearIdParam, getIdParam } from "redux-store/slices/user";

export default function WebNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const [isModal, setIsModal] = useState(false);
  const { data, idParam, searchIdParam, prescriptionIdParam } = useSelector(
    (state) => state.user
  );
  const { full_name, licence_number, degree_speciality } = data || {};
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  useEffect(() => {
    if (userData) {
      user.getUser(userData.id);
    }
  }, []);

  const handleNavigate = () => {
    if (idParam) {
      router.navigate(`/new-rx/${idParam}`);
    } else {
      router.navigate("/new-rx");
    }
  };

  const handleSearchNavigate = () => {
    if (searchIdParam.pathName === "/rx-history") {
      router.navigate(`/rx-history?memberId=${searchIdParam.memberId}`);
    } else if (prescriptionIdParam?.pathName?.pathname === "/prescription") {
      router.navigate(
        `/prescription?user_id=${prescriptionIdParam.user_id}&prescription_id=${prescriptionIdParam.prescription_id}`
      );
    } else {
      router.navigate(`/search-rx`);
    }
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary Larger shadow web-nav
        style
        
"
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "9",
        }}
      >
        <Container>
          <Navbar.Brand>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => router.navigate("/home")}
            >
              <LogoIcon width="56px" height="53px" />
              <span
                style={{
                  color: "#0669CD",
                  fontSize: "28px",
                  fontWeight: 800,
                  fontFamily: "EB Garamond",
                }}
              >
                Clear Rx
              </span>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto" style={{ color: "#636e88" }}>
              <Nav.Link
                className="fs-18 m-20 nav-menu"
                active={pathname.includes("/home") || pathname.length === 1}
                onClick={() => router.navigate("/home")}
              >
                Home
              </Nav.Link>
              {userData.type == "Assistant" ? null : (
                <Nav.Link
                  active={pathname.includes("/new-rx")}
                  className="fs-18 m-20 nav-menu"
                  onClick={handleNavigate}
                >
                  New Rx
                </Nav.Link>
              )}
              {userData.type == "Assistant" ? null : (
                <Nav.Link
                  active={
                    pathname.includes("/search-rx") ||
                    pathname.includes("/rx-history") ||
                    pathname.includes("/prescription")
                  }
                  className="fs-18 m-20 nav-menu"
                  onClick={handleSearchNavigate}
                >
                  Search Rx
                </Nav.Link>
              )}
              {userData.type == "Assistant" ? (
                <Nav.Link
                  active={pathname.includes("/clinical-detail")}
                  onClick={() => router.navigate("/clinical-detail")}
                  className="fs-18 m-20 nav-menu"
                >
                  Patient Detail
                </Nav.Link>
              ) : null}
              {userData.type === "Assistant" ? null : (
                <Nav.Link
                  active={pathname.includes("/edit-profile")}
                  onClick={() => router.navigate("/edit-profile")}
                  className="fs-18 m-20 nav-menu"
                >
                  Profile
                </Nav.Link>
              )}
              {userData.type == "Assistant" ? null : (
                <Nav.Link
                  active={pathname.includes("/assistant")}
                  onClick={() => router.navigate("/assistant")}
                  className="fs-18 m-20 nav-menu"
                >
                  Assistant
                </Nav.Link>
              )}
            </Nav>
            <Nav>
              <Nav.Link className="right-nav-item" href="#deets">
                {userData && userData.type == "Assistant" ? (
                  <>
                    <div onClick={() => setIsModal(!isModal)}>
                      <LogoutButton />
                    </div>
                    {isModal && (
                      <ConfirmationModal
                        setIsModal={setIsModal}
                        onClick={() => {localStorage.Clear();   store.dispatch(clearIdParam());}}
                      />
                    )}
                  </>
                ) : (
                  !isOpen && (
                    <DropdownButton
                      id="dropdown-menu-align-end"
                      title={full_name?.[0].toUpperCase()}
                      style={{
                        borderRadius: "50%",
                        backGroundColor: "#d8f1fb",
                      }}
                    >
                      <div className="m-2">
                        <p className="m-0" href="#/action-1">
                          Dr - {full_name}
                        </p>
                        <hr className="m-1" />
                        <p className="m-0">{degree_speciality}</p>
                        <hr className="m-1" />

                        <p className="m-0">License -{licence_number}</p>
                      </div>
                    </DropdownButton>
                  )
                )}
                <div className="header-left">
                  <HelpLineIcon /> <span className="ml-6">Help</span>
                  <span
                    className="ml-8"
                    onClick={() =>
                      window.open("http://wa.me/+916366222791", "_blank")
                    }
                  >
                    <WhatsApp />
                  </span>
                </div>
                {/* <div className="avatar" onClick={() => setIsOpen(!isOpen)}>
                  A
                </div> */}

                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <Image src="holder.js/171x180" rounded />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
