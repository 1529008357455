import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { addDiagnosticPrescriptionDetails, addPrescriptionDetails, filterDiagnosticePrescriptionDetails, updateDiagnosticPrescriptionDetails } from "redux-store/slices/user";
import { store } from "redux-store/store";
import { useParams } from "react-router-dom";
import favourite from "services/favourite";
import { toast } from "react-toastify";
import TrashIcon from "assets/svg/TrashIcon";
import favouriteDiagnostic from "services/favouriteDiagnostic";
import FavouriteDiagnose from "./favouriteDiagnose";
import useIsMobile from "hooks/useResize";

const ModalBox = (props) => {
  const isMobile = useIsMobile();
  const [selectedItems, setSelectedItems] = useState([]);
  const [favTab, setFavTab] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const { id } = useParams();
  const [selectedFavouriteMedicine, setSelectedFavouriteMedicine] = useState(
    []
  );
  const [selectedDiagnoseData, setSelectedDiagnoseData] = useState([]);
  const [diagnosData, setDiagnoseData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{
setFavTab('medicine')
  },[props.show])
  useEffect(() => {
    // Initialize filtered data with the full list of favorite medicines from props
    setFilteredData(props.favMedData);
  }, [props.favMedData]);
  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if (item?.med_data_json.length === 1) {
        setSelectedItems([...selectedItems, item]);
      }
      if (item.med_data_json.length > 0) {
        setSelectedFavouriteMedicine([
          ...selectedFavouriteMedicine,
          { medicine_id: item.medicine_id, med_data_json: item.med_data_json },
        ]);
      }
    } else {
      setSelectedFavouriteMedicine((prevFavouriteMedicines) =>
        prevFavouriteMedicines.filter((i) => i.medicine_id !== item.medicine_id)
      );
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((i) => i.id !== item.id)
      );
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    // Filter the data based on the search term
    let filtered;
    if (favTab === "medicine") {
      filtered = props.favMedData.filter((item) =>
        item.brand_name.toLowerCase().includes(searchValue)
      );
    } else {
      filtered = diagnosData.filter((item) =>
        item.brand_name_short.toLowerCase().includes(searchValue)
      );
    }

    setFilteredData(filtered);
  };
  useEffect(() => {
    const handleFavData = async () => {
      setShowLoading(true);
      if (favTab == "medicine") {
        const data = await favourite.getFavouriteList();
        setShowLoading(false);
        setFilteredData(data);
      } else {
        const data = await favouriteDiagnostic.getFavouriteDiagnostice();
        setShowLoading(false);
        setFilteredData(data);
        setDiagnoseData(data);
      }
    };
    handleFavData();
  }, [favTab]);

  const addDataToRX = () => {
    if(favTab=="medicine"){
      selectedItems.forEach((item) => {
        const data = {
          id: item?.id,
          medicine_id: item?.medicine_id,
          brand_name: item.brand_name,
          brand_name_short: `${item.brand_name} ${
            item.generic_name ? `(${item.generic_name})` : ""
          }`,
          generic_name: item.generic_name,
          medicine_name: `${item.brand_name} (${item.generic_name})`,
          dosage_duration: item.med_data_json[0].dosage_duration,
          additional_instruction: item?.med_data_json[0].additional_instruction,
          intake_morning: item?.med_data_json[0].intake_morning,
          intake_afternoon: item?.med_data_json[0].intake_afternoon,
          intake_evening: item?.med_data_json[0].intake_evening,
          intake_night: item?.med_data_json[0].intake_night,
          before_food: item?.med_data_json[0].before_food,
          after_food: item?.med_data_json[0].after_food,
          is_diagnostic: 0,
          fav: 1,
        };
        store.dispatch(
          addPrescriptionDetails({
            id: id,
            diagnosis: "",
            medicalDetail: data,
          })
        );
      });
    }else{
selectedDiagnoseData.forEach((item)=>{
  store.dispatch(
    addDiagnosticPrescriptionDetails({
      id: id,
      diagnosis: '',
      medicalDetail: item,
    })
  );
})
     
    }
   
    props.onHide();
  };

  const handleDeleteFav = () => {
    if(favTab=='medicine'){
      const selectedId = selectedItems.map((item) => item.id);
      if (selectedId.length <= 0) {
        toast.warning("Please Select medicine you want to delete ");
        return;
      }
      selectedItems.forEach((item) => {
        props.setSelectedIndexes((prevIndexes) => {
          return prevIndexes.filter((i) => i !== item.medicine_id);
        });
      });
      if (selectedItems.length === 1) {
        favourite.deleFavouriteItem(
          selectedId,
          selectedItems[0].med_data_json[0].dosage_duration
        );
      } else {
        favourite.deleFavouriteItem(selectedId);
      }
    }else{
      const selectedId=selectedDiagnoseData.map((item)=>item.id)
      if (selectedId.length <= 0) {
        toast.warning("Please Select medicine you want to delete ");
        return;
      }
      selectedId.forEach((filterId)=>{
        store.dispatch(
          filterDiagnosticePrescriptionDetails({
            id: id,
            filterId: filterId,
           
          })
        );
      })
      const filteredDiagnoseData = selectedDiagnoseData.filter((item) => 
        !selectedId.includes(item.id)  
      );
      
      setSelectedDiagnoseData(filteredDiagnoseData);
      favouriteDiagnostic.deleteFavouriteDiagnosstice(selectedId)
     
      
    }
   
    props.onHide();
  };
  const handleDiagnoseData = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedDiagnoseData([...selectedDiagnoseData, item]);
    } else {
      setSelectedDiagnoseData((prev) => prev.filter((i) => i.id !== item.id));
    }
  };
  const addSelectedVariant = (e, item, med) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const obj = {
        id: item?.id,
        medicine_id: item?.medicine_id,
        brand_name: item?.brand_name,
        generic_name: item?.generic_name,
        doctor_id: item?.doctor_id,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        med_data_json: [med],
      };

      setSelectedItems((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter((i) => i.id !== item.id);
        return [...filteredItems, obj];
      });
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((i) => i.id !== item.id)
      );
    }
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [props.show]);
  const generateLabel = (detail) => {
    return (
      <>
        {detail.dosage_duration}
        {(detail?.intake_morning === 1 ||
          detail?.intake_afternoon === 1 ||
          detail?.intake_evening === 1 ||
          detail?.intake_night === 1) && (
          <>
            <span style={{ color: "#5e5992" }}>
              (
              <span className={` "fs-16"} fw-400`} style={{ color: "#5e5992" }}>
                {[
                  detail?.intake_morning && "Morning",
                  detail?.intake_afternoon && "Afternoon",
                  detail?.intake_evening && "Evening",
                  detail?.intake_night && "Night",
                ]
                  .filter(Boolean)
                  .join(", ")}
              </span>
              )
            </span>
          </>
        )}
        <span className={`"fs-16"} fw-400`} style={{ color: "#5e5992" }}>
          {detail?.additional_instruction?.length > 0 && (
            <span>({detail?.additional_instruction})</span>
          )}
        </span>
      </>
    );
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop
      className="custom-modal"
    >
      <Modal.Header closeButton style={{ justifyContent: "unset" }}>
        <Modal.Title id="contained-modal-title-vcenter">Favourites</Modal.Title>
{
  !isMobile &&  < >
  <Col className="d-flex align-items-center justify-content-center">
    <input
      type="radio"
      id="medicine"
      value="medicine"
      name="medincine"
      className="formRadios "
      onChange={(e) => {
        setFavTab(e.target.value);
      }}
      checked={favTab == "medicine" ? true : false}
    />
    <label
      className="lightgrey "
      htmlFor="medicine"
      style={{ color: "#999999" }}
    >
      Medicine
    </label>
  </Col>
  <Col className="d-flex align-items-center">
    <input
      type="radio"
      id="Diagnostics"
      value="Diagnostics"
      name="Diagnostics"
      className="formRadios "
      onChange={(e) => {
        setFavTab(e.target.value);
      }}
      checked={favTab == "Diagnostics" ? true : false}
    />
    <label
      className="lightgrey "
      htmlFor="Diagnostics"
      style={{ color: "#999999" }}
    >
      Diagnostics
    </label>
  </Col>
</> 
}
        
      </Modal.Header>
      {isMobile && <div className="mt-15 d-flex">
          <Col className="d-flex align-items-center justify-content-center">
            <input
              type="radio"
              id="medicine"
              value="medicine"
              name="medincine"
              className="formRadios "
              onChange={(e) => {
                setFavTab(e.target.value);
              }}
              checked={favTab == "medicine" ? true : false}
            />
            <label
              className="lightgrey "
              htmlFor="medicine"
              style={{ color: "#999999" }}
            >
              Medicine
            </label>
          </Col>
          <Col className="d-flex align-items-center">
            <input
              type="radio"
              id="Diagnostics"
              value="Diagnostics"
              name="Diagnostics"
              className="formRadios "
              onChange={(e) => {
                setFavTab(e.target.value);
              }}
              checked={favTab == "Diagnostics" ? true : false}
            />
            <label
              className="lightgrey "
              htmlFor="Diagnostics"
              style={{ color: "#999999" }}
            >
              Diagnostics
            </label>
          </Col>
        </div>  }
            
      <Modal.Body className="gap-2 nav-stacked custom-modal-body">
        <Container style={{ overflowY: "auto", height: "66vh" }}>
          <input
            type="text"
            id="searchInput"
            className="form-control mb-3"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {showLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : filteredData?.length <= 0 ? (
            <center>No data found</center>
          ) : favTab == "medicine" && !showLoading ? (
            filteredData.map((item) => (
              <Row key={item.id} className="mb-3">
                <Col
                  xs={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      id={item.id}
                      name="formHorizontalRadios"
                      className="formRadios"
                      value={
                        item.brand_name
                          ? item.brand_name
                          : item.brand_name_short
                      }
                      checked={selectedItems.some((i) => i.id === item.id)}
                      onChange={(e) => handleCheckboxChange(e, item)}
                    />
                    <label htmlFor={item.id} className="ms-2 grey custom-label">
                      {item.brand_name
                        ? item.brand_name
                        : item.brand_name_short}
                    </label>
                  </div>
                </Col>
                {item.med_data_json
                  ? item?.med_data_json.length > 1 &&
                    selectedFavouriteMedicine.some(
                      (favItem) => favItem.medicine_id === item.medicine_id
                    ) && (
                      <Col
                        xs={11}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                        className="offset-1 mt-2"
                      >
                        {item.med_data_json.map((detail, index) => (
                          <Form.Check
                            key={`${item.medicine_id}-${index}`}
                            type="radio"
                            id={`${item.medicine_id}-${index}`}
                            name="nestedRadiosBox"
                            label={generateLabel(detail)}
                            className="ms-3 custom-nested-checkbox"
                            onChange={(e) =>
                              addSelectedVariant(e, item, detail)
                            }
                          />
                        ))}
                      </Col>
                    )
                  : null}
              </Row>
            ))
          ) : (
            filteredData.map((item) => {
              return (
                  <FavouriteDiagnose
                    handleDiagnoseData={handleDiagnoseData}
                    item={item}
                  />

              );
            })
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div
          style={{ width: "100%", position: "relative" }}
          className="d-flex justify-content-center gap-4 "
        >
          <div>
            <Button onClick={addDataToRX}>Add to Rx</Button>
          </div>
          <div
            onClick={handleDeleteFav}
            style={{ right: "10px", position: "absolute", cursor: "pointer" }}
          >
            <TrashIcon />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBox;
