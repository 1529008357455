import { router } from "App";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import Loader from "components/loader/Loader";
import RxContentDetail from "components/rx-content-detail/RxContentDetail";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import user from "services/user";
import {
  useNavigate,
} from 'react-router-dom';
import { store } from "redux-store/store";
import { clearIdParam } from "redux-store/slices/user";

export default function RxDetails(props) {
  const [isReview, setisReview] = useState(false);
  const [pageName, setPageName] = useState("New Rx");
  const { id } = useParams();
  const data = useSelector((state) => state.user);
  const loading = useSelector((state) => state.loading);
const navigate=useNavigate()
  const {
    SEARCH_RX: getSearchRxLoading,
    ADD_PRESCRIPTION: addPrescriptionLoading,
  } = loading;
  const { getSingleRxUsers } = data || {};
  const scrollToTopRef = useRef(); 

  useEffect(() => {
    if (id) {
      user.searchUser("id", id);
    }
  }, []);
  const handleReview = () => {
    if (isReview) {
      setisReview(false);
    } else {
      navigate(-1)
      store.dispatch(clearIdParam());
      // router.navigate("/new-rx");
    }
  };

  useEffect(() => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []); 
  return (
    <>

      <Container className="d-flex justify-content-between p-0 mt-42 align-items-center"  ref={scrollToTopRef} >
        <p className="fs-32 fw-700 m-0 w-50">
          {isReview ? "Review" : pageName}{" "}
        </p>
        <TopHeadingIcon width="34" onClick={handleReview} />
      </Container>
      <Container
        className="Larger shadow mt-30 "
        style={{ borderRadius: "10px", marginBottom: "60px" }}
      >
        <div
          style={{
            width: "80%",
            margin: " auto",
            paddingTop: "30px",
            paddingBottom: "5rem",
          }}
        >
          {getSearchRxLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <RxContentDetail
              data={getSingleRxUsers}
              isReview={isReview}
              setIsReview={setisReview}
              setPageName={setPageName}
            />
          )}
        </div>
      </Container>
    </>
  );
}
