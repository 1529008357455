import Thanks from "components/thankyou/Thanks";
import React from "react";

export default function WebThanks() {
  return (
    <>
      <Thanks pfont="fs-36" sfont="fs-26" />
    </>
  );
}
