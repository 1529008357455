import { WebOrMobile } from "App";
import WebNavbar from "components/WebComponet/web-navbar/WebNavbar";
import useIsMobile from "hooks/useResize";
import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "utils/helper";

export default function PrivateRoute({ component: Component, ...rest }) {
  const isMobile = useIsMobile();

  return isAuthenticated() ? (
    <>
      <div>
        {!isMobile && <WebOrMobile component="navbar" />}
        <Outlet />
        <WebOrMobile component="footer" />
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
}
