import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import DrugModal from "./DrugModal";
import { store } from "redux-store/store";
import { updateMedicine } from "redux-store/slices/user";
import { toast } from "react-toastify";
import user from "services/user";
import { useSelector } from "react-redux";
import Loader from "components/loader/Loader";

let pagN = 0;
let isDataEmpty = false;
export default function PreceptionModal({
  medicines,
  setIsOpen,
  isOpen,
  setIsAddDrugModalOpen,
  setMedDetail,
  medicineDetail,
  setMedicineDetail,
  medicineName,
  loading,
  searchPageOptions,
  setDropDownKey,
}) {
  const [isOpenDrugModal, setIsOpenDrugModal] = useState(false);

  const handleClick = (selectedMedicine) => {
    const data = {
      id: selectedMedicine.id,
      brand_name_short: selectedMedicine?.brand_name_short,
      generic_name: `${selectedMedicine?.brand_name_short} ${
        selectedMedicine?.generic_name && `(${selectedMedicine?.generic_name})`
      }`,
    };

    setMedicineDetail(data);

    setIsOpenDrugModal(true);
    setIsAddDrugModalOpen(true);

    setMedDetail({
      value: selectedMedicine.id,
      label: selectedMedicine.brand_name_short,
      generic_name: selectedMedicine.generic_name,
    });
    setIsOpen(false);
  };
  const handleClose = () => {
    setDropDownKey((prev) => prev + 1);
    store.dispatch(updateMedicine());
    setIsOpen(false);
  };

  useEffect(() => {
    pagN = searchPageOptions.page;
    isDataEmpty = false;
  }, []);

  const observerTarget = useRef(null);

  const fetchData = async () => {
    pagN = pagN + 1;
    const data = await user.searchMedicine(searchPageOptions.searchText, pagN);
    if (data?.data?.length === 0) {
      isDataEmpty = true;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (!isDataEmpty) {
            fetchData();
          }
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <>
      <Modal
        show={isOpen}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <Modal.Header
          className="border-0 justify-content-center text-center w-100"
          closeButton
        >
          <p
            className="d-flex fs-16 m-0 fw-500 p-0"
            style={{ color: "#253274" }}
          >
            Prescription Results
          </p>
        </Modal.Header>
        <Modal.Body className="mt-20 p-0 mx-12 p-0">
          <div
            className="cstm-scroll"
            style={{
              maxHeight: "413px",
              border: "1px solid #eeeeee",
              borderRadius: "9px 9px 0px 0px",
              overflow: "scroll",
              backgroundColor: "none",
              marginBottom: "10px",
            }}
          >
            {medicines?.length > 0 ? (
              <>
                {medicines?.map((med) => {
                  return (
                    <p
                      className="preception-modal cursor-pointer"
                      style={{
                        borderBottom: "1px solid #eeeeee",
                        padding: "10px",
                      }}
                      onClick={() => handleClick(med)}
                    >
                      {med?.brand_name_short}
                    </p>
                  );
                })}
                {loading && (
                  <div
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </>
            ) : (
              <p className="text-center mt-3 fw-700">Medicines Not Found</p>
            )}
            <div ref={observerTarget}></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
