import user from "services/user";

const getMedicines = async (search, page) => {
  const data = await user.searchMedicine(search, page);
  return data;
};

const optionsPerPage = 10;

export const loadOptions = async (search, page, setSearchPageOptions) => {
  if (search?.length < 4) {
    return {
      options: [],
      hasMore: false,
    };
  }
  setSearchPageOptions({ page, searchText: search });

  const { data, count } = await getMedicines(search, page);
  let options = data.map((d) =>
    // const returnBold
    ({
      value: d.id,
      label: `${d.brand_name_short} (${d.generic_name})`,
      generic_name: d.generic_name,
      is_diagnostic: d.is_diagnostic,
      brand_name: d.brand_name_short,
    })
  );

  let filteredOptions = [];

  const searchLower = search.toLowerCase();
  filteredOptions = options.filter(({ label }) =>
    label.toLowerCase().includes(searchLower)
  );

  let hasMore = Math.ceil(count / optionsPerPage) > page;
  if (data?.length === 0) {
    hasMore = false;
  }

  const slicedOptions = filteredOptions;

  return {
    options: slicedOptions,
    hasMore,
  };
};
