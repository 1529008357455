const SavedIcon = ({active}) => {
  return (
    <>
      <svg
        className="icon-hover"
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.25 0.5C10.25 0.433696 10.2237 0.370107 10.1768 0.323223C10.1299 0.276339 10.0663 0.25 10 0.25H3C2.27065 0.25 1.57118 0.539731 1.05546 1.05546C0.539731 1.57118 0.25 2.27065 0.25 3V17C0.25 17.7293 0.539731 18.4288 1.05546 18.9445C1.57118 19.4603 2.27065 19.75 3 19.75H13C13.7293 19.75 14.4288 19.4603 14.9445 18.9445C15.4603 18.4288 15.75 17.7293 15.75 17V7.147C15.75 7.0807 15.7237 7.01711 15.6768 6.97022C15.6299 6.92334 15.5663 6.897 15.5 6.897H11C10.8011 6.897 10.6103 6.81798 10.4697 6.67733C10.329 6.53668 10.25 6.34591 10.25 6.147V0.5ZM11 10.25C11.1989 10.25 11.3897 10.329 11.5303 10.4697C11.671 10.6103 11.75 10.8011 11.75 11C11.75 11.1989 11.671 11.3897 11.5303 11.5303C11.3897 11.671 11.1989 11.75 11 11.75H5C4.80109 11.75 4.61032 11.671 4.46967 11.5303C4.32902 11.3897 4.25 11.1989 4.25 11C4.25 10.8011 4.32902 10.6103 4.46967 10.4697C4.61032 10.329 4.80109 10.25 5 10.25H11ZM11 14.25C11.1989 14.25 11.3897 14.329 11.5303 14.4697C11.671 14.6103 11.75 14.8011 11.75 15C11.75 15.1989 11.671 15.3897 11.5303 15.5303C11.3897 15.671 11.1989 15.75 11 15.75H5C4.80109 15.75 4.61032 15.671 4.46967 15.5303C4.32902 15.3897 4.25 15.1989 4.25 15C4.25 14.8011 4.32902 14.6103 4.46967 14.4697C4.61032 14.329 4.80109 14.25 5 14.25H11Z"
          // fill="black"
          // fill-opacity="0.3"
          {...(active ? { fill: "#145dab" } : {})}
        />
      </svg>
    </>
  );
};
export default SavedIcon;
