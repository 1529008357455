import React, { useEffect, useState } from "react";
import InputField from "components/input-field/InputField";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PencilIcon from "assets/svg/PencilIcon";
import ConfirmationModal from "components/modals/ConfirmationModal";
import LogoutButton from "components/buttons/LogoutButton";
import * as formik from "formik";
import { EditProfileSchema } from "utils/schema/EditProfileSchema";
import user from "services/user";
import { useSelector } from "react-redux";
import UtilityButton from "components/buttons/UtilityButton";
import PdfIcon from "assets/svg/PdfIcon";
import MobileBottomTab from "components/mobile-components/mobile-bottom-tab/MobileBottomTab";
import auth from "services/auth";
import MobileOtp from "components/loader/mobile-otp/MobileOtp";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import Loader from "components/loader/Loader";
import { toast } from "react-toastify";
import { router } from "App";
import { store } from "redux-store/store";
import { clearIdParam, getIdParam } from "redux-store/slices/user";

export default function EditProfile() {
  const [disable, setDisable] = useState(true);
  const [isInputDisabled, setIsInputDisabled] = useState(true); // Initialize with the desired value
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [isModal, setIsModal] = useState(false);
  const { Formik } = formik;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const UserDetail = useSelector((state) => state.user);

  const { confirm_OTP: mobileOtpLoading } = useSelector(
    (state) => state.loading
  );

  const { Edit_Profile: getUserLoading } = useSelector(
    (state) => state.loading
  );
  const { data } = UserDetail;

  const {
    full_name,
    licence_number,
    degree_speciality,
    mobile_number,
    userDocuments,
    practice_location,
    contact_info,
    timings,
  } = data || {};

  const { id } =userData;
  useEffect(()=>{
    if(userData && userData.type==="Assistant"){
      router.navigate('/home')
    }
      },[])
  useEffect(() => {
    user.getUser(id);
  }, [getUserLoading]);
  
  const handleMobileChange = async (e, latestMobileNumber) => {
    if (String(latestMobileNumber).length == 10) {
      await auth.sendOtp(mobile_number, latestMobileNumber);
      e.stopPropagation();
      setIsInputDisabled(false);
      setShowOtpInput(true);
    } else {
      toast.error("Mobile number must be at least 10 digits.");
    }
  };
  const handleOtp = async (e, newMobileNumber) => {
    setOtp(e);
    if (e.length === 4) {
      await user.editMobileNumber(e, mobile_number, newMobileNumber);
      setShowOtpInput(false);
      setOtp("");
    }
  };
  return (
    <>
      <Container
        className="d-flex justify-content-between  modal-dialog-centered align-items-center mb-30"
        style={{
          margin: "0px",
          maxWidth: "100%",
          backgroundColor: "#105CAA",

          minHeight: "121px",
          borderBottom: "30px",
          borderRadius: "0px 0px 30px 30px",
        }}
      >
        <div className="d-flex align-items-center">
          {/* <div className="my-5 d-flex justify-content-center mr-24">
            <Avtar
              width="60px"
              height="58px"
              src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
            />
          </div> */}
          <div>
            <p className="text-white fs-16 fw-700 m-0">{full_name}</p>
            <p className="text-white fs-14 fw-400 mb-10">{degree_speciality}</p>

            <p
              className=" text-white fs-14 fw-500 m-0 cursor-pointer"
              onClick={() => setDisable(false)}
            >
              <PencilIcon />
              <span className="ml-6"> Edit Profile</span>
            </p>
          </div>
        </div>
        <div onClick={() => setIsModal(!isModal)}>
          <LogoutButton />
        </div>
      </Container>
      <div className="modal-dialog-centered">
        {isModal && (
          <ConfirmationModal
            setIsModal={setIsModal}
            onClick={() => {localStorage.Clear();   store.dispatch(clearIdParam());}}
          />
        )}
      </div>
      <Container>
        <Formik
          validationSchema={EditProfileSchema}
          onSubmit={(values) => {
            setShowOtpInput(false);
            user.editProfile(values, id, otp);
            setDisable(true);
            showOtpInput(false);
          }}
          initialValues={{
            fullName: full_name || "",
            LicenseNumber: licence_number || "",
            deegree: degree_speciality || "",
            location: practice_location || "",
            mobileNumber: mobile_number || "",
            address: contact_info || "",
            timing: timings || "",
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <InputField
                label="Full Name"
                sublabel="as per license certificate"
                name="fullName"
                disable={true}
                value={"Dr. " + values.fullName}
                onChange={handleChange}
                isValid={touched.fullName && !errors.fullName}
              />
              <span className="red">{touched.fullName && errors.fullName}</span>
              <InputField
                label="Registered License Number"
                disable={true}
                name="LicenseNumber"
                value={values.LicenseNumber}
                onChange={handleChange}
                isValid={touched.LicenseNumber && !errors.LicenseNumber}
              />
              <span className="red">
                {touched.LicenseNumber && errors.LicenseNumber}
              </span>
              <InputField
                label="Degree & Speciality"
                disable={disable}
                name="deegree"
                value={values.deegree}
                onChange={handleChange}
                isValid={touched.deegree && !errors.deegree}
              />
              <span className="red">{touched.deegree && errors.deegree}</span>
              <InputField
                label="Clinic/Hospital Name"
                sublabel="optional"
                labelstar={false}
                name="location"
                value={values.location}
                onChange={handleChange}
                disable={disable}
                isValid={touched.location && !errors.location}
              />
              <InputField
                label="Address And Contact Info"
                sublabel="optional"
                labelstar={false}
                name="address"
                value={values.address}
                onChange={handleChange}
                disable={disable}
                isValid={touched.address && !errors.address}
              />

              <InputField
                label="Timing"
                labelstar={false}
                name="timing"
                value={values.timing}
                onChange={handleChange}
                disable={disable}
              />

              <Form.Label className="label mt-3 mb-1">
                <span className="fs-12">Mobile Number</span>
              </Form.Label>
              <InputGroup className="mt-1" size="lg">
                <InputGroup.Text id="inputGroup-sizing-lg" className="fs-14">
                  +91
                </InputGroup.Text>
                <Form.Control
                  className="fs-14"
                  aria-label="Large"
                  type="text"
                  name="mobileNumber"
                  aria-describedby="inputGroup-sizing-sm"
                  inputMode="numeric"
                  pattern="[1-9]{1}[0-9]{9}"
                  value={Number(values.mobileNumber) || ""}
                  placeholder="Mobile Number"
                  maxLength={10}
                  disabled={disable}
                  onChange={handleChange}
                  // isValid={touched.mobileNumber && errors.mobileNumber}
                  style={{ padding: "11px 20px" }}
                />
                {values.mobileNumber !== mobile_number && (
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    disabled={mobileOtpLoading}
                    style={{ width: "66px", fontSize: "10px" }}
                    onClick={(e) => handleMobileChange(e, values.mobileNumber)}
                  >
                    Send OTP
                  </Button>
                )}
              </InputGroup>
              {/* <InputGroup className="mt-1 ">
                <Form.Control
                  className="fs-14"
                  placeholder="Mobile Number"
                  value={values.mobileNumber}
                  name="mobileNumber"
                  size="lg"
                  style={{ padding: "11px 20px" }}
                  disabled={disable}
                  onChange={handleChange}
                  isValid={touched.mobileNumber && !errors.mobileNumber}
                />
                {values.mobileNumber !== mobile_number && (
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    disabled={mobileOtpLoading}
                    onClick={(e) => handleMobileChange(e, values.mobileNumber)}
                  >
                    Send OTP
                  </Button>
                )}
              </InputGroup> */}
              <span className="red">
                {touched.mobileNumber && errors.mobileNumber}
              </span>
              {showOtpInput && (
                <>
                  <Form.Label className="label mt-3 mb-1">
                    <span className="fs-12">Confirm OTP</span>
                  </Form.Label>
                  <div className="mt-2">
                    <MobileOtp
                      otp={otp}
                      onChange={(e) => handleOtp(e, values.mobileNumber)}
                    />
                  </div>
                  {mobileOtpLoading && (
                    <div className="mt-2">
                      <Loader />
                    </div>
                  )}
                </>
              )}
              <Form.Label className="label mt-3 mb-1">
                <span className="fs-12">Uploaded License Certificate</span>
              </Form.Label>
              <div className="upload mx-1">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  {userDocuments?.map((item, index) => (
                    <div className="col-md-4 mx-6" key={index}>
                      <div
                        className="pdf-icon d-flex justify-content-start Regular shadow"
                        onClick={() => window.open(item.url)}
                      >
                        <PdfIcon />
                      </div>
                      <div className="blue fs-9">{item?.document_name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <Row className="mt-30 mb-60">
                <Container className="d-grid gap-2 mb-4" id="fixedbutton-">
                  <UtilityButton btnName="Submit" loading={getUserLoading} />
                </Container>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
      <MobileBottomTab />
    </>
  );
}
