import React, { useState } from "react";
import Description from "components/description/Description";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import { Form } from "react-bootstrap";
import Auth from "services/auth";
import { validateMobileNumber } from "utils/helper";
import LogoIcon from "assets/svg/LogoIcon";
import UtilityButton from "components/buttons/UtilityButton";
import { useSelector } from "react-redux";
import WhatsApp from "assets/svg/WhatsApp";
import HelpLineIcon from "assets/svg/HelpLineIcon";

export default function WebOtp() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const { GET_USER: getUserLoading } = useSelector((state) => state.loading);

  const handleChange = (e) => {
    const enteredNumber = e.target.value;

    setMobileNumber(enteredNumber);
  };
  const handleSendOtp = (e) => {
    e.preventDefault();
    if (validateMobileNumber(mobileNumber)) {
      Auth.sendOtp(mobileNumber);
    } else {
      setIsMobileNumberValid(validateMobileNumber(mobileNumber));
      return;
    }
  };

  return (
    <Container
      className="container-fluid d-flex m-0 "
      style={{ maxWidth: "100%", height: "100vh", padding: "0px" }}
    >
      <div
        className="Register1"
        style={{
          background: "#FAFAFA",
          backgroundImage: `url(/images/confirm-otp-bg.png)`,
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundColor: "#fafafa",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="header-otp mt-12 mb-12">
          <span
            className="ml-8"
            onClick={() => window.open("http://wa.me/+916366222791", "_blank")}
          >
            <div className="header-left header-otp-whatsApp ">
              <>
                <HelpLineIcon /> <span className="ml-6">Help</span> <WhatsApp />
              </>
            </div>
          </span>
        </div>
        <div class="bottom-right">
          <p> </p>
        </div>
      </div>
      <div className="Register2">
        <div className="otp">
          <div className="d-flex align-items-center">
            <LogoIcon width="56px" height="53px" />
            <span
              style={{
                color: "#0669CD",
                fontSize: "28px",
                fontWeight: 800,
                fontFamily: "EB Garamond",
              }}
            >
              Clear Rx
            </span>
          </div>
          <div>
            <Container className="mb-10 mt-12 postition-relative">
              <Description description="Welcome to ClearRx " />

              <span style={{ fontSize: "19px" }}>
                A digital prescription pad for doctors.
              </span>
              <Description description="" subdescription="" />
              <span style={{ fontSize: "19px" }}>
                A faster way to generate NMC compliant digital Rx.
              </span>
              <Description description="" subdescription="" />
              <span style={{ fontSize: "18px" }}>
                Try it for free for 6 months
              </span>
            </Container>
            <Form onSubmit={handleSendOtp}>
              <Container className=" mb-14 mt-20 ">
                <label
                  className="label fs-18 lightgray"
                  htmlFor="floatingPasswordCustom"
                >
                  Login/Register
                </label>

                <InputGroup
                  style={{ height: "56px", marginBottom: "6px" }}
                  size="lg"
                >
                  <InputGroup.Text
                    className="bg-white"
                    id="inputGroup-sizing-lg"
                  >
                    +91
                  </InputGroup.Text>

                  <Form.Control
                    aria-label="Large"
                    type="text"
                    aria-describedby="inputGroup-sizing-sm"
                    style={{ borderLeft: "none" }}
                    inputMode="numeric"
                    pattern="[1-9]{1}[0-9]{9}"
                    value={Number(mobileNumber) || ""}
                    maxLength={10}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Dr. Mobile"
                  />
                </InputGroup>
                <span style={{ fontSize: "16px" }}>
                  By Registering, you are agreeing to our
                  <a href="/terms" style={{ color: "#0152A8" }} target="_blank">
                    {" "}
                    terms of use.
                  </a>
                </span>
                {!isMobileNumberValid && (
                  <span className="mobile-number-error">
                    Please Enter a Valid Mobile Number
                  </span>
                )}
                <Row style={{ marginTop: "60px " }}>
                  <UtilityButton
                    btnName="Send OTP "
                    width="45rem"
                    onClick={(e) => handleSendOtp(e)}
                    loading={getUserLoading}
                    disabled={getUserLoading}
                  />
                </Row>
              </Container>
              <Container className=" d-flex justify-content-center align-items-center flex-column  ">
                <a className="mt-20" href="/about-us">
                  About Us
                </a>
                <a
                  className="mt-20"
                  href="https://clearrxvids.s3.ap-south-1.amazonaws.com/demo3.mp4"
                >
                  How To ClearRx Video
                </a>
              </Container>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}
