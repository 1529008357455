import React from "react";
import "./selectbox.css";
import SearchBox from "assets/svg/SearchBox";
import useIsMobile from "hooks/useResize";
import PaginatedSelect from "./PaginatedReactSelect";
const SelectBox = React.memo(
  ({
    medicinesList,
    setIsOpen,
    setMedicineDetail,
    medicineName,
    setSearchPageOptions,
    setMedName,
    setIsOpenDrugModal,
    dropDownKey,
    setIsDiagnostics,
    setMedInstruction,
    inputValue,
    setInputValue,
  }) => {
    const isMobile = useIsMobile();

    const handleDropDownIcon = (e, setIsOpen) => {
      e.stopPropagation();
      if (medicinesList?.length > 0) {
        setIsOpen(true);
        setMedName(medicineName);
      }
    };

    return (
      <div
        className="App test-select"
        style={{ height: "46px", borderRadius: "10px 10px 0px 0px" }}
      >
        <div
          className="magnify-search-icon"
          style={{
            position: "absolute",
            right: isMobile ? "4px" : "4px",
            marginTop: "10px",
            zIndex: 1,
          }}
          onClick={(e) => handleDropDownIcon(e, setIsOpen)}
        >
          <SearchBox />
        </div>
        <PaginatedSelect
          setSearchPageOptions={setSearchPageOptions}
          setIsOpenDrugModal={setIsOpenDrugModal}
          setMedicineDetail={setMedicineDetail}
          dropDownKey={dropDownKey}
          setIsDiagnostics={setIsDiagnostics}
          setMedInstruction={setMedInstruction}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </div>
    );
  }
);

export default SelectBox;
