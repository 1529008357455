import React from "react";
import "./radios.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

export default function Radios({ label, i }) {
  return (
    <>
      <Row className="mb-8 p-0" style={{ width: "94%" }}>
        <Col className="d-flex align-items-center">
          <Form.Check
            type="radio"
            // label={label}
            id={i}
            name="formHorizontalRadios"
            className="formRadios"
          />
          <label htmlFor={i} className="grey">
            {label}
          </label>
        </Col>
      </Row>
    </>
  );
}
Radios.propTypes = {
  label: PropTypes.string.isRequired,
};
