import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import { toast } from "react-toastify";
class FavouriteService {
  async addFavourite(data) {
    let addFavourite = {
      brand_name: data?.brand_name,
      generic_name: data?.generic_name,
      medicine_id: data?.medicine_id,
      med_data_json: [
        {
          additional_instruction: data?.additional_instruction,
          intake_morning: data?.intake_morning,
          intake_afternoon: data?.intake_afternoon,
          intake_evening: data?.intake_evening,
          intake_night: data?.intake_night,
          before_food: data?.before_food,
          after_food: data?.after_food,
          dosage_duration: data?.dosage_duration,
        },
      ],
    };

    const response = await AXIOS.post("/fav-med", addFavourite, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response?.data?.statusCode == 200) {
      toast.success(response?.data?.message);
    }
    return response;
  }
  async getFavouriteList() {
    const response = await AXIOS.get("/fav-med", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    return response?.data;
  }

  async deleFavouriteItem(arr, dosage) {
    const response = await AXIOS.delete("/fav-med", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: {
        selectedMedicines: arr,
        dosage: dosage,
      },
    });
    toast.success(response?.data?.message);
  }
}

export default new FavouriteService();
