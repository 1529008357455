import WebFooter from "components/WebComponet/web-footer/WebFooter";
import WebNavbar from "components/WebComponet/web-navbar/WebNavbar";
import AboutUs from "components/about-us/about";
import UserDetailForm from "components/shared/userDetail";
import Terms from "components/terms/Terms";
import { Welcome } from "pages/mobile";
import AddPatient from "pages/web/Home/AddPatient";
import Assistant from "pages/web/Home/Assistant";
import ClinicalDetail from "pages/web/Home/ClinicalDetail";
import NewRx from "pages/web/Home/NewRx";
import Prescription from "pages/web/Home/Prescription";
import RxDetails from "pages/web/Home/RxDetails";
import RxHistory from "pages/web/Home/RxHistory";
import SearchRx from "pages/web/Home/SearchRx";
import WaitingList from "pages/web/Home/WaitingList";
import WebConfirmOtp from "pages/web/onboarding/WebConfirmOtp";
import WebEditprofile from "pages/web/onboarding/WebEditprofile";
import WebHome from "pages/web/onboarding/WebHome";
import WebOtp from "pages/web/onboarding/WebOtp";
import WebRegister from "pages/web/onboarding/WebRegister";
import WebThanks from "pages/web/onboarding/WebThanks";

export const webRoutes = {
  home: <WebHome />,
  register: <WebRegister />,
  confirmOtp: <WebConfirmOtp />,
  welcome: <Welcome />,
  thanks: <WebThanks />,
  otp: <WebOtp />,
  editProfile: <WebEditprofile />,
  newRx: <NewRx />,
  rxDetails: <RxDetails />,
  searchRx: <SearchRx />,
  navbar: <WebNavbar />,
  footer: <WebFooter />,
  rxHistory: <RxHistory />,
  prescription: <Prescription />,
  terms: <Terms />,
  aboutus: <AboutUs />,
  assistant: <Assistant />,
  waitingList: <WaitingList />,
  existingUserList: <AddPatient />,
  clinicalDetail:<ClinicalDetail />

};
