import BakcIcon from "assets/svg/BackIcon";
import useIsMobile from "hooks/useResize";
import React from "react";
import Container from "react-bootstrap/Container";

export default function TopHeadingIcon({ onClick, width }) {
  const ismobile = useIsMobile();
  return (
    <>
      <Container className=" d-flex justify-content-end cursor-pointer">
        <div
          className={ismobile ? "back-icon" : "back-icon-web"}
          onClick={onClick}
        >
          <BakcIcon width={width} />
        </div>
        {/* <div className="cross">
          <Cross />
        </div> */}
      </Container>
    </>
  );
}
