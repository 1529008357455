import WebFooter from "components/WebComponet/web-footer/WebFooter";
import AboutUs from "components/about-us/about";
import MobileBottomTab from "components/mobile-components/mobile-bottom-tab/MobileBottomTab";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import Terms from "components/terms/Terms";

import {
  Thankyou,
  EditProfile,
  Welcome,
  ConfirmOtp,
  Register,
} from "pages/mobile";
import Home from "pages/mobile/home/Home";
import NewRx from "pages/mobile/home/NewRx";
import Prescription from "pages/mobile/home/Prescription";
import RxDetails from "pages/mobile/home/RxDetails";
import RxHistory from "pages/mobile/home/RxHistory";
import SearchRx from "pages/mobile/home/SearchRx";
import Otp from "pages/mobile/onboarding/Otp";

export const mobileRoutes = {
  home: <Home />,
  register: <Register />,
  confirmOtp: <ConfirmOtp />,
  welcome: <Welcome />,
  thanks: <Thankyou />,
  otp: <Otp />,
  editProfile: <EditProfile />,
  newRx: <NewRx />,
  rxDetails: <RxDetails />,
  searchRx: <SearchRx />,
  navbar: <Mobileheader />,
  footer: <MobileBottomTab />,
  rxHistory: <RxHistory />,
  prescription: <Prescription />,
  terms: <Terms />,
  aboutus: <AboutUs />,
};
