import Thanks from "components/thankyou/Thanks";
import React from "react";

export default function Thankyou() {
  return (
    <>
      <Thanks pfont="fs-26" sfont="fs-16" />
    </>
  );
}
