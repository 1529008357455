import React from 'react'

const PageHeading = ({heading}) => {
  return (
    <div
          className="d-flex justify-content-between fw-700 fs-32"
          style={{
            width: "90%",
            margin: "62px auto",
          }}
        >
          {heading}
        </div>
  )
}

export default PageHeading