const RightArrowIcon = () => {
  return (
    <>
      <svg
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0264 0.881373C9.71057 0.565584 9.23689 0.565584 8.9211 0.881373C8.60531 1.19716 8.60531 1.67085 8.9211 1.98664L13.5 6.60506H1.50004C1.06583 6.60506 0.710571 6.96032 0.710571 7.39453C0.710571 7.82874 1.06583 8.18401 1.50004 8.18401H13.6579L8.9211 12.9603C8.60531 13.2761 8.60531 13.7498 8.9211 14.0656C9.07899 14.2235 9.27636 14.3024 9.47373 14.3024C9.6711 14.3024 9.86846 14.2235 10.0264 14.0656L16.0658 8.02611C16.3816 7.71032 16.3816 7.23664 16.0658 6.92085L10.0264 0.881373Z"
          fill="#105CAA"
        />
      </svg>
    </>
  );
};
export default RightArrowIcon;
