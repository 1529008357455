import UtilityButton from "components/buttons/UtilityButton";
import Radios from "components/form-radios/Radios";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user from "services/user";
import InputGroup from "react-bootstrap/InputGroup";

import Loader from "components/loader/Loader";
import { router } from "App";
import Container from "react-bootstrap/Container";
import { NewRxSchema } from "utils/schema/NewRxScema";
import * as formik from "formik";
import { toast } from "react-toastify";
import Plus from "assets/svg/Plus";
import {
  clearIdParam,
  getIdParam,
  newRxMobileNumber,
} from "redux-store/slices/user";
import { store } from "redux-store/store";

export default function NewRx() {
  // const [mobileNumber, setMobileNumber] = useState("");
  const [validMobileNumber, setValidMobileNumber] = useState(false);
  const [existingMemberId, setExistingMemberId] = useState("");
  const [addMembers, setAddMembers] = useState(false);
  const { SEARCH_RX: getSearchRxLoading, Rx_USER: getRxLoading } = useSelector(
    (state) => state.loading
  );
  useEffect(() => {
    store.dispatch(clearIdParam());
  }, []);
  const { newRxMobileNumber: mobileNumber } = useSelector(
    (state) => state.user
  );
  const data = useSelector((state) => state.user);
  const { Formik } = formik;
  const { rxUsers } = data || {};
  const dispatch = useDispatch();
  const handleMobileChange = (e) => {
    dispatch(newRxMobileNumber(e.target.value));
    if (e.target.value.length < 10) {
      setValidMobileNumber(true);
    } else {
      setValidMobileNumber(false);
    }
    if (e.target.value.length > 9) user.searchUser("search", e.target.value);
  };
  // useEffect(() => {
  //   dispatch(clearState("data"));
  // }, []);
  return (
    <>
      <Container className="p-16 mt-20">
        <Mobileheader
          pageName="New Rx"
          onClick={() =>
            !addMembers ? router.navigate("/home") : setAddMembers(false)
          }
        />

        {!addMembers ? (
          <>
            <div className="mt-30 mb-20">
              <label className="fs-16 fw-400 mb-8">Patient Phone Number</label>

              <InputGroup
                style={{ height: "46px", flexWrap: "nowrap" }}
                size="lg"
              >
                <InputGroup.Text
                  id="inputGroup-sizing-lg"
                  style={{ background: "#f5f6f6" }}
                >
                  +91
                </InputGroup.Text>

                <Form.Control
                  aria-label="Large"
                  name="phoneNumber"
                  inputMode="numeric"
                  maxLength={10}
                  className="no-wrap"
                  type="text"
                  autoComplete="off"
                  aria-describedby="inputGroup-sizing-sm"
                  value={Number(mobileNumber) || ""}
                  style={{ borderLeft: "none", background: "#f5f6f6" }}
                  onChange={handleMobileChange}
                />
              </InputGroup>
              {validMobileNumber && (
                <span className="red">
                  Please Enter 10 digits Mobile Number{" "}
                </span>
              )}
            </div>

            {mobileNumber.length === 10 &&
              !addMembers &&
              !rxUsers?.length &&
              !getSearchRxLoading && (
                <div className="mb-4">
                  <UtilityButton
                    btnName={"New Patient"}
                    height="56px"
                    fontSize="19px"
                    padding="11px 38px"
                    onclick={() => {
                      setExistingMemberId("");
                      setAddMembers(true);
                    }}
                    suffix={<Plus />}
                  />
                </div>
              )}
            {mobileNumber.length === 10 &&
              !addMembers &&
              !getSearchRxLoading &&
              rxUsers?.length > 0 && (
                <div className="mb-4">
                  <UtilityButton
                    btnName={"Add Members "}
                    height="56px"
                    fontSize="19px"
                    padding="11px 38px"
                    onclick={() => {
                      setExistingMemberId("");
                      setAddMembers(true);
                    }}
                    suffix={<Plus />}
                  />
                </div>
              )}

            {/* {mobileNumber.length === 10 && !addMembers  && (
              <div className="mb-4">
                <UtilityButton
                  btnName={rxUsers?.length > 0 ? "Add Members" : "New Patient"}
                  height="56px"
                  fontSize="19px"
                  padding="11px 38px"
                  onclick={() => {
                    setExistingMemberId("");
                    setAddMembers(true);
                  }}
                  suffix={<Plus />}
                />
              </div>
            )} */}

            {getSearchRxLoading && <Loader />}

            {rxUsers?.length > 0 && mobileNumber.length == 10 ? (
              <>
                <label
                  className="label lightgray fs-16 mb-14 fw-500 black"
                  htmlFor="floatingPasswordCustom"
                >
                  Existing Member(s)
                </label>
                <div
                  className="d-flex justify-content-between flex-wrap "
                  style={{ maxHeight: "200px", overflow: "scroll" }}
                >
                  {rxUsers.length > 0 &&
                    rxUsers?.map((users) => {
                      return (
                        <>
                          <div
                            onClick={() => setExistingMemberId(users?.id)}
                            style={{ flex: "40%" }}
                          >
                            <Radios label={users?.patient_name} i={users?.id} />
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            ) : (
              <p
                className="d-flex justify-content-center"
                style={{ color: "#53545A" }}
              >
                {mobileNumber.length == 10 &&
                  rxUsers?.length === 0 &&
                  !getSearchRxLoading &&
                  "Patient Not Found"}
              </p>
            )}
          </>
        ) : (
          <Formik
            validationSchema={NewRxSchema}
            onSubmit={(values) => {
              user.rxUser(values);
            }}
            initialValues={{
              phoneNumber: Number(mobileNumber),
              age: "",
              patientName: "",
              gender: "",
            }}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form formnovalidate="formnovalidate" onSubmit={handleSubmit}>
                <Container className="p-0" style={{ flexWrap: "nowrap" }}>
                  <div className=" mb-20 mt-4">
                    <label className="fs-16 fw-400 mb-8 p-0">
                      Patient Phone Number <span className="red"> *</span>
                    </label>
                    <InputGroup
                      style={{ height: "46px", flexWrap: "nowrap" }}
                      size="lg"
                    >
                      <InputGroup.Text
                        id="inputGroup-sizing-lg"
                        style={{ background: "#f5f6f6" }}
                      >
                        +91
                      </InputGroup.Text>

                      <Form.Control
                        aria-label="Large"
                        type="text"
                        name="phoneNumber"
                        disabled
                        autoComplete="off"
                        aria-describedby="inputGroup-sizing-sm"
                        inputMode="numeric"
                        maxLength={10}
                        style={{
                          borderLeft: "none",
                          background: "#f5f6f6",
                        }}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        // isValid={touched.phoneNumber && !errors.phoneNumber}
                      />
                    </InputGroup>
                    <span className="red">
                      {touched.phoneNumber && errors.phoneNumber}
                    </span>
                  </div>
                  <div className="mb-20">
                    <label className="fs-16 fw-400 mb-8">
                      Patient Name <span className="red"> *</span>
                    </label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className="input-group"
                      name="patientName"
                      style={{ background: "#f5f6f6", height: "46px" }}
                      onChange={handleChange}
                      // isValid={touched.patientName && !errors.patientName}
                    />
                    <span className="red">
                      {touched.patientName && errors.patientName}
                    </span>
                  </div>
                  <div className="mb-20">
                    <label className="fs-16 fw-400 mb-8">
                      Age <span className="red"> *</span>
                    </label>
                    <Form.Control
                      name="age"
                      style={{ background: "#f5f6f6", height: "46px" }}
                      onChange={handleChange}
                      className="input-group"
                      // isValid={touched.age && !errors.age}
                    />
                    <span className="red">{touched.age && errors.age}</span>
                  </div>
                  <label
                    className="label fs-18 mb-14"
                    htmlFor="floatingPasswordCustom"
                    style={{ color: "#000000" }}
                  >
                    Sex <span className="red"> *</span>
                  </label>
                  <div className="d-flex  justify-content-around">
                    <Row>
                      <Col
                        className="d-flex align-items-center "
                        style={{ color: "#b7b7b7" }}
                      >
                        <Form.Check
                          type="radio"
                          id="male"
                          value="male"
                          name="gender"
                          className="formRadios"
                          onChange={handleChange}
                          // isValid={touched.gender && !errors.gender}
                        />
                        <label id="male">Male</label>
                        <span className="red">
                          {touched.gender && errors.gender}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex align-items-center "
                        style={{ color: "#b7b7b7" }}
                      >
                        <Form.Check
                          type="radio"
                          id="female"
                          value="female"
                          name="gender"
                          className="formRadios"
                          onChange={handleChange}
                        />
                        <label htmlFor="female">Female</label>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <Row
                  style={{ position: "absolute", bottom: "84px", width: "98%" }}
                >
                  <UtilityButton
                    btnName="Next"
                    loading={getRxLoading}
                    height="56px"
                    fontSize="19px"
                    // onClick={() =>
                    //   existingMemberId &&
                    //   router.navigate(`/new-rx/${existingMemberId}`)
                    // }
                  />
                </Row>
              </Form>
            )}
          </Formik>
        )}
        {!addMembers && (
          <Row
            className="d-flex justify-content-center mt-30"
            style={{
              position: "fixed",
              bottom: "3rem",
              width: "98%",
              marginBottom: "18px",
            }}
          >
            <UtilityButton
              btnName="Next"
              height="56px"
              fontSize="19px"
              disabled={rxUsers?.length > 0 ? false : true}
              onClick={() => {
                if (existingMemberId) {
                  store.dispatch(getIdParam(existingMemberId));
                  router.navigate(`/new-rx/${existingMemberId}`);
                } else {
                  toast.error("Please Select a Member");
                }
              }}
            />
          </Row>
        )}
      </Container>
    </>
  );
}
