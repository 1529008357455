import * as yup from "yup";
export const WaitingLineSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must only contain digits")
    .min(10, "Phone number must be at least 10 digits"),

  age: yup.string().required("Age is required"),
  doctorId: yup.string().required("Doctor is required"),
  clinicalDetail: yup.string(),
  patientName: yup.string().required("Patient name is required"),

  gender: yup.string().required("Gender is required"),
});
