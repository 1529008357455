import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import { toast } from "react-toastify";
class FavouriteDiagnosticService {
  async getFavouriteDiagnostice(){
    const response= await AXIOS.get('/favourite-diagnostic',     {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
    return response?.data
  }
async addfavouriteDiganonstice(data){
      const response= await AXIOS.post('/favourite-diagnostic', data,    {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      return response?.data
}

async  deleteFavouriteDiagnosstice(selectedMedicine){
  const response = await AXIOS.delete("/favourite-diagnostic", {
    headers: {
      Authorization: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: {
      selectedMedicines: selectedMedicine,
    },
  });
  if(response?.data?.statusCode==202){

    toast.success(response.data?.message)
  }
  return response?.data
}



}

export default new FavouriteDiagnosticService();
