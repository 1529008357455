import { Formik } from "formik";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { NewAssistantSchema } from "utils/schema/AssistantSchema";
import InputGroup from "react-bootstrap/InputGroup";
import { Row } from "react-bootstrap";
import UtilityButton from "components/buttons/UtilityButton";
import assistant from "services/assistant";
import { toast } from "react-toastify";

const OnBoardAssistant = ({ AssistantData, closeModal }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      validationSchema={NewAssistantSchema}
      initialValues={{
        phoneNumber: AssistantData?.mobileNo || "",
        assistantName: AssistantData?.name || "",
      }}
      enableReinitialize
      onSubmit={async (value) => {
        setLoading(true);
        const getUserInfo = JSON.parse(localStorage?.getItem("userData")) || {};
        if (getUserInfo.mobile_number == value.phoneNumber) {
          toast.error("Failed to add Doctor as an Assistant");
          setLoading(false);
          closeModal();
          return;
        }
        if (Object.keys(AssistantData).length == 0) {
          const addAssistant = {
            name: value.assistantName,
            mobile_number: value.phoneNumber,
          };
          const data = await assistant.addAssistant(addAssistant);
          if (data?.data?.status) {
            toast.success(data?.data?.message);
            setLoading(false);
            closeModal();
          } else {
            toast.error(data?.data?.message);
            setLoading(false);
            closeModal();
          }
        } else {
          const updateAssistantData = {
            assistantId: AssistantData?.id,
            assistantDetails: {
              name: value.assistantName,
              mobile_number: value.phoneNumber,
            },
          };

          const data = await assistant.updateAssistant(updateAssistantData);
          console.log(data, "DATAACOMING");
          if (data?.data?.status) {
            toast.success(data?.data?.message);
            setLoading(false);
            closeModal();
          }
        }
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (

        <div>
          <Form noValidate onSubmit={handleSubmit}>
            <div
              className="d-flex justify-content-center gap-5"
              style={{ width: "100%" }}
            >
              <Row style={{ width: "50%" }}>
                <div className="mb-20">
                  <label
                    className="d-flex align-items-center fs-18 fw-400 p-0 mb-8"
                    style={{ color: "#53545A" }}
                  >
                    Assistant Name<span className="red"> *</span>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="assistantName"
                    className="input-group"
                    style={{ background: "#f5f6f6", height: "46px" }}
                    value={values.assistantName}
                    onChange={handleChange}
                  />
                  <span className="red">
                    {touched.assistantName && errors.assistantName}
                  </span>
                </div>
                <div className="mb-20">
                  <label
                    className="fs-18 fw-400 mb-8 p-0"
                    style={{ color: "#53545A" }}
                  >
                    Assistant Phone Number<span className="red"> *</span>
                  </label>
                  <InputGroup
                    style={{ height: "46px", flexWrap: "nowrap" }}
                    size="lg"
                  >
                    <InputGroup.Text
                      id="inputGroup-sizing-lg"
                      style={{ background: "#f5f6f6" }}
                    >
                      +91
                    </InputGroup.Text>

                    <Form.Control
                      aria-label="Large"
                      name="phoneNumber"
                      type="number"
                      inputMode="numeric"
                      aria-describedby="inputGroup-sizing-sm"
                      pattern="[1-9]{1}[0-9]{9}"
                      maxLength={10}
                      style={{
                        borderLeft: "none",
                        background: "#f5f6f6",
                      }}

                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleChange(e);
                        }
                      }}

                      value={values.phoneNumber}
                    />
                  </InputGroup>
                  <span className="red">
                    {(touched.phoneNumber && errors.phoneNumber) || (touched.phoneNumber || errors.phoneNumber)}

                  </span>
                </div>
              </Row>
            </div>
            <div className="d-flex justify-content-center mt-24">
              <UtilityButton
                btnName={
                  Object.keys(AssistantData).length !== 0 ? "Update" : "Create"
                }
                loading={loading}
                width="223px"
                disabled={loading}
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default OnBoardAssistant;
