import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";

import DoctorInfoCard from "components/doctor-profile-route/DoctorInfoCard";
import RxTabs from "components/rxtabs/RxTabs";
import NewRxIcon from "assets/svg/NewRxIcon";
import SearchRxIcon from "assets/svg/SearchRxIcon";
import { router } from "App";
import user from "services/user";
import { useSelector } from "react-redux";

export default function Home() {
  const userInfo = useSelector((state) => state.user);
  const { data: _data } = userInfo;
  const data = JSON.parse(localStorage.getItem("userData")) || {};
  useEffect(() => {
    if (data) {
      user.getUser(data.id);
    }
  }, [data]);

  return (
    <>
      <Container>
        <div className="mt-20">
          <Mobileheader isHelpLineIcon={true} />
          <div className="mt-30">
            <DoctorInfoCard user={_data} />
          </div>
        </div>
        {data && data.type == "Assistant" ? null : (
          <>
            <div className="mx-34">
              <RxTabs
                RxTitle=" New Rx"
                RxIcon={<NewRxIcon />}
                onClick={() => router.navigate("/new-rx")}
              />
            </div>
            <div className="mx-34">
              <RxTabs
                RxTitle=" Search Rx"
                RxIcon={<SearchRxIcon />}
                onClick={() => router.navigate("/search-rx")}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}
