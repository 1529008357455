import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import UploadCertificate from "components/upload/UploadCertificate";
import InputField from "components/input-field/InputField";
import Headingtab from "components/WebComponet/headingtab/Headingtab";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import * as formik from "formik";
import { RegisterSchema } from "utils/schema/RegisterSchema";
import { useLocation } from "react-router-dom";
import Auth from "services/auth";
import UtilityButton from "components/buttons/UtilityButton";
import { router } from "App";
import { useSelector } from "react-redux";

export default function WebRegister() {
  const { Formik } = formik;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const phoneNumber = urlParams.get("phone");
  const { GET_USER: getUserLoading } = useSelector((state) => state.loading);

  return (
    <>
      <Container
        className="container-fluid d-flex m-0 "
        style={{
          maxWidth: "100%",
          height: "100vh",
          padding: "0px",
          marginBottom: "32px",
        }}
      >
        <div
          className="Register1"
          style={{
            background: "#FAFAFA",
            backgroundImage: `url(/images/signup-bg.png) `,
            backgroundRepeat: "no-repeat",
            height: "100vh",
            backgroundColor: "#fafafa",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        <div className="Register2">
          <div className="mt-24">
            <TopHeadingIcon onClick={() => router.navigate("/login")} />
          </div>
          <div className="Registorform">
            <Headingtab heading="Register" />
            <div className="mt-20 mb-20"></div>
            <Formik
              validationSchema={RegisterSchema}
              onSubmit={(values) => {
                const { uploadCertificate } = values;
                delete values.uploadCertificate;
                const data = uploadCertificate.map((item) => ({
                  base64: item.base64,
                  fileName: item.name.replace(/\s+/g, "").split(".")[0],
                }));
                Auth.register(values, data, phoneNumber);
              }}
              initialValues={{
                fullName: "",
                LicenseNumber: "",
                deegree: "",
                location: "",
                uploadCertificate: "",
                address: "",
              }}
              enableReinitialize
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <InputField
                    label="Full Name"
                    sublabel="as per license certificate, without Dr."
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    isValid={touched.fullName && !errors.fullName}
                  />
                  <span className="red">
                    {touched.fullName && errors.fullName}
                  </span>

                  <Form.Control.Feedback type="invalid">
                    {touched.fullName && errors.fullName}
                  </Form.Control.Feedback>

                  <InputField
                    label="Registered License Number"
                    name="LicenseNumber"
                    value={values.LicenseNumber}
                    onChange={handleChange}
                    isValid={touched.LicenseNumber && !errors.LicenseNumber}
                  />
                  <span className="red">
                    {touched.LicenseNumber && errors.LicenseNumber}
                  </span>
                  <InputField
                    label="Degree & Speciality"
                    name="deegree"
                    value={values.deegree}
                    onChange={handleChange}
                    isValid={touched.deegree && !errors.deegree}
                  />
                  <span className="red">
                    {touched.deegree && errors.deegree}
                  </span>

                  <InputField
                    label="Clinic/Hospital Name"
                    sublabel="optional"
                    labelstar={false}
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    isValid={touched.location && !errors.location}
                  />
                  <InputField
                    label="Address and Contact Info"
                    sublabel="optional"
                    labelstar={false}
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                  />

                  <UploadCertificate
                    setFieldValue={setFieldValue}
                    label="Upload License Certificate"
                    onChange={handleChange}
                    labelstar={true}
                  />
                  {touched.uploadCertificate && errors.uploadCertificate && (
                    <span className="red">{"Please select a file"}</span>
                  )}
                  <Row
                    className="mt-30"
                    style={{
                      position: "relative",
                      bottom: "12px",
                      padding: "0px 18px",
                    }}
                  >
                    <UtilityButton btnName="Submit" loading={getUserLoading} />
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
}
