import StartingPage from "components/starting-page/StartingPage";

import React from "react";

export default function Welcome() {
  return (
    <>
      <StartingPage />
    </>
  );
}
