import Description from "components/description/Description";
import LogoIcon from "assets/svg/LogoIcon";
import React, { useRef, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Auth from "services/auth";
import { validateMobileNumber } from "utils/helper";
import { useSelector } from "react-redux";
import UtilityButton from "components/buttons/UtilityButton";
import HelpLineIcon from "assets/svg/HelpLineIcon";
import WhatsApp from "assets/svg/WhatsApp";
import Video from "./Video";

export default function Otp() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isVideo, setIsVideo] = useState(false);
  const [isplayIcon, setPlayIcon] = useState(true);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const { GET_USER: getUserLoading } = useSelector((state) => state.loading);

  const handleChange = (e) => {
    const value = e.target.value;

    setMobileNumber(value);
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (mobileNumber.length == 10) {
      setIsMobileNumberValid(true);
      Auth.sendOtp(mobileNumber);
    } else {
      setIsMobileNumberValid(validateMobileNumber(mobileNumber));
      return;
    }
  };

  const ref = useRef();

  // const playVideo = () => {
  //   ref.current.play();
  //   setPlayIcon(false);
  // };
  if (isVideo) {
    return (
      <div className="position-relative">
        {isVideo && <Video setIsVideo={setIsVideo} />}
      </div>
    );
  }

  return (
    <>
      <Container className=" d-flex justify-content-center align-items-center flex-column  ">
        <span
          className="ml-8"
          onClick={() => window.open("http://wa.me/+916366222791", "_blank")}
        >
          <div className="header-otp mt-12 mb-12">
            <LogoIcon width={68} heigth={68} />
          </div>
        </span>
        <Container className="postition-relative">
          <Description description="Welcome to ClearRx " />
          <span style={{ fontSize: "18px" }}>
            A digital prescription pad for doctors.
          </span>
          <Description description="" subdescription="" />
          <span style={{ fontSize: "18px" }}>
            A faster way to generate NMC compliant digital Rx.
          </span>
          <Description description="" subdescription="" />
          <span style={{ fontSize: "18px" }}>
            Try it for free for 6 months
          </span>
        </Container>

        <form onSubmit={handleSendOtp}>
          <Container className="mt-12 mb-14 fs-36">
            <label className="fs-18" htmlFor="floatingPasswordCustom">
              Login/Register
            </label>

            <InputGroup style={{ height: "64px" }} size="lg">
              <InputGroup.Text className="bg-white" id="inputGroup-sizing-lg">
                +91
              </InputGroup.Text>
              <Form.Control
                aria-label="Large"
                type="text"
                aria-describedby="inputGroup-sizing-sm"
                style={{ borderLeft: "none" }}
                inputMode="numeric"
                pattern="[1-9]{1}[0-9]{9}"
                value={Number(mobileNumber) || ""}
                maxLength={10}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Dr. Mobile"
              />
            </InputGroup>
            {!isMobileNumberValid && (
              <span className="mobile-number-error">
                Please Enter a Valid Mobile Number
              </span>
            )}
          </Container>

          <Container>
            <span>By Registering, you are agreeing to our</span>
            <a href="/terms"> terms of use. </a>
          </Container>

          <Container className="mt-30">
            <Container className="d-grid gap-2 mb-4" id="fixedbutton-">
              <UtilityButton
                btnName="Send OTP"
                onClick={handleSendOtp}
                loading={getUserLoading}
                disabled={getUserLoading}
              />
            </Container>
          </Container>

          <Container className=" d-flex justify-content-center align-items-center flex-column  ">
            <a className="mt-10" href="/about-us">
              About Us
            </a>
            <a
              className="mt-20 mb-30"
              href="https://clearrxvids.s3.ap-south-1.amazonaws.com/demo.mp4"
            >
              How To ClearRx Video
            </a>
          </Container>
          <span
            onClick={() => window.open("http://wa.me/+916366222791", "_blank")}
          >
            <Container className=" d-flex justify-content-center align-items-center flex-column mt-15">
              <div className="mobheader-otp-whatsApp mb-36 ">
                <HelpLineIcon /> <span className="ml-10">Help</span>
                <span className="ml-10"> </span>
                <WhatsApp />
              </div>
            </Container>
          </span>
        </form>
      </Container>
    </>
  );
}
