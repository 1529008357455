import React, { useEffect, useState } from "react";
import InputField from "components/input-field/InputField";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PencilIcon from "assets/svg/PencilIcon";
import LogoutButton from "components/buttons/LogoutButton";
import * as formik from "formik";
import user from "services/user";
import { useSelector } from "react-redux";
import UtilityButton from "components/buttons/UtilityButton";
import ImageIcon from "assets/svg/ImageIcon";
import { EditProfileSchema } from "utils/schema/EditProfileSchema";
import ConfirmationModal from "components/modals/ConfirmationModal";
import MobileOtp from "components/loader/mobile-otp/MobileOtp";
import auth from "services/auth";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Loader from "components/loader/Loader";
import { toast } from "react-toastify";

import "./editProfile.css";
import SectionContainer from "components/shared/sectionContainer";
import PageHeading from "components/shared/pageHeading";
import DataBox from "components/shared/dataBox";
import { router } from "App";

export default function EditProfile() {
  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const [disAble, setDisable] = useState(true);
  // const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isOtpBtn, setIsOtpBtn] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(true);
  const [otp, setOtp] = useState("");
  const { Formik } = formik;
  const { confirm_OTP: mobileOtpLoading } = useSelector(
    (state) => state.loading
  );

  const UserDetail = useSelector((state) => state.user);
  const { Edit_Profile: getUserLoading } = useSelector(
    (state) => state.loading
  );
  const { data } = UserDetail;
  const {
    full_name,
    licence_number,
    degree_speciality,
    mobile_number,
    userDocuments,
    practice_location,
    contact_info,
    timings,
  } = data || {};
  const userData = localStorage.getItem("userData") || {};
  const { id } = JSON.parse(userData);
  useEffect(() => {
    const userData = localStorage.getItem("userData") || {};
    const { id } = JSON.parse(userData);
    if (id) user.getUser(id);
  }, []);
  useEffect(()=>{
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    if(userData && userData.type=="Assistant"){
      router.navigate("/home")
    }

  },[])
  const handleMobileChange = async (e, latestMobileNumber) => {
    if (String(latestMobileNumber).length == 10) {
      await auth.sendOtp(mobile_number, latestMobileNumber);
      e.stopPropagation();
      // setIsInputDisabled(false);
      setShowOtpInput(true);
    } else {
      toast.error("Please Enter a Valid Mobile Number");
    }
  };

  const handleOtp = async (e, newMobileNumber) => {
    setOtp(e);
    if (e.length === 4) {
      let isSuccess = await user.editMobileNumber(
        e,
        mobile_number,
        newMobileNumber
      );
      setShowOtpInput(false);
      setIsOtpBtn(isSuccess);
      setOtp("");
    }
  };

  return (
    <>
    <SectionContainer>
     <PageHeading heading="Profile"/>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "90%", margin: "34px auto" }}
        >
          <div>
            <p
              className="m-0 fw-700 fs-30 blue text-center "
              style={{ color: "#105CAA" }}
            >
              {full_name}
            </p>
            <p
              className="m-0 fw-400 fs-16 blue text-center "
              style={{ color: "#105CAA" }}
            >
              {degree_speciality}
            </p>
          </div>

          <div
            className="mt-30"
            style={{
              position: " relative",
            }}
          >
            <div onClick={() => setConfirmationModal(true)}>
              <LogoutButton height="68px" width="145px" />
            </div>
          </div>
        </div>
        {isConfirmationModal && (
          <ConfirmationModal
            setIsModal={setConfirmationModal}
            onClick={() => localStorage.Clear()}
          />
        )}
      <DataBox>
          <Formik
            validationSchema={EditProfileSchema}
            onSubmit={(values) => {
              setConfirmBtn(false);
              user.editProfile(values, id);
              user.getUser(id);
            }}
            initialValues={{
              fullName: full_name || "",
              LicenseNumber: licence_number || "",
              deegree: degree_speciality || "",
              location: practice_location || "",
              mobileNumber: mobile_number || "",
              address: contact_info || "",
              timing: timings,
            }}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div
                  className="d-flex justify-content-end "
                  style={{
                    width: "90%",
                    margin: "auto",
                    borderRadious: "10px",
                  }}
                >
                  <div className="d-flex justify-content-end position-absolute">
                    <p
                      className="mt-15 cursor-pointer"
                      style={{ color: "#105CAA" }}
                      onClick={() => setDisable(!disAble)}
                    >
                      <PencilIcon color="#105CAA" />
                      <span className="ml-16">Edit Profile</span>
                    </p>
                  </div>

                  <Row style={{ width: "45%", marginTop: "66px" }}>
                    <InputField
                      label="Full Name"
                      sublabel="as per license certificate"
                      name="fullName"
                      value={"Dr. " + values.fullName}
                      onChange={handleChange}
                      disable={true}
                      isValid={touched.fullName && !errors.fullName}
                    />
                    <span className="red">
                      {touched.fullName && errors.fullName}
                    </span>
                    <InputField
                      label="Degree & Speciality "
                      name="deegree"
                      value={values.deegree}
                      onChange={handleChange}
                      disable={disAble}
                      isValid={touched.deegree && !errors.deegree}
                    />
                    <span className="red">
                      {touched.deegree && errors.deegree}
                    </span>
                    <Form.Label className="label mt-3 mb-1">
                      <span className="fs-16">Mobile Number</span>
                    </Form.Label>
                    <InputGroup
                      className="mt-1 "
                      style={{
                        width: "91%",
                        height: "45px",
                        padding: "0px",
                        position: "relative",
                        left: "12px",
                      }}
                    >
                      <InputGroup.Text
                        id="inputGroup-sizing-lg"
                        className="fs-14"
                      >
                        +91
                      </InputGroup.Text>
                      <Form.Control
                        className="global-input fs-14 custom-select"
                        aria-label="Large"
                        type="text"
                        name="mobileNumber"
                        aria-describedby="inputGroup-sizing-sm"
                        inputMode="numeric"
                        pattern="[1-9]{1}[0-9]{9}"
                        value={Number(values.mobileNumber) || ""}
                        placeholder="Mobile Number"
                        maxLength={10}
                        disabled={disAble}
                        onChange={handleChange}
                        // isValid={touched.mobileNumber && errors.mobileNumber}
                      />
                      {values.mobileNumber !== mobile_number && !isOtpBtn ? (
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          disabled={mobileOtpLoading}
                          onClick={(e) =>
                            handleMobileChange(e, values.mobileNumber)
                          }
                        >
                          Send OTP
                        </Button>
                      ) : null}
                    </InputGroup>
                    <span className="red">
                      {touched.mobileNumber && errors.mobileNumber}
                    </span>
                  </Row>

                  <Row style={{ width: "45%", marginTop: "66px " }}>
                    <InputField
                      label="Registered License Number"
                      name="LicenseNumber"
                      value={values.LicenseNumber}
                      onChange={handleChange}
                      disable={true}
                      isValid={touched.LicenseNumber && !errors.LicenseNumber}
                    />
                    <span className="red">
                      {touched.LicenseNumber && errors.LicenseNumber}
                    </span>

                    <InputField
                      label="Clinic/Hospital Name"
                      sublabel="optional"
                      labelstar={false}
                      name="location"
                      value={values.location}
                      onChange={handleChange}
                      disable={disAble}
                      isValid={touched.location && !errors.location}
                    />

                    <span className="red">
                      {touched.location && errors.location}
                    </span>

                    <InputField
                      label="Address and Contact Info"
                      labelstar={false}
                      name="address"
                      sublabel="optional"
                      value={values.address}
                      onChange={handleChange}
                      disable={disAble}
                      // isValid={touched.location && !errors.location}
                    />

                    <span className="red">
                      {touched.location && errors.location}
                    </span>
                  </Row>
                </div>
                <div>
                  <div className="d-flex justify-content-center">
                    <Row style={{ width: "66%" }}>
                      {/* <Form.Label className="label mt-3 mb-1">
                        <span className="fs-16">Mobile Number</span>
                      </Form.Label> */}

                      {/* <InputGroup
                        className="mt-1 "
                        style={{ width: "58%", height: "45px", padding: "0px" }}
                      >
                        <InputGroup.Text
                          id="inputGroup-sizing-lg"
                          className="fs-14"
                        >
                          +91
                        </InputGroup.Text>
                        <Form.Control
                          className="global-input fs-14 custom-select"
                          aria-label="Large"
                          type="text"
                          name="mobileNumber"
                          aria-describedby="inputGroup-sizing-sm"
                          inputMode="numeric"
                          pattern="[1-9]{1}[0-9]{9}"
                          value={Number(values.mobileNumber) || ""}
                          placeholder="Mobile Number"
                          maxLength={10}
                          disabled={disAble}
                          onChange={handleChange}
                          // isValid={touched.mobileNumber && errors.mobileNumber}
                        />
                        {values.mobileNumber !== mobile_number && !isOtpBtn ? (
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            disabled={mobileOtpLoading}
                            onClick={(e) =>
                              handleMobileChange(e, values.mobileNumber)
                            }
                          >
                            Send OTP
                          </Button>
                        ) : null}
                      </InputGroup>
                      <span className="red">
                        {touched.mobileNumber && errors.mobileNumber}
                      </span> */}

                      {showOtpInput &&
                        values.mobileNumber !== mobile_number && (
                          <>
                            <Form.Label className="label mt-3 mb-1">
                              <span className="fs-16">Confirm OTP</span>
                            </Form.Label>
                            <div className="mt-2">
                              <MobileOtp
                                otp={otp}
                                onChange={(e) =>
                                  handleOtp(e, values.mobileNumber)
                                }
                              />
                            </div>
                            {mobileOtpLoading && (
                              <div className="mt-4">
                                <Loader />
                              </div>
                            )}
                          </>
                        )}
                      {/* <div className="timing"> */}
                      <div className="timing">
                        {" "}
                        <InputField
                          label="Timing"
                          labelstar={false}
                          name="timing"
                          value={values.timing}
                          onChange={handleChange}
                          disable={disAble}
                        />
                      </div>

                      <div></div>
                      {/* </div> */}
                    </Row>
                  </div>
                </div>
                <div
                  style={{
                    maxWidth: "40%",
                    minWidth: "40%",
                    margin: "32px auto",
                  }}
                >
                  <Row>
                    <Form.Label className="label  mb-3">
                      <span className="fs-16">
                        Uploaded License Certificate
                      </span>
                    </Form.Label>
                    {userDocuments?.map((item, index) => (
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <ImageIcon />
                          </div>
                          <div>
                            <span className="fs-12 fw-400">
                              &nbsp; {item?.document_name}
                            </span>
                            <span
                              className="fs-12 blue fw-400 cursor-pointer ml-12"
                              onClick={() => window.open(item.url)}
                            >
                              . preview
                            </span>
                          </div>
                        </div>
                        <span className="fs-10">
                          {(item.document_size / 1024).toFixed(2)}mb
                        </span>
                      </div>
                    ))}
                  </Row>
                </div>
                <div className="d-flex justify-content-center mt-80 ">
                  <UtilityButton
                    btnName="Submit"
                    width="223px"
                    loading={getUserLoading}
                    onclick={() => setDisable(true)}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </DataBox>

        <div
          className="d-flex"
          style={{ background: "#d3edfc", paddingBottom: "50px" }}
        >
          <div style={{ width: "90%", margin: "auto" }}>
            {/* <WebFooter /> */}
          </div>
        </div>
        </SectionContainer>
    </>
  );
}
