const PencilIcon = ({ color = "white" }) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8252 1.71712C11.0522 1.4901 11.3217 1.31001 11.6183 1.18713C11.9148 1.06426 12.2327 1.001 12.5538 1.00098C12.8748 1.00095 13.1927 1.06416 13.4893 1.187C13.7859 1.30983 14.0554 1.48988 14.2824 1.71687C14.5095 1.94386 14.6896 2.21334 14.8124 2.50993C14.9353 2.80652 14.9986 3.12441 14.9986 3.44544C14.9986 3.76648 14.9354 4.08438 14.8126 4.38098C14.6897 4.67759 14.5097 4.9471 14.2827 5.17412L13.8317 5.62462L10.3747 2.16762L10.8252 1.71712ZM9.66769 2.87512L2.17869 10.3651C1.92654 10.6171 1.75256 10.9366 1.67769 11.2851L1.01119 14.3951C0.993614 14.4768 0.996784 14.5616 1.02041 14.6417C1.04404 14.7218 1.08737 14.7948 1.14645 14.8539C1.20552 14.9129 1.27846 14.9563 1.3586 14.9799C1.43873 15.0035 1.52351 15.0067 1.60519 14.9891L4.71519 14.3226C5.06372 14.2477 5.3832 14.0738 5.63519 13.8216L13.1252 6.33162L9.66769 2.87512Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export default PencilIcon;
