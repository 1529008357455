import React from "react";
import Modal from "react-bootstrap/Modal";
import "./index.css";

import UtilityButton from "components/buttons/UtilityButton";
import { router } from "App";
import { useSelector } from "react-redux";

export default function ConfirmationModal({ setIsModal, text, handleUser }) {
  const handleClick = () => {
    localStorage.clear();
    router.navigate("/login");
  };
  const { DELETE_USER: deleteUser } = useSelector((state) => state.loading);
  console.log(deleteUser, "deleteUser");
  return (
    <Modal
      className="modal-dialog-centered"
      show={true}
      backdrop="static"
      keyboard={false}
      onHide={() => setIsModal(false)}
      centered
    >
      <Modal.Header className="border-0"></Modal.Header>
      <Modal.Body className=" d-flex justify-content-center text-center border-0 fw-500 fs-14 modal-dialog-centered ">
        {text ? text : "Are you sure you want to Logout?"}
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-evenly border-0"
        style={{ width: "70%", margin: "auto" }}
      >
        <UtilityButton
          btnName="No"
          width="108px"
          bgColor="white"
          height="40px"
          color="#105CAA"
          border="2px solid #105CAA"
          onClick={() => setIsModal(false)}
        />
        <UtilityButton
          btnName="Yes"
          onClick={handleUser || handleClick}
          width="108px"
          height="40px"
          loading={deleteUser}
          // onClick={() => localStorage.clear()}
        />
      </Modal.Footer>
    </Modal>
  );
}
