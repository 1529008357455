const PdfIcon = () => {
  return (
    <svg
      width="37"
      height="35"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58384 0.571289C3.76375 0.571289 3.09277 1.24227 3.09277 2.06236V22.9373C3.09277 23.7574 3.76375 24.4284 4.58384 24.4284H19.4945C20.3146 24.4284 20.9856 23.7574 20.9856 22.9373V6.53556L15.0213 0.571289H4.58384Z"
        fill="#E2E5E7"
      />
      <path
        d="M16.5126 6.53556H20.9858L15.0215 0.571289V5.04449C15.0215 5.86458 15.6925 6.53556 16.5126 6.53556Z"
        fill="#B0B7BD"
      />
      <path
        d="M20.9859 11.0074L16.5127 6.53418H20.9859V11.0074Z"
        fill="#CAD1D8"
      />
      <path
        d="M18.0041 19.9548C18.0041 20.3648 17.6686 20.7003 17.2586 20.7003H0.856862C0.446818 20.7003 0.111328 20.3648 0.111328 19.9548V12.4994C0.111328 12.0894 0.446818 11.7539 0.856862 11.7539H17.2586C17.6686 11.7539 18.0041 12.0894 18.0041 12.4994V19.9548Z"
        fill="#F15642"
      />
      <path
        d="M3.36035 14.6986C3.36035 14.5018 3.51542 14.2871 3.76518 14.2871H5.14218C5.91753 14.2871 6.61535 14.806 6.61535 15.8005C6.61535 16.7429 5.91753 17.2678 5.14218 17.2678H4.14689V18.055C4.14689 18.3175 3.97989 18.4658 3.76518 18.4658C3.56836 18.4658 3.36035 18.3175 3.36035 18.055V14.6986ZM4.14689 15.0379V16.523H5.14218C5.54178 16.523 5.85789 16.1703 5.85789 15.8005C5.85789 15.3838 5.54178 15.0379 5.14218 15.0379H4.14689Z"
        fill="white"
      />
      <path
        d="M7.7836 18.4659C7.58678 18.4659 7.37207 18.3585 7.37207 18.0968V14.7106C7.37207 14.4966 7.58678 14.3408 7.7836 14.3408H9.14868C11.8729 14.3408 11.8132 18.4659 9.20236 18.4659H7.7836ZM8.15935 15.0685V17.739H9.14868C10.7583 17.739 10.8299 15.0685 9.14868 15.0685H8.15935Z"
        fill="white"
      />
      <path
        d="M12.7789 15.114V16.0616H14.299C14.5137 16.0616 14.7284 16.2763 14.7284 16.4843C14.7284 16.6811 14.5137 16.8421 14.299 16.8421H12.7789V18.0939C12.7789 18.3026 12.6305 18.4629 12.4217 18.4629C12.1593 18.4629 11.999 18.3026 11.999 18.0939V14.7077C11.999 14.4937 12.1601 14.3379 12.4217 14.3379H14.5145C14.7769 14.3379 14.932 14.4937 14.932 14.7077C14.932 14.8985 14.7769 15.1132 14.5145 15.1132H12.7789V15.114Z"
        fill="white"
      />
      <path
        d="M17.2579 20.7006H3.09277V21.4461H17.2579C17.668 21.4461 18.0034 21.1107 18.0034 20.7006V19.9551C18.0034 20.3651 17.668 20.7006 17.2579 20.7006Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};
export default PdfIcon;
