import React, { useState } from 'react'
import ModalBox from "components/shared/modalBox";
import { Button } from 'react-bootstrap';
import favourite from "services/favourite";
const FavouriteModal = ({setSelectedIndexes}) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [favMedData, setFavMedData]=useState()
    const [showLoading, setShowLoading]=useState(true)
    const handleModalData=async ()=>{
        setModalShow(true)
       const data= await favourite.getFavouriteList()
       setShowLoading(false)
       setFavMedData(data)
    }
  return (
  <>
    <Button
    className="fs-11 gray  mb-2 fw-500 border-0"
    variant="light"
    style={{ color: "#19104e", backgroundColor: "#d8f1fb" }}
    onClick={() => handleModalData()     }
  >
    Favourites
  </Button>
  <ModalBox favMedData={favMedData} setSelectedIndexes={setSelectedIndexes} showLoading={showLoading} show={modalShow} onHide={() => setModalShow(false)} />
  </>
  )
}

export default FavouriteModal