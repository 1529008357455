import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OnBoardAssistant from "./onboardAssistantForm";

export default function OnboardAssistantModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Assistant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <OnBoardAssistant closeModal={()=>props.onHide()} AssistantData={props.AssistantData}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
