import React from "react";

export default function Plus() {
  return (

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1100_1856)">
          <path
            d="M22.6668 12.0007C22.6668 14.1103 22.0412 16.1726 20.8692 17.9267C19.6971 19.6809 18.0312 21.048 16.0821 21.8554C14.133 22.6627 11.9883 22.8739 9.9192 22.4624C7.85007 22.0508 5.94946 21.0349 4.4577 19.5431C2.96594 18.0514 1.95003 16.1507 1.53846 14.0816C1.12688 12.0125 1.33812 9.86777 2.14545 7.91869C2.95279 5.96962 4.31996 4.30371 6.07408 3.13164C7.82821 1.95957 9.8905 1.33398 12.0002 1.33398C14.8291 1.33398 17.5423 2.45779 19.5426 4.45818C21.543 6.45857 22.6668 9.17167 22.6668 12.0007ZM17.0602 11.0007H13.0002V6.94065C13.0002 6.67543 12.8948 6.42108 12.7073 6.23354C12.5197 6.04601 12.2654 5.94065 12.0002 5.94065C11.735 5.94065 11.4806 6.04601 11.2931 6.23354C11.1055 6.42108 11.0002 6.67543 11.0002 6.94065V11.0007H6.94017C6.67495 11.0007 6.4206 11.106 6.23306 11.2935C6.04552 11.4811 5.94017 11.7354 5.94017 12.0007C5.94017 12.2659 6.04552 12.5202 6.23306 12.7078C6.4206 12.8953 6.67495 13.0007 6.94017 13.0007H11.0002V17.0606C11.0002 17.3259 11.1055 17.5802 11.2931 17.7678C11.4806 17.9553 11.735 18.0606 12.0002 18.0606C12.2654 18.0606 12.5197 17.9553 12.7073 17.7678C12.8948 17.5802 13.0002 17.3259 13.0002 17.0606V13.0007H17.0602C17.3254 13.0007 17.5797 12.8953 17.7673 12.7078C17.9548 12.5202 18.0602 12.2659 18.0602 12.0007C18.0602 11.7354 17.9548 11.4811 17.7673 11.2935C17.5797 11.106 17.3254 11.0007 17.0602 11.0007Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1100_1856">
            <rect width="24" height="24" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>

  );
}
