const SearchRxIcon = () => {
  return (
    <>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="55.5608" height="55.5608" rx="10" fill="#105CAA" />
        <path
          d="M24.68 14.0508C29.4905 14.0508 33.4055 17.9658 33.4055 22.7808C33.4055 27.5958 29.4905 31.5063 24.68 31.5063C19.8695 31.5063 15.95 27.5913 15.95 22.7808C15.95 17.9703 19.865 14.0508 24.68 14.0508ZM24.68 29.7468C28.523 29.7468 31.646 26.6193 31.646 22.7808C31.646 18.9423 28.523 15.8103 24.68 15.8103C20.837 15.8103 17.7095 18.9378 17.7095 22.7808C17.7095 26.6238 20.837 29.7468 24.68 29.7468Z"
          fill="white"
        />
        <path
          d="M24.68 16.709C28.0235 16.709 30.746 19.4315 30.746 22.7795C30.746 26.1275 28.0235 28.8455 24.68 28.8455C21.3365 28.8455 18.6095 26.123 18.6095 22.7795C18.6095 19.436 21.332 16.709 24.68 16.709ZM20.351 23.2295C20.5985 23.2295 20.801 23.027 20.801 22.7795C20.801 20.642 22.5425 18.9005 24.68 18.9005C24.9275 18.9005 25.13 18.698 25.13 18.4505C25.13 18.203 24.9275 18.0005 24.68 18.0005C22.043 18.0005 19.901 20.1425 19.901 22.7795C19.901 23.027 20.1035 23.2295 20.351 23.2295Z"
          fill="white"
        />
        <path
          d="M34.9085 30.8652L38.7785 34.7442C39.116 35.0817 39.314 35.5092 39.3365 35.9547C39.359 36.3777 39.2195 36.7692 38.9405 37.0482C38.6705 37.3137 38.3105 37.4487 37.9325 37.4487C37.478 37.4487 37.001 37.2552 36.632 36.8862L32.87 33.1197C33.4775 33.0882 33.959 32.8857 34.3145 32.5212C34.76 32.0577 34.895 31.4187 34.9085 30.8652Z"
          fill="white"
        />
        <path
          d="M32.492 28.4492L33.9005 29.8577C33.896 29.8757 33.9005 29.8892 33.905 29.9072C33.9545 30.0827 34.013 30.4112 34.013 30.7667C34.013 31.1807 33.932 31.6262 33.6665 31.9007C33.338 32.2382 32.735 32.3102 31.871 32.1167L30.3455 30.5912C30.638 30.3887 31.0385 30.0692 31.502 29.6057C31.97 29.1377 32.285 28.7417 32.492 28.4492Z"
          fill="white"
        />
        <path
          d="M42.851 20.08H38.5625V10.9C38.5625 10.405 38.162 10 37.6625 10H11.9C11.405 10 11 10.4005 11 10.9V42.5575C11 44.4565 12.5435 46 14.4425 46H40.25C40.34 46 40.457 45.9955 40.4975 45.991C42.32 45.8695 43.7465 44.2765 43.7465 42.3595V20.9755C43.7465 20.4805 43.346 20.08 42.851 20.08ZM41.951 42.3595C41.951 43.3135 41.249 44.137 40.3265 44.2H40.2365C39.674 44.2 39.2915 43.93 39.0665 43.7005C38.747 43.381 38.567 42.9535 38.567 42.5215V40.204H36.7715V42.5215C36.7715 43.111 36.9425 43.687 37.235 44.2045H14.447C13.538 44.2045 12.8 43.4665 12.8 42.5575V11.7955H36.7625V31.114H38.558V21.8755H39.359V33.7105H41.1545V21.8755H41.9465V42.3595H41.951Z"
          fill="white"
        />
        <path
          d="M31.6505 33.8535H27.236V35.649H31.6505V33.8535Z"
          fill="white"
        />
        <path d="M34.094 37.2373H27.236V39.0328H34.094V37.2373Z" fill="white" />
        <path d="M34.094 40.6123H27.236V42.4078H34.094V40.6123Z" fill="white" />
        <path
          d="M22.6865 33.8623H15.8285C15.3335 33.8623 14.9285 34.2628 14.9285 34.7623V41.0173C14.9285 41.5123 15.329 41.9173 15.8285 41.9173H22.6865C23.1815 41.9173 23.5865 41.5168 23.5865 41.0173V34.7623C23.5865 34.2673 23.186 33.8623 22.6865 33.8623ZM21.791 40.1218H16.7285V35.6623H21.791V40.1218Z"
          fill="white"
        />
        <path
          d="M41.159 39.9258H39.3635V41.5143H41.159V39.9258Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export default SearchRxIcon;
