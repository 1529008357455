import * as yup from "yup";
export const drugModalSchema = (
  isBrandNameShort: boolean = false,
  isDosageDuration = true,
  isDiagnostics
) =>
  yup
    .object()
    .shape({
      ...(!isDosageDuration &&
        !isDiagnostics && {
          dosage_duration: yup
            .string()
            .required("Dosage and duration are required."),
        }),
      ...(isBrandNameShort && {
        brand_name_short: yup
          .string()
          .required(
            `${
              !isDosageDuration
                ? "Medicine Name is required."
                : " Test Name is required"
            }`
          ),
      }),
      intake_morning: yup.boolean(),
      intake_afternoon: yup.boolean(),
      intake_evening: yup.boolean(),
      intake_dinner: yup.boolean(),
    })
    .test(
      "at-least-one-checkbox",
      "Select at least one intake time.",
      (values) => {
        return (
          values.intake_morning ||
          values.intake_afternoon ||
          values.intake_evening ||
          values.intake_dinner
        );
      }
    );
