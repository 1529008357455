import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

export default function PdfReview({ open, handleClosePdfModal }) {
  const { pdfUrl } = useSelector((state) => state.user);
  return (
    <Modal
      show={open}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      onHide={handleClosePdfModal}
    >
      <Modal.Header
        className="d-flex justify-content-center border-0"
        closeButton
      ></Modal.Header>
      <Modal.Body>
        <iframe
          src={`${pdfUrl}`}
          width="100%"
          height="500px"
          title="Pdf Viewer"
        />
        {/* <object data={`${pdfUrl}#toolbar=1&navpanes=0&scrollbar=1&page=0&view=FitW`} width="100%"  title="Pdf Viewer"></object> */}

        {/* <embed src={pdfUrl} width="100%" height="500px" type="application/pdf" style={{overflow: "scroll"}}></embed> */}
        {/* <Iframe
                    url={pdfUrl}
                    width="100%"
                    height="500px"
                    scrolling="auto"
                    frameBorder="0"
                    
                /> */}
      </Modal.Body>
    </Modal>
  );
}
