const ProfileIcon = ({ active }) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="#b2b2b2"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1683 8.33366C12.0092 8.33366 13.5016 6.84127 13.5016 5.00033C13.5016 3.15938 12.0092 1.66699 10.1683 1.66699C8.32734 1.66699 6.83496 3.15938 6.83496 5.00033C6.83496 6.84127 8.32734 8.33366 10.1683 8.33366Z"
          {...(active ? { fill: "#145dab" } : "#b2b2b2")}
        />
        <path
          d="M16.8353 14.584C16.8353 16.6548 16.8353 18.334 10.1686 18.334C3.50195 18.334 3.50195 16.6548 3.50195 14.584C3.50195 12.5132 6.48695 10.834 10.1686 10.834C13.8503 10.834 16.8353 12.5132 16.8353 14.584Z"
          {...(active ? { fill: "#145dab" } : "#b2b2b2")}
        />
      </svg>
    </>
  );
};
export default ProfileIcon;
