import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import RxContentDetail from "components/rx-content-detail/RxContentDetail";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import user from "services/user";
import Loader from "components/loader/Loader";
import { router } from "App";

export default function RxDetails() {
  const [pageName, setPageName] = useState("New Rx");
  const { id } = useParams();
  const data = useSelector((state) => state.user);
  const [isReview, setisReview] = useState(false);
  const loading = useSelector((state) => state.loading);
  const { SEARCH_RX: getSearchRxLoading } = loading;
  const { getSingleRxUsers } = data || {};
  useEffect(() => {
    if (id) {
      user.searchUser("id", id);
    }
  }, []);
  const handleClick = () => {
    if (isReview) {
      setisReview(false);
    } else {
      router.navigate("/new-rx");
    }
  };
  return (
    <Container className="mt-20">
      <Mobileheader
        pageName={isReview ? "Review" : pageName}
        onClick={handleClick}
      />

      <div style={{ width: "100%", margin: " auto" }}>
        {getSearchRxLoading ? (
          <>
            <div className="mt-30">
              <Loader />
            </div>
          </>
        ) : (
          <RxContentDetail
            data={getSingleRxUsers}
            isReview={isReview}
            setIsReview={setisReview}
            setPageName={setPageName}
          />
        )}
      </div>
    </Container>
  );
}
