import DataBox from "components/shared/dataBox";
import PageHeading from "components/shared/pageHeading";
import SectionContainer from "components/shared/sectionContainer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showWaitingList } from "redux-store/slices/assistant";
import { store } from "redux-store/store";
import waitingList from "services/waitingList";
const WaitingList = () => {
  const [loading, setLoading] = useState(true);
  const AssistantDetail = useSelector((state) => state.assistant);
  const { waitingLists } = AssistantDetail || {};
  const fetchWaitingListUser = async () => {
    const data = await waitingList.getWaitingList();
    console.log(data?.data, "DATA COMING");
    if (data?.data?.status) {
      data?.data?.listofWaitingPatients.forEach((item) => {
        store.dispatch(
          showWaitingList({
            name: item?.patient_name,
            mobile_number: item?.patient_mobile_number,
            age: item?.age,
            id: item?.id,
            sex: item?.sex,
          })
        );
      });
      setLoading(false);
    }
  };
  console.log(waitingLists, loading, "daattt");
  useEffect(() => {
    fetchWaitingListUser();
  }, []);
  return (
    <SectionContainer>
      <PageHeading heading="Waiting List" />
      <DataBox>
        <div style={{ paddingTop: "20px" }}>
          {loading ? (
            <center>Loading ....</center>
          ) : !loading && waitingLists.length <= 0 ? (
            <center>No assistant added </center>
          ) : (
            waitingLists.map((item, index) => {
              return (
                <DataBox key={index} paddingBottom={"20px"}>
                  <div
                    className="d-flex align-items-center justify-content-between "
                    style={{ padding: "0px 20px" }}
                  >
                    <div style={{ padding: "20px 30px 0px" }}>
                      <h5 style={{ color: "#0c52a8" }}>
                        {" "}
                        <span style={{ color: "#1b263b" }}>Name:</span>{" "}
                        {item?.name}
                      </h5>
                      <p style={{ margin: 0, color: "#0c52a8" }}>
                        {" "}
                        <span style={{ color: "#1b263b" }}>
                          Mobile No:
                        </span>{" "}
                        {item?.mobile_number}
                      </p>
                    </div>
                  </div>
                </DataBox>
              );
            })
          )}
        </div>
      </DataBox>
    </SectionContainer>
  );
};

export default WaitingList;
