import React, { useState } from "react";
import { Spinner, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import assistant from "services/assistant";
import { store } from "redux-store/store";
import { useSelector } from "react-redux";
import { removeAssistant } from "redux-store/slices/assistant";
import { toast } from "react-toastify";
const DeleteModal = (props) => {
  const [loading, setLoading] = useState(false);
  console.log(props?.assistantData, "DATA");
  const AssistantDetail = useSelector((state) => state.assistant);
  const DeleteUser = async () => {
    setLoading(true);
    const data = await assistant.deleteAssistant(props.assistantData.assistant_id);
    if (data?.data?.status) {
      setLoading(false);
      store.dispatch(removeAssistant({ id: props.assistantData.id }));
      toast.success(data?.data?.message);
      props.onHide();
    } else {
      toast.error(data?.data);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Assistant : {props.assistantData.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are You sure you want to delete this assistant</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Stack direction="horizontal" gap={1}>
          <Button onClick={DeleteUser} style={{backgroundColor:'red', border:'none', borderRadius:"30px"}}>
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Delete"
            )}
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
