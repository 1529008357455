const SearchBox = ({ onClick }) => {
  return (
    <span onClick={onClick}>
      <svg
      className="search-box-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.11744 1.67409C5.67393 1.67409 4.28955 2.24752 3.26884 3.26823C2.24812 4.28895 1.67469 5.67333 1.67469 7.11684C1.67469 8.56034 2.24812 9.94473 3.26884 10.9654C4.28955 11.9862 5.67393 12.5596 7.11744 12.5596C8.56095 12.5596 9.94533 11.9862 10.966 10.9654C11.9868 9.94473 12.5602 8.56034 12.5602 7.11684C12.5602 5.67333 11.9868 4.28895 10.966 3.26823C9.94533 2.24752 8.56095 1.67409 7.11744 1.67409ZM1.60508e-08 7.11684C9.67065e-05 5.98189 0.271605 4.86342 0.791873 3.85475C1.31214 2.84608 2.06608 1.97645 2.99079 1.31841C3.91551 0.660378 4.98417 0.233022 6.10764 0.0719994C7.2311 -0.0890236 8.37679 0.0209557 9.4491 0.392762C10.5214 0.764567 11.4893 1.38742 12.2719 2.20935C13.0546 3.03128 13.6293 4.02846 13.9482 5.11768C14.2671 6.20691 14.3208 7.3566 14.105 8.47084C13.8892 9.58508 13.4101 10.6316 12.7076 11.523L15.7656 14.5809C15.9181 14.7389 16.0025 14.9504 16.0006 15.1699C15.9987 15.3895 15.9106 15.5995 15.7554 15.7547C15.6001 15.91 15.3901 15.9981 15.1705 16C14.951 16.0019 14.7395 15.9175 14.5815 15.7649L11.5236 12.707C10.474 13.5343 9.21282 14.0494 7.88422 14.1933C6.55562 14.3373 5.21333 14.1043 4.01097 13.521C2.8086 12.9378 1.79473 12.0278 1.08539 10.8952C0.376058 9.76262 -8.97161e-05 8.45321 1.60508e-08 7.11684ZM6.2801 4.18613C6.2801 3.96405 6.36832 3.75107 6.52535 3.59403C6.68238 3.437 6.89536 3.34878 7.11744 3.34878C8.11679 3.34878 9.07521 3.74577 9.78186 4.45242C10.4885 5.15907 10.8855 6.11749 10.8855 7.11684C10.8855 7.33891 10.7973 7.5519 10.6402 7.70893C10.4832 7.86596 10.2702 7.95418 10.0482 7.95418C9.82607 7.95418 9.61309 7.86596 9.45606 7.70893C9.29903 7.5519 9.21081 7.33891 9.21081 7.11684C9.21081 6.56164 8.99026 6.02919 8.59767 5.6366C8.20509 5.24402 7.67264 5.02347 7.11744 5.02347C6.89536 5.02347 6.68238 4.93525 6.52535 4.77822C6.36832 4.62119 6.2801 4.4082 6.2801 4.18613Z"
          fill="#53545A"
        />
      </svg>
    </span>
  );
};
export default SearchBox;
