import DataBox from "components/shared/dataBox";
import ExistingMember from "components/shared/existingMember";
import PageHeading from "components/shared/pageHeading";
import SectionContainer from "components/shared/sectionContainer";
import UserDetailForm from "components/shared/userDetail";
import React from "react";

const AddPatient = () => {

  return (
    <SectionContainer>
      {/* <PageHeading heading="Patient Details" /> */}

        <div style={{ padding: "20px" }}>
          <ExistingMember isAssitantPage={true} componentHeading="Add New Patient" />
         
        </div>
    </SectionContainer>
  );
};

export default AddPatient;
