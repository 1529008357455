import * as yup from "yup";
export const EditProfileSchema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),

  LicenseNumber: yup.string().required("Doctor license number is required"),
  deegree: yup.string().required("Degree is required"),
  location: yup.string(),

  location: yup.string(),
  mobileNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Invalid mobile number")
    .required("Mobile number is required"),

  timing: yup.string(),

  //   location: yup.string().required("Location is required"),
});
