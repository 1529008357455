const NewRxIcon = ({ onClick }) => {
  return (
    <>
      <svg
        onClick={onClick}
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="55.5608" height="55.5608" rx="10" fill="#105CAA" />
        <path
          d="M33.3541 10H16.5993C15.1638 10 14 11.1638 14 12.5993V43.4007C14 44.8362 15.1638 46 16.5993 46H38.1798C39.6154 46 40.7792 44.8362 40.7792 43.4007V17.5716L33.3541 10ZM20.749 24.3873H34.0242C34.6381 24.3873 35.1358 24.885 35.1358 25.4989C35.1358 26.1128 34.6381 26.6105 34.0242 26.6105H20.749C20.1351 26.6105 19.6374 26.1128 19.6374 25.4989C19.6374 24.885 20.1351 24.3873 20.749 24.3873ZM26.0298 36.4198H20.749C20.1351 36.4198 19.6374 35.9221 19.6374 35.3082C19.6374 34.6943 20.1351 34.1966 20.749 34.1966H26.0298C26.6437 34.1966 27.1414 34.6943 27.1414 35.3082C27.1414 35.9221 26.6437 36.4198 26.0298 36.4198ZM34.0301 31.5152H20.7549C20.141 31.5152 19.6433 31.0174 19.6433 30.4036C19.6433 29.7897 20.141 29.292 20.7549 29.292H34.0301C34.644 29.292 35.1417 29.7897 35.1417 30.4036C35.1417 31.0174 34.6445 31.5152 34.0301 31.5152ZM33.3541 17.5716V12.289L38.5346 17.5716H33.3541Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export default NewRxIcon;
