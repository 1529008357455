import React from "react";
import "./terms.css";
import BakcIcon from "assets/svg/BackIcon";
import { router } from "App";
import useIsMobile from "hooks/useResize";

export default function Terms() {
  const isMobile = useIsMobile();
  return (
    <>
      <p
        className={` d-flex justify-content-between term-heading fw-700  ${
          isMobile && "fs-32"
        }`}
      >
        Terms of Use - Clear Rx
      </p>
      <div className="term-para fw-500">
        <p>
          These Terms of Use (“TOU”) apply to the access and use of all of our
          web and mobile-based applications (which are collectively referred to
          as “Platforms”) and the use of our products and services provided
          through the Platforms (“Use”). Clear Rx team owns, manages, and
          operates The Platforms. These TOU govern the use of the Platforms and
          Services by you, the registered medical practitioners, having valid
          credentials and having registered with the Medical Council of India or
          any successor organization as may be notified in the future
          (“Doctors”), your staff (“Staff”), and the patients and end users who
          are using the Platforms (“Patients”). Patients, Staff, and Doctors are
          jointly referred to as “Users” or “you”. By accessing or using the
          Platforms, registering for Services offered on the Platforms, or by
          accepting, uploading, submitting, or downloading any information or
          content from or to the Platforms, you shall have agreed to these TOU.
        </p>
        <p className=" agree fw-700 fs-18">
          IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THESE TOU, we request you DO
          NOT USE THE PLATFORMS.
        </p>
        <ul>
          <p className="fw-700">1.Acceptance of Terms</p>
          <p>
            Your use of the Platforms is subject to these TOU, which may be
            updated, amended, modified, or revised by ClearRx occasionally
            without any notice to you. You need to refer to these TOU from time
            to time to make sure that you are aware of any additions, revisions,
            amendments, or modifications that we may have made to these TOU.
            Your use of the Platforms and engagement with ClearRx constitutes
            your acceptance of these TOU. Your use of the Services herein is
            under a limited, non-transferable, non-licensable, non-assignable
            license to use the Services granted by ClearRx only for the purposes
            mentioned herein.
          </p>
          <p className="fw-700">2.Use of Services</p>
          <p>
            The Platforms and Services assist Doctors in writing, storing,
            obtaining, reviewing, and transmitting health information and
            e-prescriptions of their patients. The Platforms and Services are
            designed to allow the Doctors to seamlessly access their Patient
            Data via the Platforms and use the Data to provide healthcare
            services to the Patients. We endeavor to provide a functional and
            convenient Service through our Platforms. Still, we do not guarantee
            that your web browser or mobile device will be compatible with the
            Platforms or the Services, that the Platforms and Services will be
            uninterrupted, or that any content will be error-free. ClearRx is
            not responsible for any interruption in Services or availability of
            Platforms due to, but not limited to, changes or updates in
            individual clinic’s practice, network failure, or any other
            technical incident. ClearRx reserves the right, at its sole
            discretion, to modify or replace all or any part of the TOU
            (including, without limitation, pricing and payment terms) or
            change, suspend, or discontinue all or any aspect of the Services or
            Platforms at any time by posting a notice on the Platforms or by
            informing you through any other modes of communication. It is your
            responsibility to check the TOU periodically for changes. Your
            continued use of the Platforms or the Services after posting any
            changes to the TOU constitutes acceptance of those changes.
          </p>
          <p>
            The Platforms may collect and store demographic, health records, and
            any other type of information that you may provide. This information
            is collected, stored, and processed as per our privacy policy
            published on the Platforms.
          </p>
          <p>
            The Doctors represent and warrant that they have taken the
            appropriate consent from Patients before writing e-prescriptions,
            storing, or sharing with any third parties and collecting any of the
            Patients’ information, including but not limited to personally
            identifiable information, health records, demographic information,
            financial information, etc.
          </p>
          <p>
            By accessing the Platform services, the Patients consent and agree
            that the Platform may store their personal information, including
            but not limited to contact information, demographic data, health
            records, medical history, etc. The Platform may use this information
            for purposes as per our privacy policy published on the Platforms.
          </p>
          <p className="fw-700">3.Registration</p>
          <p>
            You will need to create an account as part of the registration
            process. You are responsible for ensuring that your information is
            accurate, not misleading, and secure. You shall be responsible for
            keeping your credentials in safe custody. ClearRx will not be liable
            for any losses or claims arising from misuse of Platforms or
            Services accessed with your credentials. You cannot create an
            account or username and password using another person's name and
            information or words that are the trademarks or the property of
            another party (including ours), or vulgar, obscene, or in any other
            way inappropriate. We reserve the right, with or without notice, to
            suspend or terminate any account in breach. Doctors represent and
            warrant that they are licensed medical practitioners registered with
            the Medical Council of India (MCI), and there are no legal or
            regulatory impediments that prevent you from practicing medicine in
            India.
          </p>
          <li>4. Data Confidentiality</li>
          <p>
            Your use of the Platforms is subject to these TOU, which may be
            updated, amended, modified, or revised by ClearRx occasionally
            without any notice to you. You need to refer to these TOU from time
            to time to make sure that you are aware of any additions, revisions,
            amendments, or modifications that we may have made to these TOU.
            Your use of the Platforms and engagement with ClearRx constitutes
            your acceptance of these TOU. Your use of the Services herein is
            under a limited, non-transferable, non-licensable, non-assignable
            license to use the Services granted by ClearRx only for the purposes
            mentioned herein.
          </p>
        </ul>
        <p className="fw-700">Data Confidentiality</p>
        <p>a)Responsibility of Doctors and Staff</p>
        <p>
          Through your use of the Platforms, you shall have access to sensitive
          and valuable Data of the Users, including medical records, medical
          information, personal information, or any other information as may be
          required. Additionally, Doctors and their Staff agree to treat as
          confidential all Data that they have been provided access to, and that
          they shall make their best efforts to maintain the confidentiality of
          such Data. Doctors and their Staff should be aware of the Patient
          confidentiality requirements and observe all caution and the
          applicable duty of care in providing services under these TOU. The
          Platforms present the Patient Data to the Doctors in an As-Is manner
          and the Platforms do not make any warranties, representations, etc. on
          the accuracy and correctness of the Data.
        </p>
        <p>
          b)Restrictions on Use of Platforms. Doctors and Staff agrees that they
          shall not:
        </p>
        <ol type="i">
          <li>
            permit anyone to: (i) copy or republish the Platforms, except as may
            be provided herein; (ii) make the Platforms available to any person
            other than its authorized personnel, (iii) remove, modify or obscure
            any copyright, trademark or other proprietary notices contained in
            the Platforms, (iv) reverse engineer, decompile, disassemble, or
            otherwise attempt to derive the source code of Platforms or (vii)
            access the Platforms to build a similar or competitive product.
            Doctors and Staff shall only access (or attempt to access) the
            Platforms and the materials or services by the ClearRx interface.
          </li>
          <li>
            use any deep-link, robot, spider or other automatic device, program,
            algorithm or methodology, or any similar or equivalent manual
            process, to access, acquire, copy or monitor any portion of the
            Platforms or content, or in any way reproduce or circumvent the
            navigational structure or presentation of the Platforms, materials
            or any content, to obtain or attempt to obtain any materials,
            documents or information through any means not specifically made
            available through the Platforms.
          </li>
          <li>
            attempt to gain unauthorized access to any portion or feature of the
            Platforms, any other systems or networks connected to the Platforms,
            to any ClearRX server, or to any of the services offered on or
            through the Platforms, by hacking, password mining or any other
            illegitimate means.
          </li>
          <li>
            probe, scan or test the vulnerability of the Platforms or any
            network connected to the Platforms, nor breach the security or
            authentication measures on the Platforms or any network connected to
            the Platforms. Doctors and Staff may not reverse look-up, trace or
            seek to trace any information on any other user, of or visitor to,
            the Platforms, or any other customer of ClearRx, including any
            ClearRx account not owned by Doctors, to its source, or exploit the
            Platforms or Services or information made available or offered by or
            through the Platforms, in any way whether or not the purpose is to
            reveal any information, including but not limited to personal
            identification information, other than Doctors’ own information, as
            provided for by the Platforms;
          </li>
          <li>
            disrupt or interfere with the security of, or otherwise cause harm
            to, the Platforms, systems resources, accounts, passwords, servers
            or networks connected to or accessible through the Platforms or any
            affiliated or linked sites.
          </li>
        </ol>
        <p>
          In case of breach of this provision, all parties including but not
          limited to Users shall be liable to pay ClearRx a pre-agreed
          liquidated damages amount to INR 1,00,00,000 (One crore only).
        </p>
        <p>b) Non-Disclosure by ClearRx</p>
        <p>
          The ClearRx shall not share health-related Data like diagnosis,
          symptoms, or e-prescriptions with other doctors, clinics, or hospitals
          except basic demographics of patients like name, age, patient phone
          number. Patient demographics are shared with third parties only to
          provide stated services of the platform. In other words, the ClearRx
          shall not engage in any activity that can be construed as “poaching of
          patients”. ClearRx will store the Data on a secure cloud
          infrastructure and/or third-party servers chosen by ClearRx or built
          by ClearRx. ClearRx is committed to protecting the identity of the
          Users. The following security procedures have been put in place by us
          to protect Data:
        </p>
        <p>
          a)Access to the Services is only after the user keys in phone number
          and OTP. After successful authentication, post which the access to the
          Platforms and Services is granted. Users are fully and solely
          responsible for any or all use of the Platforms or the Services while
          accessing these using login credentials.
        </p>
        <p>
          b)To prevent impersonation, your OTP is never shared with anyone other
          than the registered mobile number.
        </p>
        <p>
          c)The communication between your device and our servers will be
          through a secure HTTPS connection.
        </p>
        <p>
          d)A secure session is established with the help of a session token for
          providing access to the Patient Data after the Doctors or the Staff
          have successfully authenticated
        </p>
        <p>
          e) Within our server we use an Advanced Encryption Standard (AES) and
          multi-level security and authentication checks to protect the
          confidential Data. Personally identifiable patient information is
          always kept strictly confidential and is never disclosed with any
          third party except as provided under our privacy policy. We reserve
          the right to access, read, preserve, and disclose any information that
          we reasonably believe is necessary to comply with law or court order;
          enforce or apply our conditions of use and other agreements; or
          protect the rights, property, or safety of ClearRx, our employees, our
          Users, or others. This includes exchanging information with other
          companies and organisations for fraud protection and risk reduction.
          The ClearRx may also use the data in de-identified and aggregated form
          for the following purposes without violating the relevant data privacy
          and data security laws of India.
        </p>
        <ol type="i">
          <li>
            for the purpose of research and publications, statistical analysis,
            generating Real World Evidence (RWE) algorithmically,
          </li>
          <li>
            for communication purpose so as to provide the Doctor a better way
            of communicating with his/her patients by means of SMS/Emails,
          </li>
          <li> for debugging customer support related issues,</li>
          <li>
            for improving the algorithms of ClearRx’s EMR to make it faster,
            efficient and reliable
          </li>
          <li>for enhancing the Clinical Decision Support</li>
          <li>
            for communicating about new Services and offerings by the ClearRx or
            its partners with the Users
          </li>
        </ol>
        <p className="fw-700">5. Communications</p>
        <p>
          {" "}
          You agree to receive communications through , telephone, mobile phone
          and/or SMS, Whatsapp from ClearRx or its third-party vendors or
          business partners or third-party service providers regarding the
          services or services updates, transactional and/or promotional emails
          and/or any announcements. In this context and regard, you agree and
          provide your consent to receive all communications at the mobile
          number provided to ClearRx, even if this mobile number is registered
          under DND/NCPR list under Telecom Regulatory Authority of India (TRAI)
          laws, rules and regulations. And for that purpose, you further
          authorise ClearRx to share/disclose the information to any third-party
          service provider or any affiliates, group companies, their authorised
          agents or third-party service providers. You also agree that in
          accordance with the applicable TRAI laws, rules and regulations
          specifically The Telecom Commercial Communications Customer
          Regulations, 2014:
        </p>
        <ol type="i">
          <li>
            Each time you do visit/transact or login in your account on the
            Platforms, it shall be regarded as a verifiable request from you
            pertaining to receipt of our Services and activities;
          </li>
          <li>
            Each time you visit/login/transact on the Platforms, it will be
            deemed to be as a fresh request from you for continuing to receive
            communication from ClearRx
          </li>
          <li>
            In case you do not wish to receive any communication from us or to
            provide your feedback about the services, you can reach us at
            “help/Contact us” on our home page.
          </li>
          <p>
            ClearRx will retain and use your information as necessary to comply
            with our legal obligations, resolve disputes and enforce our
            agreements entered into for providing Services and ancillary
            services.
          </p>
          <p className="fw-700">6. Termination</p>
          <p>
            ClearRx may terminate your access to all or any part of the
            Platforms or Services at any time if you fail to comply with these
            TOU. This may result in the forfeiture and destruction of all
            information associated with your membership and will immediately
            terminate your ability to provide services through the Platforms.
            All provisions of the TOU, which by their nature should survive
            termination, shall survive termination, including, without
            limitation, warranty disclaimers, indemnity and limitations of
            liability. In the event of discontinuation of services, ClearRx, at
            its discretion, will make reasonable efforts to help you take a
            backup of your Data in a suitable electronic format.
          </p>
          <p className="fw-700">7. DISCLAIMER</p>
          <p>
            THE PLATFORMS (INCLUDING, WITHOUT LIMITATION, ANY CONTENT) ARE
            PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY
            KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
            WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
            FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE
            OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
            DISCLAIMED. ClearRx team, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS AND
            PARTNERS DO NOT WARRANT THAT: (A) THE SERVICE WILL AVAILABLE AT ANY
            PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE
            SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE
            RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS. YOUR USE
            OF THE SERVICE IS SOLELY AT YOUR OWN RISK
          </p>
          <p className="fw-700">8. Indemnification</p>
          <p>
            You shall defend, indemnify, and hold harmless ClearRx, its
            affiliates/subsidiarie, partners' employees, contractors, directors,
            suppliers and representatives from all liabilities, losses, claims,
            and expenses, including reasonable attorneys’ fees, that arise from
            or relate to (i) your use or misuse of, or access to, the Platforms
            or Services, or (ii) your violation of the TOU or any applicable
            law, contract, policy, regulation or other obligation. ClearRx
            reserves the right to assume the exclusive defense and control of
            any matter otherwise subject to indemnification by you. In this
            event, you will assist and cooperate with ClearRx.
          </p>
          <p className="fw-700"> 9. Limitation of Liability</p>
          <p>
            {" "}
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL ClearRx
            team (NOR ITS DIRECTORS, EMPLOYEES, AGENTS, SPONSORS, PARTNERS,
            SUPPLIERS, CONTENT PROVIDERS, LICENSORS OR RESELLERS, BE LIABLE
            UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER
            LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY
            LOST PROFITS, DATA LOSS, LOSS OF GOODWILL OR OPPORTUNITY, COST OF
            PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT,
            INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND
            WHATSOEVER, OR SUBSTITUTE GOODS OR SERVICES, (II) FOR ANY DIRECT
            DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE FEES PAID BY YOU FOR THE
            SERVICE IN THE THREE (3) MONTHS PRECEDING THE APPLICABLE CLAIM OR
            (IV) FOR ANY MATTER BEYOND ITS OR THEIR REASONABLE CONTROL, EVEN IF
            ClearRx HAS BEEN ADVISED OF THE POSSIBILITY OF ANY OF THE
            AFOREMENTIONED DAMAGES.
          </p>
          <p className="fw-700">10. Governing Law</p>
          <p>
            {" "}
            This Agreement shall be governed by and construed in accordance with
            the laws of India without regard to the conflict of laws provisions
            thereof. All claims, differences and disputes arising under or in
            connection with or in relation hereto the Website, the Terms or any
            transactions entered into on or through the Website shall be subject
            to the exclusive jurisdiction of the courts at Bangalore, India and
            you hereby accede to and accept the jurisdiction of such courts.
          </p>
          <p className="fw-700">11. Miscellaneous</p>
          <p>
            The TOU are the entire agreement between you and ClearRx with
            respect to the use of Platforms or the Services, and supersede all
            prior or contemporaneous communications and proposals (whether oral,
            written or electronic) between you and ClearRx with respect to the
            use of Platforms or the Services provided. If any provision of the
            TOU is found to be unenforceable or invalid, that provision will be
            limited or eliminated to the minimum extent necessary so that the
            TOU will otherwise remain in full force and effect and enforceable.
            The failure of either party to exercise in any respect any right
            provided for herein shall not be deemed a waiver of any further
            rights hereunder. The TOU are personal to you, and are not
            assignable or transferable by you except with ClearRx’s prior
            written consent. ClearRx may assign, transfer or delegate any of its
            rights and obligations hereunder without consent. No agency,
            partnership, joint venture, or employment relationship is created as
            a result of the TOU and neither party has any authority of any kind
            to bind the other in any respect. All notices under the TOU will be
            in writing and will be deemed to have been duly given when received
            if personally delivered or sent by certified or registered mail,
            return receipt requested; when receipt is electronically confirmed,
            if transmitted by facsimile or e-mail; or two days after it is sent,
            if sent for next day delivery by recognized overnight delivery
            service.
          </p>
        </ol>
      </div>
    </>
  );
}
