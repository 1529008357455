import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import auth from "services/auth";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./verify.css";
import UtilityButton from "components/buttons/UtilityButton";
import { useSelector } from "react-redux";
import user from "services/user";
import Loader from "components/loader/Loader";

export default function Verify() {
  const [approveUser, setApproveUser] = useState(false);
  const data = useSelector((state) => state.loading);
  const userInfo = useSelector((state) => state.user);
  const { data: _data } = userInfo;

  const { Approve_User, PAGE_LOADING } = data || {};
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
const [isUserVerified, setIsUserVerified]=useState(false)
  useEffect(() => {
    const getUserDetail=async()=>{
      const data=await user.getUserWithoutToken(id);
      console.log(data,"HDATAAHEREE",data?.data)
      if(data?.data?.statusCode==200){
        const responseBody=JSON.parse(data?.data?.body)
        console.log(responseBody,"rEsponseBDODDYDD",responseBody.body.user)

        if(responseBody?.body?.user?.status==1){
          setIsUserVerified(true)
        }
      }
    }
    getUserDetail()
  }, []);

  if (PAGE_LOADING) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Loader />
      </Container>
    );
  }

  if (isUserVerified) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Row>
          <Col className="text-center d-flex justify-content center flex-column">
            <div className="d-flex justify-content-center flex-column">
              <div>
                <img width="144px" src="/images/checked.png" alt="" />
              </div>
              <p className="thanks-paragraph mb-12 fs-26">
                This account has already been Approved
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Row>
          <Col className="text-center d-flex justify-content center flex-column">
            {approveUser ? (
              <>
                <div className="d-flex justify-content-center flex-column">
                  <div>
                    <img width="144px" src="/images/checked.png" alt="" />
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center mt-30">
                <UtilityButton
                  btnName="Approve Account"
                  width="223px"
                  loading={Approve_User}
                  onClick={() =>
                    auth.approveUser({ id, token }, setApproveUser)
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
