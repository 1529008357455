import InputField from "components/input-field/InputField";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import UploadCertificate from "components/upload/UploadCertificate";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import { RegisterSchema } from "utils/schema/RegisterSchema";
import Auth from "services/auth";
import { useLocation } from "react-router-dom";
import UtilityButton from "components/buttons/UtilityButton";
import { router } from "App";
import { useSelector } from "react-redux";

export default function Register() {
  const { Formik } = formik;
  const location = useLocation();
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
  }, [webcamRef]);

  const urlParams = new URLSearchParams(location.search);
  const phoneNumber = urlParams.get("phone");
  const { GET_USER: getUserLoading } = useSelector((state) => state.loading);

  const handleClick = () => {
    router.navigate("/otp");
  };
  return (
    <>
      <Container className="p-16">
        <Mobileheader pageName="Register" onClick={handleClick} />

        <Formik
          validationSchema={RegisterSchema}
          onSubmit={(values) => {
            const { uploadCertificate } = values;
            delete values.uploadCertificate;
            const data = uploadCertificate.map((item) => ({
              base64: item.base64,
              fileName: item.name.replace(/\s+/g, "").split(".")[0],
            }));
            Auth.register(values, data, phoneNumber);
          }}
          initialValues={{
            fullName: "",
            LicenseNumber: "",
            deegree: "",
            location: "",
            uploadCertificate: "",
            address: "",
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <InputField
                label="Full Name"
                sublabel="as per license certificate, without Dr."
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                isValid={touched.fullName && !errors.fullName}
              />

              <span className="red">{touched.fullName && errors.fullName}</span>
              <InputField
                label="Registered License Number"
                name="LicenseNumber"
                value={values.LicenseNumber}
                onChange={handleChange}
                isValid={touched.LicenseNumber && !errors.LicenseNumber}
              />
              <span className="red">
                {touched.LicenseNumber && errors.LicenseNumber}
              </span>

              <InputField
                label="Degree & Speciality"
                name="deegree" // Corrected field name
                value={values.deegree}
                onChange={handleChange}
                isValid={touched.deegree && !errors.deegree}
              />
              <span className="red">{touched.deegree && errors.deegree}</span>

              <InputField
                label="Clinic/Hospital Name "
                sublabel="Optional"
                labelstar={false}
                name="location" // Corrected field name
                value={values.location}
                onChange={handleChange}
                isValid={touched.location && !errors.location}
              />
              <InputField
                label="Address and Contact Info "
                sublabel="Optional"
                labelstar={false}
                name="address"
                value={values.address}
                onChange={handleChange}
              />
              <span className="red">{touched.location && errors.location}</span>

              <UploadCertificate
                setFieldValue={setFieldValue}
                label="Upload License Certificate"
              />
              {touched.uploadCertificate && errors.uploadCertificate && (
                <span className="red">{"Please select a file"}</span>
              )}

              <Row className="mt-30">
                <Container className="d-grid gap-2 mb-4 " id="fixedbutton- ">
                  <UtilityButton btnName="Submit" loading={getUserLoading} />
                </Container>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
