import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import UtilityButton from "components/buttons/UtilityButton";
import user from "services/user";
import Radios from "components/form-radios/Radios";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import * as formik from "formik";
import { NewRxSchema } from "utils/schema/NewRxScema";
import { router } from "App";
import { toast } from "react-toastify";
import Plus from "assets/svg/Plus";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import Col from "react-bootstrap/Col";

import {
  clearIdParam,
  getIdParam,
  newRxMobileNumber,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import UserDetailForm from "components/shared/userDetail";
export default function ExistingMember({
  isAssitantPage = false,
  componentHeading,
}) {
  console.log(isAssitantPage, "PAGEEEEEE");
  // const [mobileNumber, setMobileNumber] = useState("");
  const [addMembers, setAddMembers] = useState(false);
  const getUser = JSON.parse(localStorage.getItem("userData"));
  const [validMobileNumber, setValidMobileNumber] = useState(false);

  const [existingMemberId, setExistingMemberId] = useState("");
  useEffect(() => {
    store.dispatch(clearIdParam());
  }, []);
  useEffect(() => {
    // ,
  }, []);
  const { SEARCH_RX: getSearchRxLoading, Rx_USER: getRxLoading } = useSelector(
    (state) => state.loading
  );
  const { newRxMobileNumber: mobileNumber } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const { Formik } = formik;
  const data = useSelector((state) => state.user);
  const { rxUsers } = data || {};
  const handleButtonClick = () => {
    setAddMembers(true);
  };
  const handleMobileChange = (e) => {
    dispatch(newRxMobileNumber(e.target.value));
    // setMobileNumber(e.target.value);
    if (e.target.value.length < 10) {
      setValidMobileNumber(true);
    } else {
      setValidMobileNumber(false);
    }
    if (e.target.value.length > 9) {
      user.searchUser("search", e.target.value);
    }
  };

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className=" fw-700 fs-32" style={{ width: "40%" }}>
          {componentHeading}
        </h1>
        {!addMembers && (
          <div>
            <UtilityButton
              btnName={rxUsers?.length > 0 ? "Add Members" : "New Patient"}
              onClick={handleButtonClick}
              width="223px"
              height="50px"
              disabled={mobileNumber.length < 10}
              suffix={<Plus />}
            />
          </div>
        )}
        {addMembers && (
          <TopHeadingIcon width="34" onClick={() => setAddMembers(false)} />
        )}
      </div>

      <div
        className={`d-flex align-items-start ${
          addMembers ? "justify-content-center" : "justify-content-between"
        } mt-32 Larger shadow`}
        style={{ borderRadius: "10px", padding: "64px" }}
      >
        {!addMembers ? (
          <>
            <div style={{ width: "50%" }}>
              <label className="fs-16 fw-400 mb-8" style={{ color: "#53545A" }}>
                Patient Phone Number
              </label>
              <InputGroup
                style={{ height: "46px", flexWrap: "nowrap", width: "75%" }}
                size="lg"
              >
                <InputGroup.Text
                  id="inputGroup-sizing-lg"
                  style={{ background: "#f5f6f6" }}
                >
                  +91
                </InputGroup.Text>

                <Form.Control
                  aria-label="Large"
                  name="phoneNumber"
                  aria-describedby="inputGroup-sizing-sm"
                  style={{
                    borderLeft: "none",
                    background: "#f5f6f6",
                    maxWidth: "420px",
                  }}
                  type="text"
                  value={Number(mobileNumber) || ""}
                  inputMode="numeric"
                  pattern="[1-9]{1}[0-9]{9}"
                  maxLength={10}
                  onChange={handleMobileChange}
                />
              </InputGroup>
              {validMobileNumber && (
                <span className="red mt-1">
                  Please Enter 10 digits Mobile Number
                </span>
              )}
              <div className="mt-43 mb-60 d-flex ">
                <UtilityButton
                  btnName="Next"
                  disabled={
                    rxUsers?.length > 0 && mobileNumber.length == 10
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (existingMemberId) {
                      store.dispatch(getIdParam(existingMemberId));
                      if(isAssitantPage){
                        router.navigate(`/clinical-detail/${existingMemberId}`)
                      }else{

                          router.navigate(`/new-rx/${existingMemberId}`);
                      }
                    } else {
                      toast.error("Please select a Member");
                    }
                  }}
                  width="223px"
                  height="50px"
                />
                {/* {(rxUsers?.length > 0 || mobileNumber.length == 10) && (
                  <div className="ml-12">
                    <UtilityButton
                      btnName={
                        rxUsers?.length > 0 ? "Add Members" : "New Patient"
                      }
                      onClick={handleButtonClick}
                      width="223px"
                      height="50px"
                      suffix={<Plus />}
                    />
                  </div>
                )} */}
              </div>
            </div>

            <div
              className="d-flex justify-content-center flex-column"
              style={{ width: "50%" }}
            >
              {rxUsers?.length === 0 && getSearchRxLoading ? (
                <>
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" />
                  </div>
                </>
              ) : mobileNumber?.length === 10 && rxUsers.length > 0 ? (
                <>
                  <label
                    className="label lightgray fs-16 mb-14 fw-400 black"
                    htmlFor="floatingPasswordCustom"
                    style={{ color: "#55565c !important" }}
                  >
                    Existing Member(s)
                  </label>
                  <div className="d-flex justify-content-between flex-wrap ">
                    {rxUsers?.length > 0 &&
                      mobileNumber?.length === 10 &&
                      rxUsers?.map((users, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              style={{ flex: "40%" }}
                              onClick={() => setExistingMemberId(users.id)}
                            >
                              <Radios
                                label={users?.patient_name}
                                i={users?.id}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : (
                <span
                  className="d-flex justify-content-center"
                  style={{ color: "#53545A" }}
                >
                  {mobileNumber.length == 10 &&
                    rxUsers?.length === 0 &&
                    !getRxLoading &&
                    "Patient Not Found"}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            {isAssitantPage ? (
              <UserDetailForm isassistantPage={true} />
            ) : (
              <UserDetailForm />
            )}
          </>
        )}
      </div>
    </div>
  );
}
