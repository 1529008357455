import ClockIcon from "assets/svg/ClockIcon";
import EditIcon from "assets/svg/EditIcon";
import WhatsApp from "assets/svg/WhatsApp";
import Print from "assets/svg/Print";
import UtilityButton from "components/buttons/UtilityButton";
import DrugModal from "components/modals/DrugModal";
import PreceptionModal from "components/modals/PreceptionModal";
import SelectBox from "components/select-box/SelectBox";
import useIsMobile from "hooks/useResize";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import favourite from "services/favourite";
import { setLoading as sliceLoading } from "redux-store/slices/loading";
import {
  addDiagnosis,
  addDiagnosticPrescriptionDetails,
  addPrescriptionDetails,
  clearAddPrescriptionDetails,
  medicineId,
  removePrescriptionKey,
  updateDiagnosticPrescriptionDetails,
  updateMedicinesData,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import user from "services/user";
import { router } from "App";
import PdfReview from "components/pdfReview/pdfReview";

import StarIcon from "assets/svg/StarIcon";
import FavouriteModal from "./FavouriteModal";
import { toast } from "react-toastify";
import favouriteDiagnostic from "services/favouriteDiagnostic";

export default function RxContentDetail({
  data,
  isReview,
  setIsReview,
  setPageName,
}) {
  const [isPrescriptionModal, setIsPrescriptionModalOpen] = useState(false);
  const [dropDownKey, setDropDownKey] = useState(1);
  const [isOpenDrugModal, setIsOpenDrugModal] = useState(false);
  const [isOpenAddDrugModal, setIsOpenAddDrugModal] = useState(false);
  const [diagnosis, setDiagnosis] = useState("");
  const [medDetail, setMedDetail] = useState({});
  const [medicineName, setMedicineName] = useState("");
  const [medicineDetail, setMedicineDetail] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const Medicines = useSelector((state) => state.user);
  const { id: existingMemberId } = useParams();
  const { getMedicines, newRx, savedRX, diagnostics } = Medicines || {};
  const [pageNo, setPage] = useState(1);
  const [searchPageOptions, setSearchPageOptions] = useState(0);
  const [isDiagnostics, setIsDiagnostics] = useState(false);
  const [medInstruction, setMedInstruction] = useState({});
  const dispatch = useDispatch();
  const { diagnosis: advice, prescriptions } = newRx?.[existingMemberId] || {};
  const { diagnosticData } = diagnostics?.[existingMemberId] || {};
  const loading = useSelector((state) => state.loading);
  const [loadingOption, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [pdfModal, setpdfModal] = useState(false);
  const isMobile = useIsMobile();
  const { pdfUrl } = useSelector((state) => state.user);

  const {
    ADD_PRESCRIPTION: addPrescriptionLoading,
    SEARCH_MEDICINES: searchMedicineLoading,
    PRINT_PDF: printPdfLoading,
  } = loading;
  const [medName, setMedName] = useState("");
  const medicineList = useMemo(() => {
    return getMedicines?.map((med) => {
      const data = {
        value: med.id,
        // label: med?.brand_name_short ? med?.brand_name_short : med?.brand_name,
        label: med?.brand_name_short,

        generic_name: med.generic_name,
      };
      return data;
    });
  }, [getMedicines]);
  const { patient_name, age, gender, id, last_prescription_id } = data || {};
  const handleDiagnosticMedicine = (medInstruction, brandShortName, index) => {
    setMedInstruction({ instructions: medInstruction, brandShortName, index });
    setIsDiagnostics(true);
    setIsOpenDrugModal(true);
    setIsEdit(true);
  };
  const handleEditClick = (id, medName, gen_name, brand_name_short, index,brand_name) => {
    setMedicineDetail({
      value: id,
      generic_name: gen_name,
      label: gen_name === undefined || gen_name === null? brand_name_short : medName,
      brand_name_short: brand_name_short,
      index,
      brand_name:brand_name
    });

    store.dispatch(medicineId(index));

    setIsOpenDrugModal(true);
    setIsEdit(true);
  };
  const handleSendRx = (is_print) => {
      if(is_print){
        store.dispatch(sliceLoading(["PRINT_PDF", true]))
      }else{
        store.dispatch(sliceLoading(["ADD_PRESCRIPTION", true]));
      }
    const combineData = [...(prescriptions || []), ...(diagnosticData || [])];
    const updatedData = combineData.map(item => {
      return Object.keys(item)
        .filter(key => key !== 'id')  // Filter out 'id'
        .reduce((acc, key) => {
          acc[key] = item[key];  // Rebuild object without 'id'
          return acc;
        }, {});
    });
  

    const data = {
      follow_up_advice: diagnosis,
      user_id: id,
      medicines: updatedData,
      is_print: is_print,
      rx_id: Number(last_prescription_id),
    };
    user.addPrescription(data, is_print, isMobile);
  };

  const handleDiagnosis = (e) => {
    let value = e.target.value;
    setDiagnosis(value);
    store.dispatch(addDiagnosis({ existingMemberId, value }));
  };

  const handleAddDrugModal = () => {
    setIsOpenDrugModal(false);
    setIsOpenAddDrugModal(true);
    setIsEdit(false);
  };
  const closePdfModal = () => {
    setpdfModal(false);
    router.navigate("/home");
  };

  const lastElemRef = useRef();
  const handleFavouriteMedice = async (medIndex, med) => {
    try {
      let data;
      if (med?.fav==1 ) {
        await favourite.deleFavouriteItem([med?.id], med?.dosage_duration);
        setSelectedIndexes((prevIndexes) => {
          return prevIndexes.filter((id) => id !== med.medicine_id);
        });
        store.dispatch(removePrescriptionKey({index:medIndex,  id: existingMemberId,}))
      } else {
        data = await favourite.addFavourite(med);
      }
      if (data?.data?.statusCode == 200) {
        const { newMedicine } = data.data;
        const newMedicineId = newMedicine?.medicine_id;
        setSelectedIndexes((prevIndexes) => {
          if (prevIndexes.includes(newMedicineId)) {
            return prevIndexes.filter((id) => id !== newMedicineId);
          }
          const allprescriptionData = prescriptions;
          store.dispatch(clearAddPrescriptionDetails());
          const storeData = allprescriptionData.map((item, index) => {
            if (item.brand_name === newMedicine?.brand_name) {
              const updatedItem = {
                brand_name:item.brand_name,
                generic_name:item.generic_name,
                brand_name_short: item.brand_name_short,
                medicine_name:item.brand_name_short,
                dosage_duration: item.dosage_duration,
                additional_instruction: item.additional_instruction,
                intake_morning: item.intake_morning,
                intake_afternoon: item.intake_afternoon,
                intake_evening: item.intake_evening,
                intake_night: item.intake_night,
                before_food: item.before_food,
                after_food: item.after_food,
                is_diagnostic: item.is_diagnostic,
                medicine_id: newMedicineId,
                fav: medIndex==index ? 1 :0,
                id : typeof newMedicine === 'object' ? newMedicine.id : newMedicine
              };
              store.dispatch(
                addPrescriptionDetails({
                  id: existingMemberId,
                  diagnosis: "",
                  medicalDetail: updatedItem,
                })
              );

              return updatedItem;
            } else {
              const updatedItem={...item, fav:1, id:newMedicine}
              store.dispatch(
                addPrescriptionDetails({
                  id: existingMemberId,
                  diagnosis: "",
                  medicalDetail: updatedItem,
                })
              );

              return item;
            }
          });
          return [...prevIndexes, newMedicineId];
        });
      } else {
        toast.error(data?.data?.message);
      }
    } catch (error) {
      console.error("Error handling favourite medicine:", error);
    }
  };
  useEffect(() => {
    const elementList = document?.getElementsByClassName("meds-list");

    if (elementList) {
      const lastElement = elementList?.[0]?.lastElementChild;
      const secondLastElement = lastElement?.previousElementSibling;

      const customRoot = document.querySelector(".meds-list");
      if (customRoot) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.target === lastElement && entry.isIntersecting) {
                if (lastElement.textContent.trim() !== "") {
                  if (pageNo >= 1) {
                    setPage(pageNo + 1);
                  }

                  // user.searchMedicine(medicineName, pageNo);

                  // setPage( pageNo + 1);
                  observer.disconnect();
                }
              }
            });
          },
          {
            root: customRoot,
            rootMargin: "0px",
            threshold: 1.0,
          }
        );
        if (lastElement) {
          observer.observe(lastElement);
        }

        return () => {
          observer.disconnect();
        };
      }
    }
  }, [medicineList]);

  useEffect(() => {
    if (medicineName?.length >= 3) {
      // setIncreasedPage(false);
      setLoading(true);

      // if ((pageNo >= 1 && pageNo <= totalPage) || !totalCount ) {
      user.searchMedicine(medicineName, 1);
      // }
    }
  }, [medicineName]);

  useEffect(() => {
    if (!isPrescriptionModal) {
      dispatch(
        updateMedicinesData({
          shouldReset: true,
        })
      );
    }
  }, [medicineName]);



  const handleFavouriteDiagnostic=async(med,index, isStar)=>{
    if(isStar){
      const data=await favouriteDiagnostic.deleteFavouriteDiagnosstice([med.id])
      const updatedMed={
          "brand_name_short": med.brand_name_short,
          "additional_instruction": med.additional_instruction,
          "is_diagnostic": med.is_diagnostic
      }
      store.dispatch(
        updateDiagnosticPrescriptionDetails({
          id: existingMemberId,
          index: index,
          medicalDetail: updatedMed,
        })
      );
    }else{
      const data=await favouriteDiagnostic.addfavouriteDiganonstice(med)
      if(data?.newDiagnostice){
        toast.success("Diagnostic Added to Favourites")
        store.dispatch(
          updateDiagnosticPrescriptionDetails({
            id: existingMemberId,
            index: index,
            medicalDetail: data?.newDiagnostice,
          })
        );
      }
    }
   
  }
  useEffect(() => {
    if (prescriptions) {
      const initialIndexes = prescriptions
        .map((med, index) => (med.fav ? med.medicine_id : null))
        .filter((index) => index !== null);
      setSelectedIndexes((prev) => [...prev, ...initialIndexes]);
    }
  }, [prescriptions]);

  return (
    <div style={{ marginBottom: "80px" }}>
      <div className="d-flex justify-content-between mt-30">
        <div>
          <p className="fw-700 m-0 fs-18">{`${patient_name?.[0]?.toUpperCase()}${patient_name?.slice(
            1
          )}`}</p>

          <p className="fs-14 grey m-0">
            {`${gender?.[0]?.toUpperCase()}${gender?.slice(1)} / ${age} Years`}
          </p>
        </div>
        <div>
          <span className="fw-700 m-0 fs-18">
            Rx ID{" "}
            <span className="ml-10 fs-14 fw-400 grey">
              {last_prescription_id}
            </span>
          </span>
        </div>
      </div>
      <div className="mt-15">
        {/* <p className="fs-16 mb-2">Clinical details & Advice</p> */}
        <textarea
          style={{ height: "106px", border: "3px solid #cddef1" }}
          type="text"
          class="form-control "
          id="exampleInputPassword1"
          value={savedRX[existingMemberId]}
          disabled={isReview}
          onChange={handleDiagnosis}
          placeholder={!isReview ? "Clinical details & Advice (Optional)" : ""}
        />
      </div>
      {!isReview && (
        <div className="mt-15">
          <div className="d-flex justify-content-start custom-gap align-items-center">
            <label className="fs-16 fw-400 mb-8">Prescription</label>
            <Button
              className="fs-11 gray  mb-2 fw-500 border-0"
              variant="light"
              style={{ color: "#19104e", backgroundColor: "#d8f1fb" }}
              onClick={handleAddDrugModal}
            >
              Manual Entry
            </Button>
            <FavouriteModal setSelectedIndexes={setSelectedIndexes} />

            {isOpenAddDrugModal && (
              <DrugModal
                diagnosis={diagnosis}
                medicineDetail={medDetail}
                setMedDetail={setMedDetail}
                isOpen={isOpenAddDrugModal}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setIsOpen={setIsOpenAddDrugModal}
                setMedicineDetail={setMedicineDetail}
                isOpenAddDrugModal={isOpenAddDrugModal}
                setIsDiagnostics={setIsDiagnostics}
                setInputValue={setInputValue}
              />
            )}
          </div>
          <SelectBox
            medicinesList={medicineList}
            dropDownKey={dropDownKey}
            setMedicineName={setMedicineName}
            setIsOpen={setIsPrescriptionModalOpen}
            setMedDetail={setMedDetail}
            medicineName={medicineName}
            loading={searchMedicineLoading}
            medicineDetail={medicineDetail}
            setMedicineDetail={setMedicineDetail}
            lastElemRef={lastElemRef}
            loadingOption={loadingOption}
            searchPageOptions={searchPageOptions}
            setSearchPageOptions={setSearchPageOptions}
            pageNo={pageNo}
            setMedName={setMedName}
            medName={medName}
            setIsOpenDrugModal={setIsOpenDrugModal}
            setIsDiagnostics={setIsDiagnostics}
            setMedInstruction={setMedInstruction}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </div>
      )}
      {prescriptions?.length > 0 && (
        <label className="fs-12 fw-400 mb-8 grey m-0 mt-3">
          Selected Medicine
        </label>
      )}
      <div
        className="mt-9"
        style={{
          // maxHeight: isReview && (isMobile ? "200px" : "300px"),
          maxHeight: !isReview && (isMobile ? "246px" : "300px"),
          overflow: !isReview && "scroll",
        }}
      >
        {prescriptions?.length > 0 ? (
          <>
            {prescriptions?.map((med, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-10  fs-14 fw-500"
                    style={{
                      padding: "11px 17px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      overflowWrap: "anywhere",
                      // backgroundColor: index % 2 === 0 ? "#f3fbfe" : "#fff5f4",
                      backgroundColor: "#f3fbfe",
                      border: "1px solid #f0ece4",
                    }}
                  >
                    <div className="d-flex flex-column  w-100">
                      {/* <div>{med?.medicine_name}</div> */}
                      <div>
                        {med?.generic_name === undefined ||
                        med?.generic_name === null
                          ? med?.brand_name_short
                          : `${med?.medicine_name} `}
                      </div>
                      <div className=" cursor-pointer">
                        {/* <EditIcon /> */}
                        <div>
                          <ClockIcon /> &nbsp;
                          <span
                            className={`${isMobile ? "fs-12" : "fs-16"} fw-400`}
                            style={{ color: "#5e5992" }}
                          >
                            {med?.dosage_duration && med?.dosage_duration}
                          </span>
                          {(med?.intake_morning === 1 ||
                            med?.intake_afternoon === 1 ||
                            med?.intake_evening === 1 ||
                            med?.intake_night === 1) && (
                            <>
                              <span style={{ color: "#5e5992" }}>
                                (
                                <span
                                  className={`${
                                    isMobile ? "fs-12" : "fs-16"
                                  } fw-400`}
                                  style={{ color: "#5e5992" }}
                                >
                                  {[
                                    med?.intake_morning && "Morning",
                                    med?.intake_afternoon && "Afternoon",
                                    med?.intake_evening && "Evening",
                                    med?.intake_night && "Night",
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </span>
                                )
                              </span>
                            </>
                          )}
                          <span
                            className={`${isMobile ? "fs-12" : "fs-16"} fw-400`}
                            style={{ color: "#5e5992" }}
                          >
                            {med?.before_food === 1 && ` (Before Food)`}

                            {med?.after_food === 1 && ` (After Food)`}
                          </span>
                          <span
                            className={`${isMobile ? "fs-12" : "fs-16"} fw-400`}
                            style={{ color: "#5e5992" }}
                          >
                            {med?.additional_instruction?.length > 0 && (
                              <span>({med?.additional_instruction})</span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {!isReview && (
                      <Stack direction="horizontal" gap={3}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            handleFavouriteMedice(index, med);
                          }}
                        >
                          <StarIcon color={med?.fav == 1 ? true : false} />
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            handleEditClick(
                              med?.medicine_id,
                              med?.medicine_name,
                              med?.generic_name,
                              med?.brand_name_short,
                              index,
                              med?.brand_name
                            );
                          }}
                        >
                          <EditIcon />
                        </div>
                      </Stack>
                    )}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <p className="fs-12 fw-400 mb-8 grey">Please Select the Medicines</p>
        )}
      </div>
      {diagnosticData?.length > 0 && (
        <label className="fs-12 fw-400 mb-8 grey m-0 ">
          Selected Diagnostics
        </label>
      )}
      <div
        className="mt-9"
        style={{
          maxHeight: !isReview && (isMobile ? "200px" : "300px"),
          overflow: !isReview && "scroll",
        }}
      >
        {diagnosticData?.length > 0 && (
          <>
            {diagnosticData?.map((med, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-10  fs-14 fw-500"
                    style={{
                      padding: "11px 17px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      overflowWrap: "anywhere",
                      // backgroundColor: index % 2 === 0 ? "#f3fbfe" : "#fff5f4",
                      backgroundColor: "#fff5f4",
                      border: "1px solid #f0ece4",
                    }}
                  >
                    <div className="d-flex flex-column  w-100">
                      {/* <div>{med?.medicine_name}</div> */}
                      <div>{med?.brand_name_short}</div>
                      <div className=" cursor-pointer">
                        {/* <EditIcon /> */}
                        <div>
                          {/* <ClockIcon /> &nbsp; */}
                          <span
                            className={`${isMobile ? "fs-12" : "fs-16"} fw-400`}
                            style={{ color: "#5e5992" }}
                          >
                            {med?.additional_instruction}
                          </span>
                        </div>
                      </div>
                    </div>
                    {!isReview && (
                         <Stack direction="horizontal" gap={3}>
                         <div
                           className="cursor-pointer"
                           onClick={() => {
                             handleFavouriteDiagnostic(med,index, ('id' in med)? true:false);
                           }}
                         >
                           <StarIcon color={("id" in med) ? true : false} />
                         </div>
                         <div
                           className="cursor-pointer"
                           onClick={() => {
                            handleDiagnosticMedicine(
                              med?.additional_instruction,
                              med.brand_name_short,
                              index
                            );
                          }}
                         >
                           <EditIcon />
                         </div>
                       </Stack>
                     
                    )}
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
      {(prescriptions?.length > 0 || diagnosticData?.length > 0) && (
        <div className="d-flex justify-content-center mt-4 ">
          {isReview ? (
            <>
              <div className="mx-12 mt-12">
                <UtilityButton
                  btnName="Edit "
                  bgColor="white"
                  color="#105CAA "
                  width={isMobile ? "80px" : "209px"}
                  border="1px solid #105CAA"
                  onClick={() => {
                    setIsReview(false);
                    setPageName("Edit Rx");
                  }}
                />
              </div>
              <div className="mx-12 mt-12">
                <UtilityButton
                  btnName="Send"
                  width={isMobile ? "100px" : "209px"}
                  onClick={() => handleSendRx(false)}
                  disabled={addPrescriptionLoading}
                  loading={addPrescriptionLoading}
                  suffix={<WhatsApp />}
                />
              </div>
              <div className="mx-12 mt-12">
                <UtilityButton
                  btnName="Print "
                  width={isMobile ? "85px" : "209px"}
                  border="1px solid #105CAA"
                  disabled={printPdfLoading}
                  loading={printPdfLoading}
                  suffix={<Print />}
                  onClick={() => {
                    handleSendRx(true);
                    setpdfModal(true);
                  }}
                />
              </div>
            </>
          ) : (
            <UtilityButton
              btnName="Review"
              width={isMobile ? "120px" : "209px"}
              onClick={() => setIsReview(true)}
              disabled={addPrescriptionLoading}
            />
          )}
        </div>
      )}
      {isPrescriptionModal && (
        <PreceptionModal
          setDropDownKey={setDropDownKey}
          medicines={getMedicines}
          setMedDetail={setMedDetail}
          isOpen={isPrescriptionModal}
          setIsOpen={setIsPrescriptionModalOpen}
          setIsPrescriptionModalOpen={setIsPrescriptionModalOpen}
          setIsAddDrugModalOpen={setIsOpenDrugModal}
          setMedicineDetail={setMedicineDetail}
          medicineDetail={medicineDetail}
          medicineName={medName}
          loading={searchMedicineLoading}
          searchPageOptions={searchPageOptions}
        />
      )}
      {pdfUrl && pdfModal && !isMobile && (
        <PdfReview open={true} handleClosePdfModal={closePdfModal} />
      )}

      {isOpenDrugModal && (
        <DrugModal
          diagnosis={diagnosis}
          medicineDetail={medicineDetail}
          setMedDetail={setMedDetail}
          isOpen={isOpenDrugModal}
          isEdit={isEdit}
          medDetail={medDetail}
          setIsEdit={setIsEdit}
          setIsOpen={setIsOpenDrugModal}
          setMedicineDetail={setMedicineDetail}
          isDiagnostics={isDiagnostics}
          setIsDiagnostics={setIsDiagnostics}
          medInstruction={medInstruction}
          setInputValue={setInputValue}
        />
      )}
    </div>
  );
}
