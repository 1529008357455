import BakcIcon from "assets/svg/BackIcon";
import React from "react";

export default function Video({ setIsVideo }) {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* <div
        className="back-icon-parent mt-2 "
        style={{ marginLeft: "5px" }}
        onClick={() => setIsVideo(false)}
      >
        <BakcIcon />
      </div> */}
      <video autoPlay width="100%" height="100%" controls allowfullscreen>
        <source
          src="https://clearrxvids.s3.ap-south-1.amazonaws.com/demo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
