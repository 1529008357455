import { router } from "App";
import ClockIcon from "assets/svg/ClockIcon";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import Loader from "components/loader/Loader";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  getprescriptionIdParam,
  getsearchIdParam,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import user from "services/user";
import { convertTimestampToReadableDate } from "utils/helper";

export default function Prescription({ data, isReview, setIsReview }) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const user_id = searchParams.get("user_id");
  const prescriptionId = searchParams.get("prescription_id");

  const { particularUserPrescription } = useSelector((state) => state.user);
  const { GET_PARTICULAR_USER_PRESCRIPTION: loading } = useSelector(
    (state) => state.loading
  );
  const { rx_user, prescription } = particularUserPrescription || {};
  useEffect(() => {
    user.getParticularUserPrescription(user_id, prescriptionId);
  }, []);
  useEffect(() => {
    const data = {
      pathName: location,
      user_id: user_id,
      prescription_id: prescriptionId,
    };
    store.dispatch(getprescriptionIdParam(data));
    store.dispatch(getsearchIdParam({}));
  }, []);

  return (
    <>
      <div
        className="d-flex justify-content-between mt-60 "
        style={{ width: "90%", margin: "60px auto 30px" }}
      >
        <span className="fs-32 fw-700">Prescription</span>
        <TopHeadingIcon
          width="34"
          onClick={() => router.navigate(`/rx-history?memberId=${user_id}`)}
        />
      </div>
      <div
        className="  Regular shadow mb-30"
        style={{
          width: "90%",
          margin: "auto",
          padding: "30px 0px",
          borderRadius: "10px",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              className="d-flex justify-content-between  align-items-center"
              style={{ width: "80%", margin: "auto", padding: "30px 0px" }}
            >
              <div>
                <p className="fw-700 m-0 fs-18">{`${rx_user?.patient_name[0].toUpperCase()}${rx_user?.patient_name.slice(
                  1
                )}`}</p>

                <p className="fs-14 grey m-0">
                  {" "}
                  {`${rx_user?.gender?.[0].toUpperCase()}${rx_user?.gender?.slice(
                    1
                  )}/ ${rx_user?.age} Years`}
                </p>
              </div>
              <div>
                <div className="mr-16">
                  <p className="text-blue fs-16 fw-700 m-0">
                    {prescription?.[0]?.user?.full_name}
                  </p>
                  <p className="text-blue fs-14 fw-400 m-0">
                    {prescription?.[0]?.user?.degree_speciality}
                  </p>
                  <p className="text-blue fs-12 fw-400 m-0">
                    License - {prescription?.[0]?.user?.licence_number}
                  </p>
                </div>
                <p className="fs-14 lightgray m-0">
                  {convertTimestampToReadableDate(prescription?.[0].createdAt)}
                </p>

                <span className="fw-700 m-0 fs-18">
                  Rx ID
                  <span className="ml-10 fs-14 fw-400 grey">
                    {prescription?.[0]?.rx_id}
                  </span>
                </span>
              </div>
            </div>
            <p
              className="fs-18 fw-400 mb-2"
              style={{ width: "80%", margin: "auto" }}
            >
              Diagnosis and Follow up advice
            </p>
            <div className="mt-15" style={{ width: "80%", margin: "auto" }}>
              <textarea
                style={{ height: "106px" }}
                type="text"
                disabled={true}
                class="form-control mt-10"
                id="exampleInputPassword1"
                value={prescription?.[0]?.follow_up_advice}
              />
            </div>
            <p
              className="fs-18 fw-400  mt-3"
              style={{ width: "80%", margin: "auto" }}
            >
              Prescription
            </p>

            <label
              className="d-flex fs-12 fw-400  grey"
              style={{ width: "80%", margin: " auto" }}
            >
              Selected Medicine
            </label>
            <div
              className="mt-9"
              style={{
                maxHeight: "300px",
                overflow: "scroll",
                width: "80%",
                margin: "auto",
              }}
            >
              {prescription?.[0]?.prescription_medicines?.map(
                (items, index) => {
                  return (
                    <div
                      // key={index}
                      className="d-flex justify-content-between align-items-center mb-10  fs-14 fw-500"
                      style={{
                        padding: "11px 17px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        overflowWrap: "anywhere",

                        backgroundColor:
                          index % 2 === 0 ? "#f3fbfe" : "#fff5f4",
                        border: "1px solid #f0ece4",
                      }}
                    >
                      <div className="d-flex flex-column  w-100">
                        <div>
                          {items?.medicine?.brand_name_short}{" "}
                          {items?.medicine?.generic_name &&
                            `(${items?.medicine?.generic_name})`}
                        </div>
                        {/* <p className="fw-500 fs-14 m-0 ">
                          {`${item.prescription_medicines?.[0]?.medicine?.brand_name_short} ( ${item.prescription_medicines?.[0]?.medicine?.generic_name})`}
                        </p> */}

                        <div className=" cursor-pointer">
                          {/* <EditIcon /> */}
                          <div>
                            <ClockIcon /> &nbsp;
                            <span
                              className="fs-16 fw-400"
                              style={{ color: "#5e5992" }}
                            >
                              {items?.dosage_duration &&
                                `  (${items?.dosage_duration})`}
                            </span>
                            {(items?.intake_morning === 1 ||
                              items?.intake_afternoon === 1 ||
                              items?.intake_evening === 1 ||
                              items?.intake_night === 1) && (
                              <>
                                <span style={{ color: "#5e5992" }}>
                                  (
                                  <span
                                    className=" fs-16 fw-400"
                                    style={{ color: "#5e5992" }}
                                  >
                                    {[
                                      items?.intake_morning && "Morning",
                                      items?.intake_afternoon && "Afternoon",
                                      items?.intake_evening && "Evening",
                                      items?.intake_night && "Night",
                                    ]
                                      .filter(Boolean)
                                      .join(", ")}
                                  </span>
                                  )
                                </span>
                              </>
                            )}
                            <span
                              className="fs-16 fw-400"
                              style={{ color: "#5e5992" }}
                            >
                              {items?.before_food === 1 && ` (Before Food)`}

                              {items?.after_food === 1 && ` (After Food)`}
                            </span>
                            <span
                              className="fs-16 fw-400"
                              style={{ color: "#5e5992" }}
                            >
                              {items?.additional_instruction &&
                                `(${items?.additional_instruction})`}
                            </span>
                          </div>
                        </div>
                      </div>
                      {!isReview && <div className="cursor-pointer"></div>}
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
