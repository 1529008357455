const ClockIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="mingcute:time-fill" clip-path="url(#clip0_1100_2000)">
          <g id="Group">
            <path
              id="Vector"
              d="M8.00065 1.33203C11.6827 1.33203 14.6673 4.3167 14.6673 7.9987C14.6673 11.6807 11.6827 14.6654 8.00065 14.6654C4.31865 14.6654 1.33398 11.6807 1.33398 7.9987C1.33398 4.3167 4.31865 1.33203 8.00065 1.33203ZM8.00065 3.9987C7.82384 3.9987 7.65427 4.06894 7.52925 4.19396C7.40422 4.31898 7.33398 4.48855 7.33398 4.66536V7.9987C7.33402 8.17549 7.40428 8.34504 7.52932 8.47003L9.52932 10.47C9.65505 10.5915 9.82345 10.6587 9.99825 10.6571C10.173 10.6556 10.3403 10.5855 10.4639 10.4619C10.5875 10.3383 10.6576 10.1711 10.6591 9.9963C10.6606 9.8215 10.5934 9.6531 10.472 9.52736L8.66732 7.7227V4.66536C8.66732 4.48855 8.59708 4.31898 8.47206 4.19396C8.34703 4.06894 8.17746 3.9987 8.00065 3.9987Z"
              fill="#105CAA"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1100_2000">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export default ClockIcon;
