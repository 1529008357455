import React from "react";

const TrashIcon = () => {
  return (
    <div
      style={{
        backgroundColor: "#d2f0fb",
        borderRadius: "50%",
        height: "27px",
        width: "27px",
        textAlign: "center",
      }}
    >
      <svg
        fill="red"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="13px"
        height="13px"
        viewBox="0 0 523.266 523.266"
        xmlSpace="preserve"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <rect x="72.528" width="378.191" height="77.476"></rect>{" "}
              <path d="M450.738,100.154H72.543v423.112h378.192V100.154H450.738z M178.453,465.487h-30.6V157.933h30.6V465.487z M276.942,465.487h-30.6V157.933h30.6V465.487z M375.425,465.487h-30.601V157.933h30.601V465.487z"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>
      </svg>
    </div>
  );
};

export default TrashIcon;
