import React from "react";

const DataBox = ({ children, paddingBottom, background, margin }) => {
  return (
    <div
      className="  Regular shadow"
      style={{
        width: "90%",
        margin: margin || "0px auto 44px auto",
        paddingBottom: paddingBottom || "40px",
        borderRadius: "12px",
        background: background || "unset",
      }}
    >
      {children}
    </div>
  );
};

export default DataBox;
