import { router } from "App";
import { AXIOS } from "utils/axios";
import { toast } from "react-toastify";
import { store } from "redux-store/store";
import { setLoading } from "redux-store/slices/loading";

class AuthService {
  async confirmOtp(mobileNumber, otp) {
    try {
      store.dispatch(setLoading(["GET_USER", true]));
      const response = await AXIOS.post("/verify-otp", {
        mobile_number: mobileNumber,
        otp: Number(otp),
      });
      if (response?.data?.statusCode === 200) {
        const userData = response?.data?.body?.user || {};
        localStorage.setItem("userData", JSON.stringify(userData));
        if (!response?.data?.body?.userExits) {
          router.navigate(`/register?phone=${mobileNumber}`);
          return;
        }
        if (
          response?.data?.body?.userExits &&
          response?.data?.body?.accountVerified
        ) {
          localStorage.setItem("token", response?.data?.body?.token);
          router.navigate("/home");
        } else {
          if (!response.data.body.userExits) {
            router.navigate(`/register?phone=${mobileNumber}`);
          }
          if (!response?.data?.body?.accountVerified) {
            router.navigate("/login");
          }
        }
        toast.success(response?.data?.body?.message);
      }
      if (response?.data?.statusCode === 404) {
        toast.error("Wrong OTP");
      }
    } catch (error) {
      console.log("please enter a valid input");
    } finally {
      store.dispatch(setLoading(["GET_USER", false]));
    }
  }

  async sendOtp(mobileNumber, newMobileNumber) {
    try {
      store.dispatch(setLoading(["GET_USER", true]));
      const response = await AXIOS.post("/login", {
        mobile_number: mobileNumber,
        new_number: newMobileNumber,
      });
      if (response?.data?.statusCode === 200) {
        toast.success(response.data.body.message);
        if (!newMobileNumber) {
          router.navigate(`/confirm-otp?phone=${mobileNumber}`);
        }
      }

      if (response?.data?.statusCode !== 200) {
        toast.error(response.data.body.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      store.dispatch(setLoading(["GET_USER", false]));
    }
  }

  async register(values, img, phoneNumber) {
    try {
      store.dispatch(setLoading(["GET_USER", true]));
      const { LicenseNumber, deegree, fullName, location, address } =
        values || {};

      const data = {
        mobile_number: phoneNumber,
        full_name: fullName,
        licence_number: LicenseNumber,
        degree_speciality: deegree,
        practice_location: location,
        imageArr: img,
        contact_info: address,
      };
      const response = await AXIOS.post("/register", data);

      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "userData",
          JSON.stringify(response?.data?.body?.user)
        );
        router.navigate("/thanks");
        // toast.success(response?.data?.body.message);
        return;
      }
    } catch (error) {
      //
    } finally {
      store.dispatch(setLoading(["GET_USER", false]));
    }
  }
  async approveUser(data, approveuser) {
    store.dispatch(setLoading(["Approve_User", true]));

    try {
      const response = await AXIOS.post("/approve-user", data);
      if (response.data.statusCode === 200) {
        approveuser(true);
        toast.success(response?.data?.body?.message);
      } else {
        approveuser(false);
        toast.error(response.data.body.message);
      }
    } catch (error) {
      console.log(error, "errorasfdasdfasdf");
      //
    } finally {
      store.dispatch(setLoading(["Approve_User", false]));
    }
  }
}

export default new AuthService();
