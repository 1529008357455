import DataBox from 'components/shared/dataBox'
import PageHeading from 'components/shared/pageHeading'
import SectionContainer from 'components/shared/sectionContainer'
import UserDetailForm from 'components/shared/userDetail'
import TopHeadingIcon from 'components/WebComponet/TopHeadingIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ClinicalDetail = () => {
  const navigate=useNavigate()
  const handleBack=()=>{
    navigate(-1)
  }
  return (
    <SectionContainer>
    <div className='d-flex align-items-center'style={{width:'88%', margin:'0 auto'}}>
    <PageHeading heading="Patient Details" />

    <TopHeadingIcon width="34" onClick={() => handleBack()} />
    </div>
    <DataBox>
      <div style={{ padding: "20px" }}>
        <h4>Add Patient Detail</h4>
        <UserDetailForm isassistantPage={true} />
      </div>
    </DataBox>
  </SectionContainer>
  )
}

export default ClinicalDetail