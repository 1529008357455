const ImgIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="36"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.47251 0C3.65243 0 2.98145 0.67098 2.98145 1.49107V22.366C2.98145 23.1861 3.65243 23.8571 4.47251 23.8571H19.3832C20.2033 23.8571 20.8742 23.1861 20.8742 22.366V5.96427L14.91 0H4.47251Z"
          fill="#E2E5E7"
        />
        <path
          d="M16.4012 5.96427H20.8744L14.9102 0V4.4732C14.9102 5.29329 15.5811 5.96427 16.4012 5.96427Z"
          fill="#B0B7BD"
        />
        <path
          d="M20.8746 10.4361L16.4014 5.96289H20.8746V10.4361Z"
          fill="#CAD1D8"
        />
        <path
          d="M17.8928 19.3835C17.8928 19.7935 17.5573 20.129 17.1473 20.129H0.745533C0.33549 20.129 0 19.7935 0 19.3835V11.9282C0 11.5181 0.33549 11.1826 0.745533 11.1826H17.1473C17.5573 11.1826 17.8928 11.5181 17.8928 11.9282V19.3835Z"
          fill="#F15642"
        />
        <path
          d="M17.1466 20.1293H2.98145V20.8749H17.1466C17.5566 20.8749 17.8921 20.5394 17.8921 20.1293V19.3838C17.8921 19.7938 17.5566 20.1293 17.1466 20.1293Z"
          fill="#CAD1D8"
        />
        <path
          d="M3.323 13.135V18H2.525V13.135H3.323ZM9.8447 13.135V18H9.0467V14.668L7.5627 18H7.0097L5.5187 14.668V18H4.7207V13.135H5.5817L7.2897 16.95L8.9907 13.135H9.8447ZM14.7646 14.535C14.6386 14.2923 14.4636 14.1103 14.2396 13.989C14.0156 13.863 13.7566 13.8 13.4626 13.8C13.1406 13.8 12.8536 13.8723 12.6016 14.017C12.3496 14.1617 12.1512 14.367 12.0066 14.633C11.8666 14.899 11.7966 15.207 11.7966 15.557C11.7966 15.907 11.8666 16.2173 12.0066 16.488C12.1512 16.754 12.3496 16.9593 12.6016 17.104C12.8536 17.2487 13.1406 17.321 13.4626 17.321C13.8966 17.321 14.2489 17.1997 14.5196 16.957C14.7902 16.7143 14.9559 16.3853 15.0166 15.97H13.1896V15.333H15.8706V15.956C15.8192 16.334 15.6839 16.6817 15.4646 16.999C15.2499 17.3163 14.9676 17.5707 14.6176 17.762C14.2722 17.9487 13.8872 18.042 13.4626 18.042C13.0052 18.042 12.5876 17.937 12.2096 17.727C11.8316 17.5123 11.5306 17.216 11.3066 16.838C11.0872 16.46 10.9776 16.033 10.9776 15.557C10.9776 15.081 11.0872 14.654 11.3066 14.276C11.5306 13.898 11.8316 13.604 12.2096 13.394C12.5922 13.1793 13.0099 13.072 13.4626 13.072C13.9806 13.072 14.4402 13.2003 14.8416 13.457C15.2476 13.709 15.5416 14.0683 15.7236 14.535H14.7646Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export default ImgIcon;
