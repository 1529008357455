import UtilityButton from "components/buttons/UtilityButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import {
  emptyDctorList,
  getDoctorList,
  getIdParam,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import user from "services/user";
import { NewRxSchema } from "utils/schema/NewRxScema";
import { WaitingLineSchema } from "utils/schema/WaitingLineSchema";
import assistant from "services/assistant";
import waitingList from "services/waitingList";
import { setLoading } from "redux-store/slices/loading";
import assistantDashboardJs from "services/assistantDashboard.js";
import { useParams } from "react-router-dom";

const UserDetailForm = ({ isassistantPage = false }) => {
  const [userData, setUserData] = useState({
    phoneNumber: "",
    name: "",
    gender: "",
    age: "",
  });
  const [existingMemberId, setExistingMemberId] = useState("");
  const { newRxMobileNumber: mobileNumber } = useSelector(
    (state) => state.user
  );
  const { CLINIC_DETAIL: clicnicDetailLoading } = useSelector(
    (state) => state.loading
  );
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      const getUserDetail = async () => {
        const data = await user.searchUser("id", id);
        const rx_user=data?.data?.body?.rx_users

        setUserData((prev) => ({
          name: rx_user?.patient_name ,
          phoneNumber: rx_user?.mobile_number,
          gender: rx_user?.gender,
          age: rx_user?.age,
        }));
      };
      getUserDetail();
    }
  }, []);

  const doctorRecord = useSelector((state) => state.user);

  const { doctorList } = doctorRecord || {};


  const initialValueForRxuser = {
    phoneNumber: mobileNumber,
    age: " ",
    patientName: "",
    gender: "",
  };

  const initalValueForWaitingLineUser = {
    phoneNumber: userData?.phoneNumber || mobileNumber ,
    age: userData?.age,
    patientName: userData?.name,
    gender: userData?.gender,
    doctorId: "",
    clinicalDetail: "",
  };

  const handleSubmit = (values) => {
    if (isassistantPage) {
      store.dispatch(setLoading(["CLINIC_DETAIL", true]));
      waitingList.addUserToWaitingList(values);
    } else {
      store.dispatch(getIdParam(existingMemberId));
      user.rxUser(values);
    }
  };

  useEffect(() => {
    const fetchDcotorName = async () => {
      store.dispatch(emptyDctorList());
      const data = await assistantDashboardJs.getDoctorList();
      if (data?.data?.status) {
        data?.data?.listOfDoctors.forEach((item) => {
          store.dispatch(
            getDoctorList({ id: item?.id, full_name: item?.full_name })
          );
        });
      }
    };
    fetchDcotorName();
  }, []);
  return (
    <Formik
      validationSchema={isassistantPage ? WaitingLineSchema : NewRxSchema}
      onSubmit={handleSubmit}
      initialValues={
        isassistantPage ? initalValueForWaitingLineUser : initialValueForRxuser
      }
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form formnovalidate="formnovalidate" onSubmit={handleSubmit}>
          <div
            className="d-flex justify-content-center gap-5 "
            style={{ width: "100%" }}
          >
            <Row style={{ width: "50%" }}>
              <div className=" mb-20">
                <label
                  className="fs-18 fw-400 mb-8 p-0"
                  style={{ color: "#53545A" }}
                >
                  Patient Phone Number<span className="red"> *</span>
                </label>
                <InputGroup
                  style={{ height: "46px", flexWrap: "nowrap" }}
                  size="lg"
                >
                  <InputGroup.Text
                    id="inputGroup-sizing-lg"
                    style={{ background: "#f5f6f6" }}
                  >
                    +91
                  </InputGroup.Text>

                  <Form.Control
                    aria-label="Large"
                    name="phoneNumber"
                    type="text"
                    inputMode="numeric"
                    aria-describedby="inputGroup-sizing-sm"
                    pattern="[1-9]{1}[0-9]{9}"
                    maxLength={10}
                    style={{
                      borderLeft: "none",
                      background: "#f5f6f6",
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        handleChange(e);
                      }
                    }}
                    value={values.phoneNumber}
                  />
                </InputGroup>
                <span className="red">
                  {(touched.phoneNumber && errors.phoneNumber) ||
                    touched.phoneNumber ||
                    errors.phoneNumber}
                </span>
              </div>
              <div className=" mb-20">
                <label
                  className="fs-18 fw-400 mb-8"
                  style={{ color: "#53545A" }}
                >
                  Age<span className="red"> *</span>
                </label>
                <Form.Control
                  type="number"
                  // pattern="[1-9]{1}[0-9]{9}"
                  name="age"
                  style={{ background: "#f5f6f6", height: "46px" }}
                  onChange={handleChange}
                  className="input-group"
                  value={values.age}
                />
                <span className="red">{touched.age && errors.age}</span>
              </div>
              {isassistantPage && (
                <div className=" mb-20">
                  <label
                    className="d-flex align-items-center fs-18 fw-400 p-0 mb-8 "
                    style={{ color: "#53545A" }}
                  >
                    Clinical Details 
                  </label>
                  <textarea
                    style={{ height: "106px", border: "3px solid #cddef1" }}
                    type="text"
                    class="form-control "
                    name="clinicalDetail"
                    value={values.clinicalDetail}
                    onChange={handleChange}
                    placeholder={"Clinical details "}
                  />
                  {/* <Form.Control
                    type="text"
                    placeholder=""
                    name="heartRate"
                    className="input-group"
                    style={{ background: "#f5f6f6", height: "46px" }}
                    value={values.heartRate}
                    onChange={handleChange}
                  /> */}
                  <span className="red">
                    {touched.heartRate && errors.heartRate}
                  </span>
                </div>
              )}
            </Row>
            <Row style={{ width: "50%" }}>
              <div className=" mb-20">
                <label
                  className="d-flex align-items-center fs-18 fw-400 p-0 mb-8 "
                  style={{ color: "#53545A" }}
                >
                  Patient Name<span className="red"> *</span>
                </label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="patientName"
                  className="input-group"
                  style={{ background: "#f5f6f6", height: "46px" }}
                  value={values.patientName}
                  onChange={handleChange}
                />
                <span className="red">
                  {touched.patientName && errors.patientName}
                </span>
              </div>
              <div className=" mb-20">
                <label
                  className="d-flex align-items-center fs-18 fw-400 p-0 mb-8 "
                  style={{ color: "#53545A" }}
                >
                  Sex <span className="red"> *</span>
                </label>

                {/* <Form.Select
                aria-label=" aria-label"
                style={{ background: "#f5f6f6", height: "46px" }}
                name="gender"
                onChange={handleChange}
              >
                <option>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Form.Select> */}
                <div className="d-flex">
                  <Row className="mb-8 p-0" style={{ width: "94%" }}>
                    <Col className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        id="male"
                        value="male"
                        name="gender"
                        className="formRadios "
                        onChange={handleChange}
                        checked={userData?.gender=='male'? true:null}
                      />
                      <label
                        className="lightgrey "
                        htmlFor="male"
                        style={{ color: "#999999" }}
                      >
                        Male
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-8 p-0" style={{ width: "94%" }}>
                    <Col className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        id="female"
                        value="female"
                        name="gender"
                        onChange={handleChange}
                        checked={userData?.gender=='female'? true:null}
                        className="formRadios"
                       
                      />
                      <label htmlFor="female" style={{ color: "#999999" }}>
                        Female
                      </label>
                    </Col>
                  </Row>
                </div>

                <span className="red">{touched.gender && errors.gender}</span>
              </div>

              {isassistantPage && (
                <div className=" mb-20">
                  <label
                    className="d-flex align-items-center fs-18 fw-400 p-0 mb-8 "
                    style={{ color: "#53545A" }}
                  >
                    Doctor <span className="red"> *</span>
                  </label>
                  <div className="d-flex">
                    <Form.Select
                      aria-label="Default select example"
                      name="doctorId"
                      onChange={handleChange}
                      value={values.doctorId}
                    >
                      <option>Open this select menu</option>
                      {doctorList?.map((item) => {
                        return (
                          <option value={item.id}>{item.full_name}</option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  <span className="red">
                    {touched.doctorId && errors.doctorId}
                  </span>
                </div>
              )}
            </Row>
          </div>

          <div className="d-flex justify-content-start  mt-24 ">
            <UtilityButton
              loading={clicnicDetailLoading}
              disabled={clicnicDetailLoading}
              btnName={isassistantPage ? "Submit" : "Next"}
              width="223px"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserDetailForm;
