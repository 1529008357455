import React from "react";
import Card from "react-bootstrap/Card";
import "./doctorinfocard.css";
export default function DoctorInfoCard({ user }) {
  const data = JSON.parse(localStorage?.getItem("userData")) || {};
  // const { full_name, licence_number, degree_speciality } = data;

  return (
    <>
      <Card className="doctor-info-card  ">
        {/* <Avtar
          height="58px"
          width="60px"
          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
        /> */}
        <div className="doctor-detail">
          <span className="text-white fs-16 fw-700 m-0">
            Dr. {user?.full_name}
          </span>
          <span className="text-white fs-14 fw-400 mb-1">
            {" "}
            {user?.degree_speciality}
          </span>
          <hr style={{ marginTop: "7px", color: "white" }} />
          <span className="text-white fs-12 fw-400 mb-10">
            License -{user?.licence_number}
          </span>
        </div>
      </Card>
    </>
  );
}
