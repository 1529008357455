import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import { toast } from "react-toastify";
import { addAssistant, updateAssistant } from "redux-store/slices/assistant";
class AssistantDashboard {
  
  url="/doctor-assistant-query"
async getDoctorList(){
    const response=await AXIOS.get(this.url, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,  
        "Content-Type": "application/json",
      }
    })
    return response
}
async getDoctorData(id){
    const response=await AXIOS.get(`/doctor-assistant-query?doctorId=${id}`, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,  
        "Content-Type": "application/json",
      }
    })
    return response
}
}

export default new AssistantDashboard();
