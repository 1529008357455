import WebNavbar from "components/WebComponet/web-navbar/WebNavbar";
import UtilityButton from "components/buttons/UtilityButton";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import user from "services/user";
import { router } from "App";
import { store } from "redux-store/store";
import {
  emptyWaitingList,
  showWaitingList,
} from "redux-store/slices/assistant";
import waitingList from "services/waitingList";
import DataBox from "components/shared/dataBox";
import { Form, Spinner } from "react-bootstrap";
import ConfirmationModal from "components/modals/ConfirmationModal";
import assistantDashboardJs from "services/assistantDashboard.js";
import {
  addDiagnosis,
  emptyDctorList,
  getDoctorList,
} from "redux-store/slices/user";
import { toast } from "react-toastify";
import TrashIcon from "assets/svg/TrashIcon";
import { setLoading } from "redux-store/slices/loading";
export default function WebHome() {
  const AssistantDetail = useSelector((state) => state.assistant);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const { waitingLists } = AssistantDetail || {};
  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const UserDetail = useSelector((state) => state.user);
  const { GET_USER: getUserLoading, PAGE_LOADING: pageLoading } = useSelector(
    (state) => state.loading
  );

  const doctorRecord = useSelector((state) => state.user);
  const { data } = UserDetail;
  const { doctorList } = doctorRecord || {};
  const fetchWaitingListUser = async () => {
    store.dispatch(emptyWaitingList());
    const data = await waitingList.getWaitingList();
    if (data?.data?.status) {
      data?.data?.listofWaitingPatients.forEach((item) => {
        store.dispatch(
          showWaitingList({
            name: item?.patient_name,
            mobile_number: item?.patient_mobile_number,
            age: item?.age,
            id: item?.id,
            sex: item?.sex,
            clinical_detail: item?.clinical_detail,
            createdAt: item.createdAt,
          })
        );
      });
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (userData.type !== "Assistant") {
      fetchWaitingListUser();
      const interval = setInterval(() => {
        fetchWaitingListUser();
      }, 60000); // 60000 ms = 1 minute
  
      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    const fetchDcotorName = async () => {
      store.dispatch(emptyDctorList());
      const data = await assistantDashboardJs.getDoctorList();
      if (data?.data?.status) {
        data?.data?.listOfDoctors.forEach((item) => {
          store.dispatch(
            getDoctorList({ id: item?.id, full_name: item?.full_name })
          );
        });
      }
    };
    if (userData && userData.type == "Assistant") {
      fetchDcotorName();
    }
  }, []);
  const selectedWaitingLineUser = (userData) => {
    if (selectedUser?.id === userData.id) {
      setSelectedUser({});
    } else {
      setSelectedUser(userData);
    }
  };

  const { id } = userData || {};

  useEffect(() => {
    user.getUser(id);
  }, []);

  const deleteUserFromWaitingList = async () => {
    store.dispatch(setLoading(["DELETE_USER", true]));
    if (selectedUser) {
      const patientId = selectedUser.id;
      const data = await waitingList.remoeUserFromWaitingList(patientId);
      if (data?.data?.status) {
        store.dispatch(setLoading(["DELETE_USER", false]));
        setConfirmationModal(false);
        toast.success(data?.data?.message);
      }
    }
  };
  const handleRxUser = async () => {
    if (Object.keys(selectedUser).length > 0) {
      const addRxUser = {
        phoneNumber: selectedUser.mobile_number,
        age: selectedUser.age,
        gender: selectedUser.sex,
        patientName: selectedUser.name,
      };
      const response = await user.rxUser(addRxUser);
      if (selectedUser?.clinical_detail) {
        store.dispatch(
          addDiagnosis({
            existingMemberId: response?.data?.body?.rx_user?.id,
            value: selectedUser?.clinical_detail,
          })
        );
      }
      localStorage.setItem("selectedUserId", selectedUser.id);
    } else {
      router.navigate("/new-rx");
    }
  };
  const handleDoctorData = async (id) => {
    setDataLoading(true);
    if (userData && userData.type == "Assistant") {
      const data = await assistantDashboardJs.getDoctorData(id);
      if (data?.data?.status) {
        store.dispatch(emptyWaitingList());
        data?.data?.listofWaitingPatients.forEach((item) => {
          store.dispatch(
            showWaitingList({
              name: item?.patient_name,
              mobile_number: item?.patient_mobile_number,
              age: item?.age,
              id: item?.id,
              sex: item?.sex,
              clinical_detail: item?.clinical_detail,
              createdAt: item.createdAt,
            })
          );
        });
        setDataLoading(false);
      }
    }
  };
  useEffect(() => {
    if (doctorList.length > 0) {
      handleDoctorData(doctorList[0].id);
    }
  }, [doctorList]);
  const generateDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const registeredDate = newDate.getDate();
    return `${registeredDate}-${month}-${year}`;
  };
  const handleSearchRxUser = async () => {
    if (Object.keys(selectedUser).length > 0) {
      const RxUser = {
        phoneNumber: selectedUser.mobile_number,
        age: selectedUser.age,
        gender: selectedUser.sex,
        patientName: selectedUser.name,
      };
      await user.searchRxUser(RxUser);
    } else {
      router.navigate("/search-rx");
    }
  };
  return (
    <Container style={{ margin: "0px", maxWidth: "100%", padding: "0px" }}>
      <div
        style={{
          background: "#105CAA",
          height: "540px",
          paddingTop: "30px",
          position: "relative",
        }}
      >
        {userData.type == "Assistant" ? (
          <div>
            <div
              style={{
                width: "30%",
                position: "absolute",
                right: "10px",
                display: "flex",
                color: "white",
              }}
            >
              Chooose Doctor :
              <Form.Select
                size="lg"
                aria-label="Default select example"
                onChange={(e) => handleDoctorData(e.target.value)}
              >
                {doctorList?.map((item) => {
                  return <option value={item.id}>{item.full_name}</option>;
                })}
              </Form.Select>
            </div>
          </div>
        ) : null}
        {userData.type == "Assistant" ? null : (
          <div
            style={{
              width: "90%",
              margin: " auto",
              border: "1px solid white",
              borderRadius: "8px",
            }}
          >
            <p
              classname="fs-30 fw-500 text-center"
              style={{
                fontSize: "30px",
                color: "white",
                lineHeight: "40px",
                letterSpacing: "-0.3px",
                textAlign: "center",
              }}
            ></p>
            <div className="d-flex justify-content-center">
              <div style={{ margin: "12px" }}>
                <UtilityButton
                  btnName="New Rx"
                  bgColor="#FFFFFF"
                  width="202px"
                  color="#3db8ed"
                  borderRadios="10px"
                  onclick={() => handleRxUser()}
                />
              </div>
              <div
                className="d-flex align-items-center"
                style={{ margin: "12px" }}
              >
                <UtilityButton
                  btnName="Search Rx"
                  color="white"
                  width="202px"
                  bgColor='#00b4d8'
                  border="1px solid white"
                  borderRadios="10px"
                  dataLoading={getUserLoading}
                  onclick={() => handleSearchRxUser()}
                />
              </div>
            </div>
          </div>
        )}
        <div>
          <center>
            <h4
              style={{
                color: "white",
                fontWeight: "bold",
                paddingTop: "20px",
                fontSize: "28px",
              }}
            >
              Waiting List
            </h4>
          </center>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: dataLoading
              ? "center"
              : waitingLists.length <= 0
              ? "center"
              : "space-between",
            flexWrap: "wrap",
            width: "65%",
            margin: "0 auto",
            position: "relative",
          }}
        >
          {dataLoading ? (
            <center>
              <Spinner variant="light" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </center>
          ) : !dataLoading && waitingLists.length <= 0 ? (
            <center style={{ color: "white", fontSize: "20px" }}>
              No patient in Waiting List{" "}
            </center>
          ) : (
            waitingLists.map((item, index) => {
              return (
                <div
                  key={index}
                  className=" d-flex align-items-center justify-content-center mb-2 relative"
                  style={{
                    margin: "10px 0px",
                    width: "38%",
                    position: "relative",
                    backgroundColor:
                      selectedUser?.id === item?.id ? "#00a8e8" : "#e0e1dd",
                    borderRadius: "10px",
                    padding: "4px 10px",
                    minHeight: "60px",
                  }}
                >
                  <Form.Check
                    type="radio"
                    id={item.name}
                    value="male"
                    name="gender"
                    className="formRadios "
                    checked={selectedUser?.id === item.id} 
                    onClick={() => selectedWaitingLineUser(item)}  
                    onChange={() => {}} 
                  />
                  <div style={{ width: "100%" }}>
                    <label
                      className="ms-2 text-gray fw-bold "
                      htmlFor={item.name}
                      style={{
                        color: "#105CAA",
                        fontSize: "18px",
                        width: "100%",
                      }}
                    >
                      {item.name} &nbsp;&nbsp;({item.age}/
                      {item.sex == "male" ? "M" : "F"})
                    </label>
                  </div>
                  {userData.type == "Assistant" ? (
                    <div
                      onClick={() => {
                        setConfirmationModal(true);
                        setSelectedUser(item);
                      }}
                      style={{
                        position: "absolute",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <TrashIcon />
                    </div>
                  ) : null}
                </div>
              );
            })
          )}
        </div>
        {isConfirmationModal && (
          <ConfirmationModal
            setIsModal={setConfirmationModal}
            onClick={() => localStorage.Clear()}
            text="Are you sure you want to remove this patient?"
            handleUser={deleteUserFromWaitingList}
          />
        )}
      </div>
    </Container>
  );
}
