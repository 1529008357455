import LogoIcon from "assets/svg/LogoIcon";
import Description from "components/description/Description";
import React from "react";
import { Container } from "react-bootstrap";

export default function AboutUs() {
  return (
    <Container className=" d-flex   flex-column  " style={{ height: "60vh" }}>
      <div className="mt-12 mb-12">
        <LogoIcon width={68} heigth={68} />
      </div>

      <Container className="mb-12 postition-relative">
        <Description description="Welcome to ClearRx " />

        <p style={{ fontSize: "22px", margin: "0px" }}>
          We are a team of doctors and engineers with extensive global Health IT
          experience.
        </p>
        <p style={{ fontSize: "22px", margin: "0px" }}>
          We provide software tools for doctors to digitize clinical practice.{" "}
        </p>
      </Container>
    </Container>
  );
}
  